import {
    GET_DASHBOARD_ACTIVITIES,
    GET_DASHBOARD_ACTIVITIES_SUCCESS,
    GET_DASHBOARD_ACTIVITIES_FAILED
} from './actionTypes';

const initialState = {
    dashActivities: null,
}

const dashboardActivities = (state = initialState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_ACTIVITIES:
            state = {
                ...state
            };
            break;
        case GET_DASHBOARD_ACTIVITIES_SUCCESS:
            state = {
                ...state,
                dashActivities: action.payload
            };
            break;
        case GET_DASHBOARD_ACTIVITIES_FAILED:
            state = {
                ...state
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default dashboardActivities;