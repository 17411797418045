import React, { useEffect, Fragment } from 'react';

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from 'react-i18next';

const SidebarContent = (props) => {

    // Use ComponentDidMount and ComponentDidUpdate method symultaniously

    useEffect(() => {

        let pathName = props.location.pathname;

        const initMenu = () => {
            new MetisMenu("#side-menu");
            let matchingMenuItem = null;
            let ul = document.getElementById("side-menu");
            let items = ul.getElementsByTagName("a");
            for (let i = 0; i < items.length; ++i) {
                if (pathName === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        }
        initMenu();
    }, [props.location.pathname]);


    function activateParentDropdown(item) {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    }


    const role = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).data.role : ""
    return (
        <React.Fragment>
            <div id="sidebar-menu">
                <ul className="metismenu list-unstyled" id="side-menu">
                    {role === "Teacher" && (
                        <Fragment>
                            <li>
                                <Link to="/dashboard" className="waves-effect">
                                    <i className="fas fa-home" aria-hidden="true"></i>
                                    <span>{props.t('Dashboard')}</span>
                                    <span className="badge badge-pill badge-success float-right">New</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/users" className="waves-effect">
                                    <i className="fa fa-user-plus" aria-hidden="true"></i>
                                    <span>{props.t('Users')}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/#" className="waves-effect">
                                    <i className="fa fa-bicycle" aria-hidden="true"></i>
                                    <span>{props.t('Activities')}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="/create-activity">{props.t('Create Activity')}</Link></li>
                                    <li><Link to="/activity-list">{props.t('Activity List')}</Link></li>
                                    <li><Link to="/activity-detail">{props.t('Child Evaluation')}</Link></li>
                                </ul>
                            </li>
                        </Fragment>
                    )}
                    {role === "Teacher" && (<>
                        <li>
                            <Link to="/attendances" className="waves-effect">
                                <i className="fab fa-wpforms" aria-hidden="true"></i>
                                <span>{props.t('Attendances')}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/#" className="waves-effect">
                                <i className="fa fa-child" aria-hidden="true"></i>
                                <span>{props.t('Students')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="/students">{props.t('Active Students')}</Link></li>
                                <li>
                                    <Link to="/#">
                                        <span>{props.t('Old Students')}</span>
                                        <span className="badge badge-pill badge-success float-right">New</span>
                                    </Link>
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li>
                                            <Link to="/old-report">
                                                <span>{props.t('School Reports')}</span>
                                                <span className="badge badge-pill badge-success float-right">New</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/group" className="waves-effect">
                                <i className="fa fa-users" aria-hidden="true"></i>
                                <span>{props.t('Groups')}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/#" className="waves-effect">
                                <i className="far fa-folder" aria-hidden="true"></i>
                                <span>{props.t('School Report')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="/report-items">{props.t('Create Report Items')}</Link></li>
                                <li><Link to="/report-list">{props.t('Report Items List')}</Link></li>
                                <li><Link to="/report-detail">{props.t('Report Detail')}</Link></li>
                            </ul>
                        </li>
                    </>
                    )}
                    <li>
                        <Link to="/#" className="waves-effect">
                            <i className="far fa-sticky-note" aria-hidden="true"></i>
                            <span>{props.t('Reports')}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                            <li><Link to="/report-attendances">{props.t('Attendances')}</Link></li>
                            <li><Link to="/report-expenses">{props.t('Expenses')}</Link></li>
                            {role !== "Teacher" && (
                                <li><Link to="/report-show">{props.t('Child Evaluations')}</Link></li>
                            )}
                        </ul>
                    </li>
                    {role === "Teacher" && (
                        <>
                            <li>
                                <Link to="/waiting-list" className="waves-effect">
                                    <i className="fas fa-hourglass-end" aria-hidden="true"></i>
                                    <span>{props.t('Waiting List')}</span>
                                    <span className="badge badge-pill badge-success float-right">New</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/contacts" className="waves-effect">
                                    <i className="far fa-envelope" aria-hidden="true"></i>
                                    <span>{props.t('Contacts')}</span>
                                    <span className="badge badge-pill badge-success float-right">New</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/registrations" className="waves-effect">
                                    <i className="far fa-file-alt" aria-hidden="true"></i>
                                    <span>{props.t('Registrations')}</span>
                                    <span className="badge badge-pill badge-success float-right">New</span>
                                </Link>
                            </li>
                        </>
                    )}
                </ul>
            </div>
        </React.Fragment>
    );
}

export default withRouter(withNamespaces()(SidebarContent));


