import React, {useEffect, useState} from 'react';
import ReactApexChart from 'react-apexcharts';

const dountchart = (props) => {

    useEffect(() => {
        setSeries([]);
        setLabels([]);
        props.info.forEach((activity) => {
            setSeries((oldValue) => [...oldValue, activity[1]]);
            setLabels((oldValue) => [...oldValue, activity[0]]);
        })
    }, props.info);

    const [series, setSeries] = useState([]);
    const [labels, setLabels] = useState([]);
    const   options  = {
        labels: labels,
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '14px',
            offsetX: 0,
            offsetY: -10
        },
        responsive: [{
            breakpoint: 600,
            options: {
                chart: {
                    height: 240
                },
                legend: {
                    show: false
                },
            }
        }]

    };


    return (
        <ReactApexChart options={options} series={series} type="donut" className={"dashboard-chart"} />
    );
}

export default dountchart;