export const GET_A_STUDENT = 'GET_A_STUDENT';
export const GET_A_STUDENT_SUCCESS = 'GET_A_STUDENT_SUCCESS';
export const GET_A_STUDENT_FAILED = 'GET_A_STUDENT_FAILED';
export const UPDATE_A_STUDENT = 'UPDATE_A_STUDENT';
export const UPDATE_A_STUDENT_SUCCESS = 'UPDATE_A_STUDENT_SUCCESS';
export const UPDATE_A_STUDENT_FAILED = 'UPDATE_A_STUDENT_FAILED';
export const GET_TEACHERS_FOR_A_STUDENT = 'GET_TEACHERS_FOR_A_STUDENT';
export const GET_MEDICAL_DOCS = 'GET_MEDICAL_DOCS';
export const UPLOAD_DOC = 'UPLOAD_DOC';
export const UPLOAD_DOC_SUCCESS = 'UPLOAD_DOC_SUCCESS';
export const UPLOAD_DOC_FAILED = 'UPLOAD_DOC_FAILED';
export const DELETE_DOC = 'DELETE_DOC';
export const DELETE_DOC_SUCCESS = 'DELETE_DOC_SUCCESS';
export const DELETE_DOC_FAILED = 'DELETE_DOC_FAILED';
