import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { REGISTER_STUDENT, GET_REGISTERED_LIST, UPDATE_REGISTRATION } from './actionTypes';
import {
    registerStudentSuccess,
    registerStudentFailed
} from './actions';
import {noAuthPostWithFile, paramPut, paramPost, paramGet} from '../../../apiHelpers/commonAPi';
import toastr from 'toastr';
import {getRegistrationListSuccess, updateRegistrationSuccess, updateRegistrationFailed} from "./actions";


function* getRegistrations({ payload: { page, pageSize } }) {
    try {
        const response = yield call(paramGet, '/api/v1/registrations', {page, pageSize});
        if (response.status === 200) {
            yield put(getRegistrationListSuccess(JSON.parse(response.data.students), response.data.total));
        }
    } catch (error) {
        throw error;
    }
}

function* registerAStudent({ payload: student }) {
    try {
        let formData = new FormData();
        formData.append(`docs[0]`, student.birthFile);
        formData.append(`docs[1]`, student.bankFile);
        delete student.birthFile;
        delete student.bankFile;
        for ( let key in student ) {
            if(key === "language"){continue;}
            formData.append(key, student[key]);
        }
        formData.append(`language`, JSON.stringify(student.language))
        const response = yield call(noAuthPostWithFile, `/api/v1/registered_students/upload_docs`, formData);
        if (response.status === 200) {
            yield put(registerStudentSuccess());
        }
        else if(response.status === 422) {
            response.data.message.forEach(msg => {
                toastr.error(replaceCorrectMsg(msg), "Error");
            });
            yield put(registerStudentFailed());
        } else {
            toastr.error(response.data.message,"Error")
            yield put(registerStudentFailed());
        }
    } catch (error) {
        yield put(registerStudentFailed());
    }
}

function replaceCorrectMsg(msg) {
    let toMsg = msg.replace('Motheremail', "The mother's email");
    toMsg = toMsg.replace('Fatheremail', "The father's email");
    toMsg = toMsg.replace('Mothercellphone', "The mother's phone number");
    toMsg = toMsg.replace('Fathercellphone', "The father's phone number");
    return toMsg;
}

function* updateRegistrationList({ payload: { data } }) {
    try {
        const index = data.index
        const id = data.id
        delete data.id
        delete data.index
        const response = yield call(paramPut, `/api/v1/registrations/${id}`, {...data});
        if (response.status === 200) {
            yield put(updateRegistrationSuccess(JSON.parse(response.data.student), index));
            toastr.success(response.data.message, "Success");
        } else if(response.status === 422) {
            response.data.message.forEach(msg => {
                toastr.error(replaceCorrectMsg(msg), "Error");
            });
            yield put(updateRegistrationFailed());
        } else {
            toastr.error(response.data.message, "Error");
            yield put(updateRegistrationFailed());
        }
    } catch (error) {
        yield put(updateRegistrationFailed());
    }
}


export function* watchRegisterStudent() {
    yield takeEvery(REGISTER_STUDENT, registerAStudent)
}

export function* watchRegistrations() {
    yield takeEvery(GET_REGISTERED_LIST, getRegistrations)
}

export function* watchUpdating() {
    yield takeEvery(UPDATE_REGISTRATION, updateRegistrationList)
}


function* registerStudentSaga() {
    yield all([
        fork(watchRegisterStudent),
        fork(watchRegistrations),
        fork(watchUpdating)
    ]);
}

export default registerStudentSaga;