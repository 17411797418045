import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import { GET_ATTENDANCES, GET_STUDENTS_LIST, GET_OLD_STUDENTS_LIST } from './actionTypes';
import {
    getAttendancesSuccess,
    getAttendancesFailed,
    getStudentsListSuccess,
    getOldStudentsListSuccess
} from './actions';
import { getAttendances, getAllStudents } from '../../../apiHelpers/reportApiHelper';
import { noParamGet } from '../../../apiHelpers/commonAPi';
import toastr from 'toastr';

function* getAttInReport({payload}) {
    try {
        const response = yield call(getAttendances, '/api/v1/report_attendances', payload);
        if(response.status === 200 && (JSON.parse(response.data.attendances)).length > 0) {
            yield put(getAttendancesSuccess(JSON.parse(response.data.attendances)));
        } else {
            toastr.info("You do not have any student associated with this email", "info")
            yield put(getAttendancesFailed(response.status));
        }

    } catch (error) {
        yield put(getAttendancesFailed(error));
    }
}

function* getStudents() {
    try {
        const response = yield call(getAllStudents, '/api/v1/students_list');
        if(response.status === 200) {
            yield put(getStudentsListSuccess(JSON.parse(response.data.students)));
        } else {
            toastr.info("There is no registered children", "Info")
        }
    } catch (error) {
        toastr.warning("Something went wrong!", "Warning")
    }
}

function* getOldStudents() {
    try {
        const response = yield call(noParamGet, '/api/v1/old_students_list');
        if(response.status === 200) {
            yield put(getOldStudentsListSuccess(JSON.parse(response.data.students)));
        } else {
            toastr.info("There is no deleted children", "Info")
        }
    } catch (error) {
        toastr.warning("Something went wrong!", "Warning")
    }
}

export function* watchGetAttendances() {
    yield takeEvery(GET_ATTENDANCES, getAttInReport)
}

export function* watchGetStudents() {
    yield takeEvery(GET_STUDENTS_LIST, getStudents)
}

export function* watchGetOldStudents() {
    yield takeEvery(GET_OLD_STUDENTS_LIST, getOldStudents)
}


function* attendanceSaga() {
    yield all([
        fork(watchGetAttendances),
        fork(watchGetStudents),
        fork(watchGetOldStudents)
    ]);
}

export default attendanceSaga;