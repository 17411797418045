import React, {useState, useEffect} from 'react';
import {
    Container,
    Row,
    Col, CardBody, Media, Card
} from "reactstrap";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import StatsChart from './statsChart';

import {connect} from 'react-redux';
//i18n
import {withNamespaces} from 'react-i18next';
import { getDashboardActivities } from '../../store/actions';
import moment from "moment";


const Dashboard = (props) => {

    useEffect(() => {
        props.getDashboardActivities();
    }, [])

    const topData = () => {
        if(props.dashActivities) {
            const data = Object.entries(props.dashActivities);
            return data.map((item, index) => {
                return (
                    <Col xs={12} sm={6} className={"dashboard-stats mb-2"} key={index}>
                        <Card className="mini-stats-wid">
                            <CardBody>
                                <Media>
                                    <Media body>
                                        <p className="text-muted font-weight-medium">{item[0]} Activities</p>
                                        <h4 className="mb-0">{item[1]}</h4>
                                    </Media>
                                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                            <span className="avatar-title">
                                                <i className={"bx bx-copy-alt font-size-24"}></i>
                                            </span>
                                    </div>
                                </Media>
                            </CardBody>
                        </Card>
                    </Col>
                )
            })
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('Dashboard')} breadcrumbItem={props.t('Dashboard')}/>
                    <Row className={"dashboard-stats-row"}>
                        {topData()}
                    </Row>
                    {props.dashActivities && (
                        <Row className="justify-content-center font-size-16 font-weight-bold mt-5">
                            <Col xs={12} className="text-center">Activities given</Col>
                            <Col xs={12}>
                                <StatsChart info={Object.entries(props.dashActivities)} />
                            </Col>
                        </Row>
                        )
                    }
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    const {dashActivities} = state.DashboardReducer;
    return {dashActivities};
}

export default withRouter(connect(mapStateToProps, {getDashboardActivities})(withNamespaces()(Dashboard)));
