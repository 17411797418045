import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Card,
  CardBody,
  FormGroup,
  Label,
} from "reactstrap";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { connect } from "react-redux";

import { getAllActivities } from "../../store/actions";
import Select from "react-select";
//i18n
import { withNamespaces } from "react-i18next";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import {
  createWorkBook,
  createActivity,
  callActivity,
  saveSubactivity,
} from "../../apiHelpers/activityApiHelper";
import toastr from "toastr";

const Student = (props) => {
  useEffect(() => {
    props.getAllActivities();
  }, []);

  const [rows1, setrows1] = useState([]);

  const [activities, setActivities] = useState([]);
  const [workbookId, setWorkBookId] = useState(undefined);
  const [subAct, setSubAct] = useState("Select");
  const [subList, SetSubList] = useState([]);

  const [cws, setCws] = useState(false);
  const [cas, setCas] = useState(false);
  const [csas, setCsas] = useState(false);

  function handleAddRowNested() {
    const item1 = { name1: "" };
    setrows1([...rows1, item1]);
  }

  function handleRemoveRowNested(e, idx) {
    document.getElementById("nested" + idx).remove();
  }

  const handleWorkbook = (event, values) => {
    event.persist();
    setCws(true);
    createWorkBook(values)
      .then((result) => {
        setCws(false)
        if (result.status === 200) {
          props.getAllActivities();
          toastr.success(result.data.message, "Success");
        } else {
          toastr.error(
            "It seems the workbook title informed already exists",
            "Error"
          );
        }
      })
      .catch((error) => {
        setCws(false)
        toastr.error("Failed to create a workbook!", "Error");
      });
  };

  const handleActivity = (event, values) => {
    event.persist();
    if (values.workbook === "Select") {
      toastr.warning("Please select a workbook", "Warning");
      return false;
    }
    setCas(true)
    createActivity(values)
      .then((result) => {
        setCas(false)
        if (result.status === 200) {
          props.getAllActivities();
          if (workbookId) {
            callingActivity(workbookId);
          }
          toastr.success(result.data.message, "Success");
            refreshPage()
        } else {
          toastr.error(
            "It seems the Activity name informed already exists",
            "Error"
          );
        }
      })
      .catch((error) => {
        setCas(false)
        toastr.error("Failed to create a workbook!", "Error");
      });
  };

  const handleSubActivity = (event, values) => {
    event.persist();
    if (values.workbook === "Select") {
      toastr.info("Please select a workbook", "Info");
      return false;
    }
    if (values.activity === "Select") {
      toastr.info("Please select an activity", "Info");
      return false;
    }

    const activity_id = values.activity;
    delete values.workbook;
    delete values.activity;
    let sub_activities = Object.keys(values).map((key) => values[key]);
    sub_activities = sub_activities.filter(Boolean);
    const sub_hook = sub_activities;
    sub_activities = sub_activities.filter((val) => !subList.includes(val));
    if (sub_activities.length === 0) {
      toastr.info(
        "These values are already saved. Please input others!",
        "Info"
      );
      return false;
    }
    setCsas(true)
    saveSubactivity({ activity_id: activity_id, subs: sub_activities })
      .then((result) => {
        setCsas(false)
        if (result.status === 200) {
          // props.getAllActivities()
          SetSubList(sub_hook);
          toastr.success(result.data.message, "Success");
          refreshPage();
        } else {
          toastr.error(
            `It seems the sub-activity name: ${result.data.data} already exists`,
            "Error"
          );
          refreshPage();
        }
      })
      .catch((error) => {
        setCsas(false)
        toastr.error("Something went wrong!", "Error");
      });
  };

  const refreshPage = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const getActivity = (e) => {
    setActivities([]);
    setWorkBookId(e.target.value);
    callActivity({ workbook_id: e.target.value })
      .then((result) => {
        if (result.status === 200) {
          setActivities(JSON.parse(result.data.activities));
        } else {
          toastr.warning(result.data.message, "Warning");
        }
      })
      .catch((error) => {
        toastr.warning("Something went wrong", "Error");
      });
  };

  const callingActivity = (id) => {
    setActivities([]);
    callActivity({ workbook_id: id })
      .then((result) => {
        if (result.status === 200) {
          setActivities(JSON.parse(result.data.activities));
        } else {
          toastr.warning(result.data.message, "Warning");
        }
      })
      .catch((error) => {
        toastr.warning("Something went wrong", "Error");
      });
  };

  const options = () => {
    if (props.workbooks) {
      return props.workbooks.map((item, index) => {
        return (
          <option key={index} value={item.id}>
            {item.title}
          </option>
        );
      });
    }
  };

  const activityOptions = () => {
    if (activities) {
      return activities.map((item, index) => {
        return (
          <option key={index} value={item.id}>
            {item.name}
          </option>
        );
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Activity-Create")}
            breadcrumbItem={props.t("Group")}
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={12} sm={6}>
                      <h5 className="mb-3">1. {props.t("Create WorkBook")}</h5>
                      <AvForm
                        onValidSubmit={(e, v) => {
                          handleWorkbook(e, v);
                        }}
                      >
                        <AvField
                          name="title"
                          label={props.t("Title")}
                          placeholder={props.t("Title")}
                          type="text"
                          errorMessage={props.t("Enter Title")}
                          value=""
                          // onChange={(e) => {setName(e.value)}}
                          validate={{ required: { value: true } }}
                        />
                        <FormGroup className="mb-0">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1 float-right"
                              disabled={cws}
                            >
                              {cws ? (
                                  <React.Fragment>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                    Saving
                                  </React.Fragment>
                              ): "Save"}
                            </Button>{" "}
                          </div>
                        </FormGroup>
                      </AvForm>
                      <h5 className="mb-3" style={{ marginTop: "70px" }}>
                        2. {props.t("Create Activity")}
                      </h5>

                      <AvForm
                        onValidSubmit={(e, v) => {
                          handleActivity(e, v);
                        }}
                      >
                        <AvField
                          type="select"
                          value={"Select"}
                          name="workbook"
                          label={props.t("Select Workbook")}
                          validate={{ required: { value: true } }}
                        >
                          <option disabled={true}>Select</option>
                          {options()}
                        </AvField>
                        <AvField
                          name="name"
                          label={props.t("Activity Title")}
                          placeholder={props.t("Activity Name")}
                          type="text"
                          errorMessage={props.t("Enter name")}
                          value=""
                          // onChange={(e) => {setName(e.value)}}
                          validate={{ required: { value: true } }}
                        />
                        <FormGroup className="mb-0">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1 float-right"
                              disabled={cas}
                            >
                              {cas ? (
                                  <React.Fragment>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                    Saving
                                  </React.Fragment>
                              ): "Save"}
                            </Button>{" "}
                          </div>
                        </FormGroup>
                      </AvForm>
                    </Col>
                    <Col xs={12} sm={6}>
                      <h5>3. {props.t("Create Sub-Activity")}</h5>
                      <AvForm
                        className="outer-repeater"
                        onValidSubmit={(e, v) => {
                          handleSubActivity(e, v);
                        }}
                      >
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="form-group">
                              <AvField
                                type="select"
                                value={subAct}
                                name="workbook"
                                label={props.t("Select Workbook")}
                                onChange={getActivity}
                              >
                                <option disabled={true}>Select</option>
                                {options()}
                              </AvField>
                            </div>

                            <div className="form-group">
                              <AvField
                                type="select"
                                value={"Select"}
                                name="activity"
                                label={props.t("Select Activity")}
                                validate={{ required: { value: true } }}
                              >
                                <option disabled={true}>Select</option>
                                {activityOptions()}
                              </AvField>
                            </div>

                            <div className="inner-repeater mb-4">
                              <Label>{props.t("Sub-Activity Title")}:</Label>
                              <table style={{ width: "100%" }}>
                                <tbody>
                                  <tr id="addrMain" key="">
                                    <td>
                                      <Row className="mb-2">
                                        <Col md="9">
                                          <AvField
                                            name="title0"
                                            placeholder={props.t(
                                              "Add sub-activity"
                                            )}
                                            type="text"
                                            errorMessage={props.t(
                                              "Enter sub-activity"
                                            )}
                                            value=""
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </Col>
                                        <Col md="3">
                                          <Button
                                            color="danger"
                                            // className="btn-block inner"
                                            // style={{ width: "100%" }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                            }}
                                          >
                                            <i className="fas fa-minus"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </td>
                                  </tr>

                                  {rows1.map((item1, idx) => (
                                    <tr id={"nested" + idx} key={idx}>
                                      <td>
                                        <Row className="mb-2">
                                          <Col md="9">
                                            <AvField
                                              name={`title` + (idx + 1)}
                                              placeholder={props.t(
                                                "Add sub-activity"
                                              )}
                                              type="text"
                                              errorMessage={props.t(
                                                "Enter sub-activity"
                                              )}
                                              value=""
                                              // onChange={(e) => {setName(e.value)}}
                                              // validate={{ required: { value: true } }}
                                            />
                                          </Col>
                                          <Col md="3">
                                            <Button
                                              onClick={(e) => {
                                                handleRemoveRowNested(e, idx);
                                              }}
                                              color="danger"
                                              // className="btn-block inner"
                                              // style={{ width: "100%" }}
                                            >
                                              <i className="fas fa-minus"></i>
                                            </Button>
                                          </Col>
                                        </Row>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <Button
                                onClick={() => {
                                  handleAddRowNested();
                                }}
                                color="success"
                                className="mt-1"
                              >
                                <i className="fas fa-plus"></i>
                              </Button>
                            </div>
                            <Button
                              type="submit"
                              color="primary"
                              className="float-right"
                              disabled={csas}
                            >
                              {csas ? (
                                  <React.Fragment>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                    Saving
                                  </React.Fragment>
                              ): "Save"}
                            </Button>
                          </div>
                        </div>
                      </AvForm>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { loading, error, workbooks, activities } = state.ActivityReducer;
  return { loading, error, workbooks, activities };
};

export default withRouter(
  connect(mapStateToProps, { getAllActivities })(withNamespaces()(Student))
);
