import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError } from './actions';
import { postJwtLogin, deviseLogout } from '../../../apiHelpers/authHelper';

function* loginUser({ payload: { user, history } }) {
    try {
        const response = yield call(postJwtLogin, '/api/v1/auth/sign_in', { email: user.email, password: user.password });
        localStorage.setItem("authUser", JSON.stringify(response.data));
        localStorage.setItem("authHeaders", JSON.stringify(response.headers));
        yield put(loginSuccess(JSON.stringify(response)));
        if (response.status === 200 && response.data.data.role === "Teacher") {
            window.location.href = "/dashboard"
        } else {
            window.location.href = "/report-attendances"
        }
    } catch (error) {
        yield put(apiError(error));
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        const localHeaders = JSON.parse(localStorage.getItem("authHeaders"))
        const headers = {
            accesstoken: localHeaders.accesstoken,
            client: localHeaders.client,
            uid: localHeaders.uid
        }
        const response = yield call(deviseLogout, '/api/v1/auth/sign_out', { ...headers });
        if (response.success) {
            localStorage.removeItem("authUser");
            localStorage.removeItem("authHeaders");
            localStorage.removeItem("locale")
            history.push('/login');
        } else {
            window.history.go(-1)
        }
    } catch (error) {
        yield put(apiError(error));
        window.history.go(-1)
    }
}


export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* authSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
    ]);
}

export default authSaga;