import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { GET_CONTACTS, UPDATE_CONTACTS } from './actionTypes';
import {
    getContactsSuccess,
    updateContactsSuccess,
    updateContactsFailed
} from './actions';
import { paramPost, paramGet, paramPut } from "../../../apiHelpers/commonAPi";
import toastr from 'toastr';

function* getContacts({ payload: { page, pageSize } }) {
    try {
        const response = yield call(paramGet, '/api/v1/contacts', {page, pageSize});
        if (response.status === 200) {
            yield put(getContactsSuccess(JSON.parse(response.data.contacts), response.data.total));
        }
    } catch (error) {
        throw error;
    }
}

function* updateContactList({ payload: { newContact } }) {
    try {
        const index = newContact.index
        const id = newContact.id
        delete newContact.id
        delete newContact.index
        const response = yield call(paramPut, `/api/v1/contacts/${id}`, {...newContact});
        if (response.status === 200) {
            yield put(updateContactsSuccess(JSON.parse(response.data.new_contact), index));
            toastr.success(response.data.message, "Success");
        } else {
            toastr.error(response.data.message, "Error");
            yield put(updateContactsFailed());
        }
    } catch (error) {
        yield put(updateContactsFailed());
    }
}


export function* watchGetContactList() {
    yield takeEvery(GET_CONTACTS, getContacts)
}

export function* watchUpdateContact() {
    yield takeEvery(UPDATE_CONTACTS, updateContactList)
}

function* contactSaga() {
    yield all([
        fork(watchGetContactList),
        fork(watchUpdateContact)
    ]);
}

export default contactSaga;
