import React, {useState, useEffect} from "react";
import {
    AvCheckbox,
    AvCheckboxGroup,
    AvField,
    AvForm,
    AvInput,
    AvRadio,
    AvRadioGroup
} from "availity-reactstrap-validation";
import {Table, Tbody, Td, Th, Thead, Tr} from 'react-super-responsive-table';
import InputMask from "react-input-mask";
import {Button, Label, CustomInput} from 'reactstrap';
import toastr from "toastr";
import { connect } from 'react-redux';
import { registerStudent } from "../../store/actions";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import ReCAPTCHA from "react-google-recaptcha";

const selectOptions = [1, 1, 1, 1, 1];
const selectDescriptions = ['', '', '', '', ''];
const mode = false;
const defaultValues = {
    firstName: "Amina",
    lastName: "Taous",
    address: "14 rue de la Madone G1E5W9",
    birthday: "2017-05-28",
    gender: "F",
    selectlanguages: ["French"],
    mMon: true,
    mTue: true,
    mWed: true,
    mThu: true,
    mFri: true,
    aMon: true,
    aTue: true,
    aWed: true,
    aThu: true,
    aFri: true,
    motherName: "Saida Berri",
    motherEmail: "example@email.com",
    motherSameAddress: "Yes",
    motherAddress: "14 rue de la Madone G1E5W9",
    motherHomePhone: "(418) 914-5521",
    motherCellPhone: "(999) 999-9999",
    motherOfficePhone: "(418) 914-5521",
    fatherName: "Ahmed Taoud",
    fatherEmail: "hmadts@example.fr",
    fatherSameAddress: "Yes",
    fatherAddress: "14 rue de la Madone G1E5W9",
    fatherHomePhone: "(418) 914-5521",
    fatherCellPhone: "(581) 984-1076",
    fatherOfficePhone: "(999) 999-9999",
    pickerName: "dheroking",
    pickerPhone: "(999) 999-9999",
    linkChild: "uncle",
    pickerAddress: "de la Madone G1E5W",
    urgencyName: "Driss Berri",
    urgencyPhone: "(428) 570-3498",
    urgencyLinkChild: "oncle",
    urgencyAddress: "333 rue Ader",
    healthSin: "BBBB 1231 2321",
    healthExp: "2025-05-31",
    specialCare: mode ? 3 : 1,// have to be 1 for origin
    specialCareDesc: "Description for specialcare",
    medication: mode ? 3 : 1, // have to be 1 for origin
    medicationDesc: "",
    sideEffects: mode ? 3 : 1, // have to be 1 for origin
    sideEffectsDesc: "",
    vaccine: mode ? 3 : 1, // have to be 1 for origin
    vaccineUpToDate: mode ? 3 : 1, // have to be 1 for origin
    allergies: mode ? 3 : 1, // have to be 1 for origin
    allergyDesc: "",
    provision: mode ? 3 : 1, // have to be 1 for origin
    provisionDesc: "",
    doctorName: "Danny Boulanger",
    doctorAddress: "CLSC ORLEANS BEAUPORT",
    doctorPhone: "(999) 999-9999",
    drugSign: "Tylenol, Creme solaire. anti-moustique",
    firstPSign: true,
    firstSDate: "2020-09-01",
    signDesc: "AMBULANCE TRANSPORTE",
    secondPSign: true,
    secondSDate: "2020-09-01",
    //New
    confirmSign: true,
    confirmSignDate: "2020-09-01",
}

const RegistrationForm = (props) => {

    const recaptchaRef = React.createRef();

    //step 2 states
    const [mMon, setMMon] = useState(false)
    const [mTue, setMTue] = useState(false)
    const [mWed, setMWed] = useState(false)
    const [mThu, setMThu] = useState(false)
    const [mFri, setMFri] = useState(false)

    const [aMon, setAMon] = useState(false)
    const [aTue, setATue] = useState(false)
    const [aWed, setAWed] = useState(false)
    const [aThu, setAThu] = useState(false)
    const [aFri, setAFri] = useState(false)

    const [isMSameAddress, setIsMSameAddress] = useState(false);
    const [isFSameAddress, setIsFSameAddress] = useState(false);

    const [selectList, setSelectList] = useState(selectOptions);
    const [selectDescription, setSelectDescription] = useState(selectDescriptions);

    const [birthFile, setBirthFile] = useState(null);
    const [bankFile, setBankFile] = useState(null);

    const [success_dlg, setsuccess_dlg] = useState(false);

    useEffect(() => {
        if(props.result === 1) {
            setsuccess_dlg(true);
        }
    }, [props.result])

    const handleMAddress = (event, value) => {
        if(value) {
            const isSame = value === 1
            setIsMSameAddress(isSame);
        }
    }

    const handleFAddress = (event, value) => {
        if(value) {
            const isSame = value === 1
            setIsFSameAddress(isSame);
        }
    }

    const handleSelections = (event, num) => {
        let val = selectList;
        val[num] = parseInt(event.target.value);
        if(val[num] === 3) {
            let value = selectDescription;
            value[num] = '';
            setSelectDescription([...value]);
        }
        setSelectList([...val]);
    }

    const handleSelectionDesc = (event, num) => {
        let val = selectDescription;
        val[num] = event.target.value;
        setSelectDescription([...val]);
    }


    const handleRegistration = async (event, values) => {
        // recaptchaRef.current.execute();
        event.persist();
        if(values.parentFirstSignDate !== values.parentSecondSignDate ||
            values.parentSecondSignDate !== values.parentConfirmSignDate ||
            values.parentConfirmSignDate !== values.parentFirstSignDate
        ) {
            toastr.warning("Sign dates can't be different", "Validation Warning");
            return;
        }

        // Attendance check
        const days = [mMon, mTue, mWed, mThu, mFri, aMon, aTue, aWed, aThu, aFri]
        let total = 0
        days.forEach(item => {
            if (item) {
                total++;
            }
        });
        if (total < 3) {
            toastr.warning("S'il vous plaît vérifier la présence!. La fréquentation minimale est de 3 jours par semaine.")
            return false;
        }

        // File check
        if(!birthFile || !bankFile) {
            toastr.warning("Please upload mandatory files")
            return false;
        }

        if(!checkFileSizeAndExt(birthFile)) {
            return false;
        }

        if(!checkFileSizeAndExt(bankFile)) {
            return false;
        }

        values.mMon = mMon;
        values.mTue = mTue;
        values.mWed = mWed;
        values.mThu = mThu;
        values.mFri = mFri;
        values.aMon = aMon;
        values.aTue = aTue;
        values.aWed = aWed;
        values.aThu = aThu;
        values.aFri = aFri;


        values.bankFile = bankFile;
        values.birthFile = birthFile;

        if( !values.fatherSameAddress ) {
            values.fatherSameAddress = 0
        }
        if( !values.motherSameAddress ) {
            values.motherSameAddress = 0
        }
        // const recaptchaValue = recaptchaRef.current.getValue();
        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();

        values.token = token;

        await props.registerStudent(values);
    };

    const checkFileSizeAndExt = (file) => {
        const fileSize = file.size / Math.pow(10, 6);
        const fileType = file.type;
        const reg = /[\/](jpg|jpeg|png|pdf)$/i;
        if(!reg.test(fileType)) {
            toastr.warning("Please check the file type. Only jpg, png, jpeg and pdf are allowed!", "File validation error")
            return false;
        }
        if(fileSize > 10) {
            toastr.warning("Please check the file size. Max 10mb allowed!", "File validation error")
            return false;
        }
        return true;
    }

    return (
        <div className="container regi-form-wrapper pb-5">
            <div className="text-center top-title">
                <h1 className="regi-form-title">Fiche d'inscription / Registration Form</h1>
            </div>
            <AvForm
                onValidSubmit={(e, v) => {
                    handleRegistration(e, v);
                }}
            >
                <div className="row sub-section">
                    <div className="col-xl-12 sub-section-title-div">
                        <h3 className="sub-section-title">Informations sur l'enfant / Child Info</h3>
                    </div>
                    <div className="col-sm-12 col-md-6 mt-4">
                        <AvField
                            name="lastName"
                            type="text"
                            label="Nom de famille / Last name *"
                            errorMessage="Enter Last Name!"
                            value={mode && defaultValues.lastName}
                            validate={{ required: { value: true } }}
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 mt-4">
                        <AvField
                            name="firstName"
                            type="text"
                            label="Prénom / First name *"
                            errorMessage="Enter First Name!"
                            value = { mode && defaultValues.firstName}
                            validate={{ required: { value: true } }}
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 mt-4">
                        <AvField
                            name="address"
                            label="Adresse / Address *"
                            type="text"
                            errorMessage="Provide a valid address!"
                            value = { mode && defaultValues.address}
                            validate={{ required: { value: true } }}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-3 mt-4">
                        <AvField
                            name="birthday"
                            label="Date d'anniversaire / Birthday *"
                            type="date"
                            value = { mode && defaultValues.birthday}
                            errorMessage="Provide a valid date!"
                            required
                        />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-3 mt-4">
                        <AvField
                            type="select"
                            label="Genre / Gender *"
                            name="gender"
                            errorMessage={"Select the gender"}
                            value={1}
                            validate={{
                                required: { value: true },
                                pattern: {value: '^(?!(?:1)$)', errorMessage: 'Please select'}
                            }}>
                            <option value={1}>Genre / Gender</option>
                            <option value={2}>M</option>
                            <option value={3}>F</option>
                        </AvField>
                    </div>
                    <div className="col-sm-12 mt-4">
                        <label className="control-label">Langage / Language *</label>
                        <AvCheckboxGroup
                            inline
                            name="language"
                            style={{padding: "0 10px"}}
                            value = { mode && defaultValues.selectlanguages}
                            errorMessage="Select more than 1 language"
                            required
                        >
                            <AvCheckbox label="Français / French" value="French" />
                            <AvCheckbox label="Anglais / English" value="English" />
                            <AvCheckbox label="Espanol / Spanish" value="Spanish" />
                            <AvCheckbox label="Portugais / Portuguese" value="Portuguese" />
                            <AvCheckbox label="Arabe / Arabic" value="Arabic" />
                        </AvCheckboxGroup>
                    </div>
                    <div className="col-12 mt-4">
                        <label className="control-label">La fréquence (minimum 3 jours par semaine) / Expected attendance (Minimum attendance is 3 days per week) *</label>
                        <Table id="days-table" className="table table-striped table-bordered">
                            <Thead>
                                <Tr>
                                    <Th data-priority="1">Periode / Period</Th>
                                    <Th data-priority="1">Lundi / Monday</Th>
                                    <Th data-priority="3">Mardi / Tuesday</Th>
                                    <Th data-priority="1">Mercredi / Wednesday</Th>
                                    <Th data-priority="1">Jeudi / Thursday</Th>
                                    <Th data-priority="1">Venderdi/Friday</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>Matin / Morning</Td>
                                    <Td className="week-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setMMon(!mMon)} checked={mMon} />
                                    </Td>
                                    <Td className="week-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setMTue(!mTue)} checked={mTue} />
                                    </Td>
                                    <Td className="week-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setMWed(!mWed)} checked={mWed} />
                                    </Td>
                                    <Td className="week-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setMThu(!mThu)} checked={mThu} />
                                    </Td>
                                    <Td className="week-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setMFri(!mFri)} checked={mFri} />
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>Après-midi / Afternoon</Td>
                                    <Td className="week-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setAMon(!aMon)} checked={aMon} />
                                    </Td>
                                    <Td className="week-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setATue(!aTue)} checked={aTue} />
                                    </Td>
                                    <Td className="week-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setAWed(!aWed)} checked={aWed} />
                                    </Td>
                                    <Td className="week-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setAThu(!aThu)} checked={aThu} />
                                    </Td>
                                    <Td className="week-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setAFri(!aFri)} checked={aFri} />
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </div>

                    {/*Identification of parents*/}
                    <div className="col-xl-12 sub-section-title-div mt-lg-5 mt-3">
                        <h3 className="sub-section-title">Identification des parents / Identification of parents</h3>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <AvField
                            name="motherName"
                            label="Tuteur1/Tutrice1 - Tutor1 *"
                            type="text"
                            value = { mode && defaultValues.motherName}
                            errorMessage="Enter mother name"
                            validate={{ required: { value: true } }}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <AvField
                            name="motherEmail"
                            value = { mode && defaultValues.motherEmail}
                            type="email"
                            label={"Courriel / E-mail *"}
                            errorMessage="Invalid Email"
                            validate={{
                                required: { value: true },
                                email: { value: true }
                            }}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <label className="control-label">
                            Même adresse que l’enfant / Same child address
                        </label>
                        <AvRadioGroup inline name="motherSameAddress" style={{padding: "0 10px"}} onChange={handleMAddress} >
                            <AvRadio label="Oui / Yes" value={1} />
                            <AvRadio label="Non / No" value={2} />
                        </AvRadioGroup>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <AvField
                            name="motherAddress"
                            label="Adresse complète / Address"
                            type="text"
                            disabled={isMSameAddress}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <AvField
                            tag={InputMask}
                            mask="(999) 999-9999"
                            className="form-control input-color"
                            value = { mode && defaultValues.motherHomePhone}
                            name="motherHomePhone"
                            label="N. tél. maison / Home phone number"
                        />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <AvField
                            tag={InputMask}
                            mask="(999) 999-9999"
                            className="form-control input-color"
                            name="motherCellPhone"
                            label="N. tél. cellulaire / Cel phone number *"
                            value = { mode && defaultValues.motherCellPhone}
                            errorMessage={"Input phone number"}
                            required
                        />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <AvField
                            tag={InputMask}
                            mask="(999) 999-9999"
                            className="form-control input-color"
                            name="motherOfficePhone"
                            value = { mode && defaultValues.motherOfficePhone}
                            label="N. tél. travail / Office phone number"
                        />
                    </div>
                    <div className="col-sm-6 col-md-8"></div>

                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <AvField
                            name="fatherName"
                            label="Tuteur2/Tutrice2 - Tutor2"
                            type="text"
                            value = { mode && defaultValues.fatherName}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <AvField
                            name="fatherEmail"
                            type="email"
                            label={"Courriel / E-mail *"}
                            value = { mode && defaultValues.fatherEmail}
                            validate={{
                                email: { value: true }
                            }}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <label className="control-label">
                            Même adresse que l’enfant / Same child address
                        </label>
                        <AvRadioGroup inline name="fatherSameAddress" style={{padding: "0 10px"}} onChange={handleFAddress} >
                            <AvRadio label="Oui / Yes" value={1} />
                            <AvRadio label="Non / No" value={2} />
                        </AvRadioGroup>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <AvField
                            name="fatherAddress"
                            label="Adresse complète / Address"
                            type="text"
                            disabled={isFSameAddress}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <AvField
                            tag={InputMask}
                            mask="(999) 999-9999"
                            className="form-control input-color"
                            name="fatherHomePhone"
                            value = { mode && defaultValues.fatherHomePhone}
                            label="N. tél. maison / Home phone number"
                        />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <AvField
                            tag={InputMask}
                            mask="(999) 999-9999"
                            className="form-control input-color"
                            name="fatherCellPhone"
                            value = { mode && defaultValues.fatherCellPhone}
                            label="N. tél. cellulaire / Cel phone number"
                        />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <AvField
                            tag={InputMask}
                            mask="(999) 999-9999"
                            className="form-control input-color"
                            name="fatherOfficePhone"
                            value = { mode && defaultValues.fatherOfficePhone}
                            label="N. tél. travail / Office phone number"
                        />
                    </div>

                    {/*Authorized person to pick up child*/}
                    <div className="col-xl-12 sub-section-title-div mt-lg-5 mt-3">
                        <h3 className="sub-section-title">
                            Personnes autorisées à venir chercher l’enfant / Persons authorized to pickup the child
                        </h3>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <AvField
                            name="pickerName"
                            label="Nom / Name *"
                            type="text"
                            errorMessage="Enter name"
                            value = { mode && defaultValues.pickerName}
                            validate={{ required: { value: true } }}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <AvField
                            name="pickerLinkChild"
                            type="text"
                            value = { mode && defaultValues.linkChild}
                            label="Lien avec l'enfant / Link with child *"
                            validate={{
                                required: { value: true }
                            }}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <AvField
                            tag={InputMask}
                            mask="(999) 999-9999"
                            className="form-control input-color"
                            name="pickerPhone"
                            value = { mode && defaultValues.pickerPhone}
                            label="N. télephone / Phone number *"
                            errorMessage={"Input phone number"}
                            required
                        />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <AvField
                            name="pickerAddress"
                            label="Adresse complète / Address *"
                            type="text"
                            value = { mode && defaultValues.pickerAddress}
                            errorMessage={"Input address"}
                            required
                        />
                    </div>

                    {/*Contact in case of urgency (other than parents)*/}
                    <div className="col-xl-12 sub-section-title-div mt-lg-5 mt-3">
                        <h3 className="sub-section-title">
                            Personnes à contacter en cas d’urgence (autres que les parents) / Contact in case of urgency (other than parents)
                        </h3>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <AvField
                            name="urgencyName"
                            label="Nom / Name *"
                            type="text"
                            value = { mode && defaultValues.urgencyName}
                            errorMessage="Enter name"
                            validate={{ required: { value: true } }}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <AvField
                            name="urgencyLinkChild"
                            type="text"
                            value = { mode && defaultValues.urgencyLinkChild}
                            label="Lien avec l'enfant / Link with child *"
                            validate={{
                                required: { value: true }
                            }}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <AvField
                            tag={InputMask}
                            mask="(999) 999-9999"
                            value = { mode && defaultValues.urgencyPhone}
                            className="form-control input-color"
                            name="urgencyPhone"
                            label="N. télephone / Phone number *"
                            errorMessage={"Input phone number"}
                            required
                        />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                        <AvField
                            name="urgencyAddress"
                            label="Adresse complète / Address *"
                            value = { mode && defaultValues.urgencyAddress}
                            type="text"
                            errorMessage={"Input address"}
                            required
                        />
                    </div>

                    {/* Child health information */}
                    <div className="col-xl-12 sub-section-title-div mt-lg-5 mt-3">
                        <h3 className="sub-section-title">
                            Renseignements sur la santé de l’enfant / Child health information
                        </h3>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <AvField
                            tag={InputMask}
                            mask="aaaa 9999 9999"
                            className="form-control input-color"
                            name="securityNumber"
                            value = { mode && defaultValues.healthSin}
                            label="N. assurance maladie / RAMQ *"
                            required
                        />
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <AvField
                            name="expDate"
                            type="date"
                            className="form-control"
                            label="Exp. date *"
                            value = { mode && defaultValues.healthExp}
                            required
                        />
                    </div>
                    <div className="col-md-4 mt-4">
                        A-t-il des problèmes de santé qui nécessitent des soins particuliers? /
                        Are there any health problems that require special care? *
                    </div>
                    <div className="col-md-4 mt-4">
                        <AvField
                            type="select"
                            name="specialCare"
                            value={defaultValues.specialCare}
                            onChange={(e) => handleSelections(e, 0)}
                            validate={{
                                required: { value: true },
                                pattern: {value: '^(?!(?:1)$)', errorMessage: 'Please select'}
                            }}>
                            <option value={1} disabled={true}>Select</option>
                            <option value={2}>Oui / Yes</option>
                            <option value={3}>Non / No</option>
                        </AvField>
                    </div>
                    <div className="col-md-4 mt-4">
                        <AvField
                            name="specialCareDesc"
                            type="textarea"
                            className="form-control"
                            placeholder="Si oui, lesquels?"
                            disabled={selectList[0] !== 2}
                            required={selectList[0] === 2}
                            errorMessage={"Please input description"}
                            value={selectDescription[0]}
                            onChange={e => handleSelectionDesc(e, 0)}
                            rows={3}
                        />
                    </div>

                    <div className="col-md-4 mt-4">
                        Prend-il des médicaments de façon régulière? /
                        Does he/she take medication on a regular basis? *
                    </div>
                    <div className="col-md-4 mt-4">
                        <AvField
                            type="select"
                            name="medication"
                            value={defaultValues.medication}
                            onChange={(e) => handleSelections(e, 1)}
                            validate={{
                                required: { value: true },
                                pattern: {value: '^(?!(?:1)$)', errorMessage: 'Please select'}
                            }}>
                            <option value={1} disabled={true}>Select</option>
                            <option value={2}>Oui / Yes</option>
                            <option value={3}>Non / No</option>
                        </AvField>
                    </div>
                    <div className="col-md-4 mt-4">
                        <AvField
                            name="medicationDesc"
                            type="textarea"
                            className="form-control"
                            placeholder="Si oui, lesquels?"
                            disabled={selectList[1] !== 2}
                            value={selectDescription[1]}
                            required={selectList[1] === 2}
                            errorMessage={"Please input description"}
                            onChange={e => handleSelectionDesc(e, 1)}
                            rows={3}
                        />
                    </div>

                    <div className="col-md-4 mt-4">
                        Effets secondaires possibles de ces médicaments /
                        Possible side effects for these medications *
                    </div>
                    <div className="col-md-4 mt-4">
                        <AvField
                            type="select"
                            name="sideEffect"
                            value={defaultValues.sideEffects}
                            onChange={(e) => handleSelections(e, 2)}
                            validate={{
                                required: { value: true },
                                pattern: {value: '^(?!(?:1)$)', errorMessage: 'Please select'}
                            }}>
                            <option value={1} disabled={true}>Select</option>
                            <option value={2}>Oui / Yes</option>
                            <option value={3}>Non / No</option>
                        </AvField>
                    </div>
                    <div className="col-md-4 mt-4">
                        <AvField
                            name="sideEffectDesc"
                            type="textarea"
                            className="form-control"
                            placeholder="Si oui, lesquels?"
                            disabled={selectList[2] !== 2}
                            required={selectList[2] === 2}
                            errorMessage={"Please input description"}
                            value={selectDescription[2]}
                            onChange={e => handleSelectionDesc(e, 2)}
                            rows={3}
                        />
                    </div>

                    <div className="col-md-4 mt-4">
                        A-t-il reçu tous ses vaccins? / Has the child received all vaccines? *
                    </div>
                    <div className="col-md-4 mt-4">
                        <AvField
                            type="select"
                            name="vaccines"
                            value={defaultValues.vaccine}
                            validate={{
                                required: { value: true },
                                pattern: {value: '^(?!(?:1)$)', errorMessage: 'Please select'}
                            }}>
                            <option value={1} disabled>Select</option>
                            <option value={2}>Oui / Yes</option>
                            <option value={3}>Non / No</option>
                        </AvField>
                    </div>
                    <div className="col-md-4 mt-4">
                    </div>

                    <div className="col-md-4 mt-4">
                        La vaccination est-elle à jour? / Is the vaccination up to date? *
                    </div>
                    <div className="col-md-4 mt-4">
                        <AvField
                            type="select"
                            name="vaccineUpToDate"
                            value={defaultValues.vaccineUpToDate}
                            validate={{
                                required: { value: true },
                                pattern: {value: '^(?!(?:1)$)', errorMessage: 'Please select'}
                            }}>
                            <option value={1} disabled={true}>Select</option>
                            <option value={2}>Oui / Yes</option>
                            <option value={3}>Non / No</option>
                        </AvField>
                    </div>
                    <div className="col-md-4 mt-4">
                    </div>

                    <div className="col-md-4 mt-4">
                        Votre enfant souffre-t-il d’allergies (médicaments ou aliments)? / Does your child have allergies (medication or food)? *
                    </div>
                    <div className="col-md-4 mt-4">
                        <AvField
                            type="select"
                            name="allergies"
                            value={defaultValues.allergies}
                            onChange={(e) => handleSelections(e, 3)}
                            validate={{
                                required: { value: true },
                                pattern: {value: '^(?!(?:1)$)', errorMessage: 'Please select'}
                            }}>
                            <option value={1} disabled={true}>Select</option>
                            <option value={2}>Oui / Yes</option>
                            <option value={3}>Non / No</option>
                        </AvField>
                    </div>
                    <div className="col-md-4 mt-4">
                        <AvField
                            name="allergyDesc"
                            type="textarea"
                            className="form-control"
                            placeholder="Si oui, lesquels?"
                            disabled={selectList[3] !== 2}
                            required={selectList[3] === 2}
                            errorMessage={"Please input description"}
                            value={selectDescription[3]}
                            onChange={e => handleSelectionDesc(e, 3)}
                            rows={3}
                        />
                    </div>

                    <div className="col-md-4 mt-4">
                        Y a-t-il des dispositions à prendre pour sa santé selon les maladies et/ou allergies mentionnées plus haut en cas d’urgence? /
                        Are there provisions to be taken for his health according to the disease and / or allergies mentioned above in case of emergency? *
                    </div>
                    <div className="col-md-4 mt-4">
                        <AvField
                            type="select"
                            name="provision"
                            value={defaultValues.provision}
                            onChange={(e) => handleSelections(e, 4)}
                            validate={{
                                required: { value: true },
                                pattern: {value: '^(?!(?:1)$)', errorMessage: 'Please select'}
                            }}>
                            <option value={1} disabled={true}>Select</option>
                            <option value={2}>Oui / Yes</option>
                            <option value={3}>Non / No</option>
                        </AvField>
                    </div>
                    <div className="col-md-4 mt-4">
                        <AvField
                            name="provisionDesc"
                            type="textarea"
                            className="form-control"
                            placeholder="Si oui, lesquels?"
                            disabled={selectList[4] !== 2}
                            required={selectList[4] === 2}
                            errorMessage={"Please input description"}
                            value={selectDescription[4]}
                            onChange={e => handleSelectionDesc(e, 4)}
                            rows={3}
                        />
                    </div>

                    <div className="col-md-4 mt-4">
                        Nom du pédiatre et/ou du médecinde famille /
                        Name of Pediatrician And / or family doctor *
                    </div>
                    <div className="col-md-4 mt-4">
                        <AvField
                            name="doctorName"
                            placeholder="Prénom, Nom / Last"
                            type="text"
                            value = { mode && defaultValues.doctorName}
                            errorMessage="Enter Name"
                            validate={{ required: { value: true } }}
                        />
                        <br />
                        <AvField
                            tag={InputMask}
                            mask="(999) 999-9999"
                            className="form-control input-color"
                            name="doctorPhone"
                            value = { mode && defaultValues.doctorPhone}
                            placeholder="N. Télephone / Phone number"
                            required
                        />
                    </div>
                    <div className="col-md-4 mt-4">
                        <AvField
                            name="doctorAddress"
                            type="text"
                            value = { mode && defaultValues.doctorAddress}
                            className="form-control"
                            placeholder="Adresse / Address"
                            required
                        />
                    </div>

                    <div className="col-md-4 mt-4">
                        Médicaments autorisés. Ceci est requis en signant les formulaires MFA /
                        Authorized Drugs. This is required by signing MFA forms *
                    </div>
                    <div className="col-md-8 mt-4">
                        <AvField
                            name="drugSign"
                            type="text"
                            value = { mode && defaultValues.drugSign}
                            errorMessage="Enter Authorized drug"
                            validate={{ required: { value: true } }}
                        />
                    </div>

                    {/* Autorisations / Authorizations */}
                    <div className="col-xl-12 sub-section-title-div mt-lg-5 mt-3">
                        <h3 className="sub-section-title">
                            Autorisations / Authorizations
                        </h3>
                    </div>
                    <div className="mt-4 mx-0 py-4 row" style={{border: "1px solid"}}>
                        <div className="col-md-12 py-2">
                            <p>
                                J’autorise mon enfant à participer aux activités extérieures organisées par le service de garde de l’École
                                Montessori de Beauport (sorties éducatives ou sportives).
                            </p>
                            <p>
                                I authorize my child to participate on outdoors activities organized by École Montessori de Beauport. (educational or sports activities)
                            </p>
                        </div>
                        <div className="col-sm-6 py-2 d-flex align-items-center">
                            <AvInput
                                className=""
                                type="checkbox" name="parentFirstSign"
                                tag={CustomInput}
                                label={"Autorisation du parent / Parent's authorization *"}
                                errorMessage="Please check this!"
                                value = { mode && defaultValues.firstPSign}
                                required />
                        </div>
                        <div className="col-sm-6 py-2">
                            <AvField
                                name="parentFirstSignDate"
                                type="date"
                                value = { mode && defaultValues.firstSDate}
                                errorMessage="Provide a valid date!"
                                required
                            />
                        </div>
                        <div className="col-sm-12 py-2">
                            <p className="border-top" />
                        </div>
                        <div className="col-md-12 py-2">
                            <p>
                                J’autorise l’équipe de l’École Montessori de Beauport à prendre les mesures nécessaires en cas d’urgence
                                pour la santé et la sécurité de mon enfant.
                            </p>
                            <p>
                                I authorize the École Montessori de Beauport to take the necessary measures in case of urgency concerning the security and healthy of my child.
                            </p>
                            <p>
                                Y a-t-il des dispositions particulières à prendre en cas d’urgence (ambulance, hôpital, premiers soins)?<br />
                                Is there any particular or concerns we should know in case of urgency? (hospital, ambulance, first aid).
                            </p>
                        </div>
                        <div className="col-md-12 pb-2">
                            <AvField
                                name="signDesc"
                                type="textarea"
                                className="form-control"
                                placeholder="Si oui, lesquelles? / If yes, please describe."
                                rows={5}
                            />
                        </div>
                        <div className="col-sm-6 py-2 d-flex align-items-center">
                            <AvInput
                                tag={CustomInput}
                                type="checkbox"
                                name="parentSecondSign"
                                value = { mode && defaultValues.secondPSign}
                                label={"Autorisation du parent / Parent's authorization *"}
                                required />
                        </div>
                        <div className="col-sm-6 py-2">
                            <AvField
                                name="parentSecondSignDate"
                                type="date"
                                value = { mode && defaultValues.secondSDate}
                                errorMessage="Provide a valid date!"
                                required
                            />
                        </div>
                        <div className="col-sm-12 py-2">
                            <p className="border-top" />
                        </div>

                        <div className="col-md-12 py-2">
                            <p>
                                Les renseignements contenus dans cette fiche sont exacts et je m’engage à vous informer de tout changement.
                            </p>
                        </div>
                        <div className="col-sm-6 py-2 d-flex align-items-center">
                                <AvInput
                                    label={"je suis d'accord  / I agree *"}
                                    tag={CustomInput}
                                    type="checkbox"
                                    name="parentConfirmSign"
                                    value = { mode && defaultValues.confirmSign}
                                    required />
                        </div>
                        <div className="col-sm-6 py-2">
                            <AvField
                                name="parentConfirmSignDate"
                                type="date"
                                value = { mode && defaultValues.confirmSignDate}
                                errorMessage="Provide a valid date!"
                                required
                            />
                        </div>
                    </div>

                    {/* Mandatory sending documents */}
                    <div className="col-xl-12 sub-section-title-div mt-lg-5 mt-3">
                        <h3 className="sub-section-title">
                            Envoi obligatoire des documents / Mandatory sending documents
                        </h3>
                    </div>
                    <div className="col-md-12 py-2 px-0">
                        <Label className="form-label">Certificat de naissance / Birth certificate (jpg, png, jpeg, pdf) max 10mb</Label>
                        <div className="input-group">
                            <input onChange={e => setBirthFile(e.target.files[0])} accept="application/pdf, image/*" type="file" name="birthCert" className="form-control" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" aria-label="Upload" />
                            {/*<Button className="input-btn" color="primary" type="button" id="inputGroupFileAddon01">Upload</Button>*/}
                        </div>
                    </div>
                    <div className="col-md-12 py-2 px-0">
                        <Label className="form-label">Prélèvement bancaire (jpg, png, jpeg, pdf) max 10mb</Label>
                        <div className="input-group">
                            <input onChange={e => {
                                    setBankFile(e.target.files[0])
                                }}
                               accept="application/pdf, image/*"
                               type="file" name="bankDebit" className="form-control" id="inputGroupFile02" aria-describedby="inputGroupFileAddon02" aria-label="Upload" />
                        </div>
                    </div>
                    <div className="col-md-12 py-2 px-0">
                        <ul>
                            <li>
                                Svp faire le download du Prélèvement bancaire
                                <a href="https://firebasestorage.googleapis.com/v0/b/beauport-firebase-app.appspot.com/o/certification_docs%2FAccord%20DPA%20du%20payeur%20-%20%20Sporadique.pdf?alt=media&token=d04f34ee-c24b-4e59-b7ca-15ff0640aff7" download={true} target="_blank"> ici</a>
                                , le remplir, prend une photo et faire l'upload
                            </li>
                            <li>
                                Please download the document
                                <a href="https://firebasestorage.googleapis.com/v0/b/beauport-firebase-app.appspot.com/o/certification_docs%2FAccord%20DPA%20du%20payeur%20-%20%20Sporadique.pdf?alt=media&token=d04f34ee-c24b-4e59-b7ca-15ff0640aff7" download={true} target="_blank"> here</a>
                                , fill it out, take a picture and upload above
                            </li>
                        </ul>
                    </div>

                    <ReCAPTCHA
                        sitekey="6LeBLiAcAAAAAMKdP_2zIjbv4e4yj8ptqFtAGdEf"
                        ref={recaptchaRef}
                        size='invisible'
                        hl={'fr'}
                        // className="m-auto"
                    />
                    {/*Submit button*/}
                    <div className="col-md-12 py-2 text-center">
                        <Button
                            type="submit"
                            color="primary"
                            className="py-2 px-4"
                            disabled={props.loading}
                        >
                            {props.loading ? (
                                <React.Fragment>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                    Saving...
                                </React.Fragment>
                            ) : "Envoyer / Send"}
                        </Button>
                    </div>
                </div>
            </AvForm>

            {success_dlg ? (
                <SweetAlert
                    success
                    title="Merci!"
                    onConfirm={() => {
                        setsuccess_dlg(false);
                        setTimeout(() => {
                            window.location.href = "https://www.montessori-beauport.com"
                        }, [2000])
                    }}
                >
                    La fiche d'inscription est enregistré <br />
                    Vous serez redirigé vers notre site web
                </SweetAlert>
            ) : null}
        </div>
    )
}

const mapStateToProps = state => {
    const { loading, result } = state.StudentRegisterReducer;
    return { loading, result };
}

export default withRouter(connect(mapStateToProps, { registerStudent })(withNamespaces()(RegistrationForm)));
