export const CREATE_WAITING_LIST = 'CREATE_WAITING_LIST';
export const CREATE_WAITING_LIST_SUCCESS = 'CREATE_WAITING_LIST_SUCCESS';
export const CREATE_WAITING_LIST_FAILED = 'CREATE_WAITING_LIST_FAILED';

export const GET_ALL_WAITING_LIST = 'GET_ALL_WAITING_LIST';
export const GET_ALL_WAITING_LIST_SUCCESS = 'GET_ALL_WAITING_LIST_SUCCESS';

export const UPDATE_WAITING_LIST = 'UPDATE_WAITING_LIST';
export const UPDATE_WAITING_LIST_SUCCESS = 'UPDATE_WAITING_LIST_SUCCESS';
export const UPDATE_WAITING_LIST_FAILED = 'UPDATE_WAITING_LIST_FAILED';

export const DELETE_ONE_WAITER = 'DELETE_ONE_WAITER';
