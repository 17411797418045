import {
    CREATE_WAITING_LIST,
    CREATE_WAITING_LIST_SUCCESS,
    CREATE_WAITING_LIST_FAILED,
    GET_ALL_WAITING_LIST,
    GET_ALL_WAITING_LIST_SUCCESS,
    UPDATE_WAITING_LIST,
    UPDATE_WAITING_LIST_SUCCESS,
    UPDATE_WAITING_LIST_FAILED,
    DELETE_ONE_WAITER
} from './actionTypes';

export const getWaitingList = (page) => {
    return {
        type: GET_ALL_WAITING_LIST,
        payload: page
    }
}

export const deleteOneElement = (index) => {
    return {
        type: DELETE_ONE_WAITER,
        payload: index
    }
}

export const getWaitingListSuccess = (waitingList, total) => {
    return {
        type: GET_ALL_WAITING_LIST_SUCCESS,
        payload: waitingList,
        total: total
    }
}

export const totalUsers = (total) => {
    return {
        type: ALL_USERS,
        payload: total
    }
}

export const createWaitingList = (waitingList) => {
    return {
        type: CREATE_WAITING_LIST,
        payload: { waitingList }
    }
}

export const createWaitingListSuccess = () => {
    return {
        type: CREATE_WAITING_LIST_SUCCESS,
        payload: {}
    }
}

export const createWaitingListFailed = () => {
    return {
        type: CREATE_WAITING_LIST_FAILED
    }
}

export const updateWaitingList = (newWaiter) => {
    return {
        type: UPDATE_WAITING_LIST,
        payload: { newWaiter }
    }
}

export const updateWaitingListSuccess = (updatedInfo, index) => {
    return {
        type: UPDATE_WAITING_LIST_SUCCESS,
        payload: updatedInfo,
        index: index
    }
}

export const updateWaitingListFailed = () => {
    return {
        type: UPDATE_WAITING_LIST_FAILED
    }
}
