import {
    GET_A_STUDENT,
    GET_A_STUDENT_SUCCESS,
    GET_A_STUDENT_FAILED,
    UPDATE_A_STUDENT,
    UPDATE_A_STUDENT_SUCCESS,
    UPDATE_A_STUDENT_FAILED,
    GET_TEACHERS_FOR_A_STUDENT,
    GET_MEDICAL_DOCS,
    UPLOAD_DOC,
    UPLOAD_DOC_SUCCESS,
    UPLOAD_DOC_FAILED,
    DELETE_DOC,
    DELETE_DOC_SUCCESS,
    DELETE_DOC_FAILED
} from './actionTypes';

export const uploadDoc = (data) => {
    return {
        type: UPLOAD_DOC,
        payload: data
    }
}

export const uploadDocSuccess = (student) => {
    return {
        type: UPLOAD_DOC_SUCCESS,
        payload: student
    }
}

export const uploadDocFailed = () => {
    return {
        type: UPLOAD_DOC_FAILED
    }
}

export const deleteDoc = (data) => {
    return {
        type: DELETE_DOC,
        payload: data
    }
}

export const deleteDocSuccess = (student) => {
    return {
        type: DELETE_DOC_SUCCESS,
        payload: student
    }
}

export const deleteDocFailed = () => {
    return {
        type: DELETE_DOC_FAILED
    }
}

export const getAStudent = (id) => {
    return {
        type: GET_A_STUDENT,
        payload: id
    }
}

export const getAStudentSuccess = (student) => {
    return {
        type: GET_A_STUDENT_SUCCESS,
        payload: student,
    }
}

export const getTeachersForChild = (teachers) => {
    return {
        type: GET_TEACHERS_FOR_A_STUDENT,
        payload: teachers
    }
}

export const getMedicalDocs = (docs) => {
    return {
        type: GET_MEDICAL_DOCS,
        payload: docs
    }
}

export const getAStudentFailed = (error) => {
    return {
        type: GET_A_STUDENT_FAILED,
        payload: error,
    }
}

export const updateAStudent = (student) => {
    return {
        type: UPDATE_A_STUDENT,
        payload: student
    }
}

export const updateAStudentSuccess = () => {
    return {
        type: UPDATE_A_STUDENT_SUCCESS,
        payload: {}
    }
}

export const updateAStudentFailed = (error) => {
    return {
        type: UPDATE_A_STUDENT_FAILED,
        payload: error
    }
}





