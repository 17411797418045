import React, { useState, useEffect } from 'react';
import {Link, Redirect, withRouter} from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images
import admin from "../../../assets/images/admin.png";

//i18n
import { withNamespaces } from 'react-i18next';
import { getNotifications } from '../../../store/actions';
import { inactiveNotification } from '../../../apiHelpers/notificationApiHelper';
import moment from "moment";
import { Badge } from 'antd';
import {connect} from "react-redux";


const NotificationDropdown = (props) => {

 // Declare a new state variable, which we'll call "menu"
  useEffect(() => {
    if(localStorage.getItem("authUser") && JSON.parse(localStorage.getItem("authUser")).data.role !== "Teacher") {
      props.getNotifications()
    }
  }, [])

  const [menu, setMenu] = useState(false);

  const showNotifications = () => {
    if(props.notifications) {
      return props.notifications.map((item, index) => {
        return (
            <Link key={index} to="/report-show" className="text-reset notification-item" >
              <div className="media" onClick={() => revertNotification(item.id, item.status)}>
                <img src={admin} className="mr-3 rounded-circle avatar-xs" alt="user-pic" />
                <div className="media-body">
                  {item.status ? (
                      <Badge dot>
                        <h6 className="mt-0 mb-1">Teacher</h6>
                      </Badge>
                  ) : (
                      <h6 className="mt-0 mb-1">Teacher</h6>
                  )}
                  <span className="badge badge-danger badge-pill"></span>
                  <div className="font-size-12">
                    <p className="mb-1">{props.t('New Report has been published. Please check') + "."}</p>
                    <p className="mb-0"><i className="mdi mdi-clock-outline"></i>   {moment(item.created_at).fromNow()} </p>
                  </div>
                </div>
              </div>
            </Link>
        )
      })
    }
  }

  const revertNotification = (item, status) => {
    if(status) {
      inactiveNotification({id: item})
          .then((result) => {
            if(result.status === 200) {
              props.getNotifications()
            } else {
            }
          })
          .catch((error) => {
            throw error.response
          })
    }
  }

  return (
    <>
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className="btn header-item noti-icon waves-effect"
            tag="button" id="page-header-notifications-dropdown"
          >
            <i className="bx bx-bell bx-tada"></i>
            {props.notificationCounts && props.notificationCounts !== "nothing" && (
              <span className="badge badge-danger badge-pill">
                {props.notificationCounts}
              </span>
            )}
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {props.t('Notifications')} </h6>
                </Col>
              </Row>
            </div>

            <SimpleBar style={{ height: "230px" }}>
              {showNotifications()}
            </SimpleBar>
            <div className="p-2 border-top">
            </div>
          </DropdownMenu>
        </Dropdown>
    </>
  );
}

const mapStateToProps = state => {
  const { notifications, notificationCounts } = state.NotificationReducer;
  return { notifications, notificationCounts };
}

export default withRouter(connect(mapStateToProps, { getNotifications })(withNamespaces()(NotificationDropdown)));