import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Input, Card, CardBody, Modal, ModalHeader, ModalBody, FormGroup, Label } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Avatar from '../../assets/images/avatar.png';
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Steps, DatePicker } from 'antd';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from "reactstrap"

import { connect } from 'react-redux';

// Form Mask
import InputMask from "react-input-mask";

import { getAStudent, updateAStudent, uploadDoc, deleteDoc } from '../../store/actions';
import Select from "react-select";
//i18n
import { withNamespaces } from 'react-i18next';
import { AvForm, AvField } from "availity-reactstrap-validation";
import toastr from "toastr";
import moment from "moment";
import axios from "axios";
const { Step } = Steps;
const firebase = require("firebase/app");



const Student = (props) => {

    const [modal, setmodal] = useState(false);
    const [current, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20)


    const [id, setId] = useState('cool')

    const [stepCurrent, setStepCurrent] = useState(0)

    const [isCreate, setIsCreate] = useState(null)
    //sweet alert settings
    const [confirm_both, setconfirm_both] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [error_dlg, seterror_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [delData, setDelData] = useState(null);

    const [confirmUpload, setConfirmUpload] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const [allChecked, setAllChecked] = useState(false)
    //step 1 states
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [address, setAddress] = useState('')
    const [birthday, setBirthday] = useState('')
    const [gender, setGender] = useState('Gender')
    const [selectlanguages, setSelectlanguages] = useState(null);

    //step 2 states
    const [mMon, setMMon] = useState(false)
    const [mTue, setMTue] = useState(false)
    const [mWed, setMWed] = useState(false)
    const [mThu, setMThu] = useState(false)
    const [mFri, setMFri] = useState(false)

    const [aMon, setAMon] = useState(false)
    const [aTue, setATue] = useState(false)
    const [aWed, setAWed] = useState(false)
    const [aThu, setAThu] = useState(false)
    const [aFri, setAFri] = useState(false)

    //step 3 states
    //* Mother info
    const [motherName, setMotherName] = useState('')
    const [motherEmail, setMotherEmail] = useState('')
    const [motherAddress, setMotherAddress] = useState('')
    const [motherSameAddress, setMotherSameAddress] = useState('Select')
    const [motherHomePhone, setMotherHomePhone] = useState("(999) 999-9999")
    const [motherCellPhone, setMotherCellPhone] = useState("(999) 999-9999")
    const [motherOfficePhone, setMotherOfficePhone] = useState("(999) 999-9999")
    //* Father info
    const [fatherName, setFatherName] = useState('')
    const [fatherEmail, setFatherEmail] = useState('')
    const [fatherSameAddress, setFatherSameAddress] = useState('Select')
    const [fatherAddress, setFatherAddress] = useState('')
    const [fatherHomePhone, setFatherHomePhone] = useState("(999) 999-9999")
    const [fatherCellPhone, setFatherCellPhone] = useState("(999) 999-9999")
    const [fatherOfficePhone, setFatherOfficePhone] = useState("(999) 999-9999")
    // Step 4 states
    const [pickerName, setPickerName] = useState('')
    const [pickerPhone, setPickerPhone] = useState("(999) 999-9999")
    const [linkChild, setLinkChild] = useState('')
    const [pickerAddress, setPickerAddress] = useState('')
    //step 5 states
    const [urgencyName, setUrgencyName] = useState('')
    const [urgencyPhone, setUrgencyPhone] = useState("(999) 999-9999")
    const [urgencyLinkChild, setUrgencyLinkChild] = useState('')
    const [urgencyAddress, setUrgencyAddress] = useState('')
    //step 6 states
    const [healthSin, setHealthSin] = useState('')
    const [healthExp, setHealthExp] = useState('')
    const [specialCare, setSpecialCare] = useState('Special Care?')
    const [specialCareDesc, setSpecialCareDesc] = useState('')
    const [medication, setMedication] = useState('Medication')
    const [medicationDesc, setMedicationDesc] = useState('')
    const [sideEffects, setSideEffects] = useState('Side Effects')
    const [sideEffectsDesc, setSideEffectsDesc] = useState('')
    const [vaccine, setVaccine] = useState('Vaccines')
    const [vaccineUpToDate, setVaccineUpToDate] = useState('Is the vaccination up to date?')
    const [allergies, setAllergies] = useState('Allergies')
    const [allergyDesc, setAllergyDesc] = useState('')
    const [provision, setProvision] = useState('Health prov')
    const [provisionDesc, setProvisionDesc] = useState('')
    const [doctorName, setDoctorName] = useState('')
    const [doctorAddress, setDoctorAddress] = useState('')
    const [doctorPhone, setDoctorPhone] = useState('(999) 999-9999')
    const [drugSign, setDrugSign] = useState('')
    //step 7 states
    const [firstPSign, setFirstPSign] = useState(false)
    const [firstSDate, setFirstSDate] = useState(null)
    const [signDesc, setSignDesc] = useState('')
    const [secondPSign, setSecondPSign] = useState(false)
    const [secondSDate, setSecondSDate] = useState(null)

    const uploadRef = useRef(null);
    const [uploadingFile, setUploadingFile] = useState(null);
    const [uploadingType, setUploadingType] = useState(undefined);


    const optionGroup = [
        { label: "English", value: "English" },
        { label: "French", value: "French" },
        { label: "Spanish", value: "Spanish" },
        { label: "Portuguese", value: "Portuguese" }
    ];

    useEffect(() => {
        const id = props.match.params.id
        props.getAStudent(id)
    }, [])

    useEffect(() => {
        if (props.isModal === "success") {
            clearStates()
            setmodal(false)
            setStepCurrent(0)
        }
    }, [props.isModal])

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const validate = (step) => {
        switch (step) {
            case 0:
                if (firstName.length < 3 || firstName.length > 100) {
                    toastr.warning("Please input valid First Name!")
                    return false
                }
                if (lastName.length < 3 || lastName.length > 100) {
                    toastr.warning("Please input valid Last Name!")
                    return false
                }
                if (address.length < 3 || address.length > 100) {
                    toastr.warning("Please input valid Address!")
                    return false
                }
                if (birthday.length === 0) {
                    toastr.warning("Please select birthday")
                    return false
                }
                if (gender === "Gender") {
                    toastr.warning("Please select gender")
                    return false
                }
                if (!selectlanguages) {
                    toastr.warning("Please select languages")
                    return false
                }
                const current = stepCurrent + 1;
                setStepCurrent(current)
                break;
            case 1:
                let ckecks = [mMon, mTue, mWed, mThu, mFri, aMon, aTue, aWed, aThu, aFri]
                let total = 0
                ckecks.forEach(item => {
                    if (item) {
                        total++;
                    }
                });
                if (total < 3) {
                    toastr.warning("Have to check more than 3 days")
                    return false
                }
                setStepCurrent(stepCurrent + 1)
                break;
            case 2:
                if (motherName.length < 3 || motherName.length > 100) {
                    toastr.warning("Please input valid Mother/Tutor Name!")
                    return false
                }
                if (motherEmail.length === 0) {
                    toastr.warning("Please input Mother/Tutor Email!")
                    return false
                } else {
                    const valid = validateEmail(motherEmail)
                    if(!valid) {
                        toastr.warning("Please input valid email address!", "Info")
                        return false
                    }
                }
                if (motherSameAddress === 'Select') {
                    toastr.warning("Please select one!")
                    return false
                }
                if (motherSameAddress === 'No' && motherAddress === '') {
                    toastr.warning("Please input child address detail!")
                    return false
                }
                if (motherHomePhone === '(999) 999-9999') {
                    toastr.warning("Please input mother's home phone number!")
                    return false
                }
                if (fatherEmail) {
                    const valid = validateEmail(fatherEmail)
                    if(!valid) {
                        toastr.warning("Please input valid email address!", "Info")
                        return false
                    }
                }
                setStepCurrent(stepCurrent + 1)
                break;
            case 3:
                setStepCurrent(stepCurrent + 1)
                break;
            case 4:
                setStepCurrent(stepCurrent + 1)
                break;
            case 5:
                setStepCurrent(stepCurrent + 1)
                break;
            case 6:
                if (!firstPSign) {
                    toastr.warning("Please check to concerning outside activity authorization.")
                    return false
                }
                if (!firstSDate) {
                    toastr.warning("Please select date authorization above has been made")
                    return false
                }
                if (!secondPSign) {
                    toastr.warning("Please check to concerning authorization for the admission")
                    return false
                }
                if (!secondSDate) {
                    toastr.warning("Please select date authorization for the child admission has been made.")
                    return false
                }
                setStepCurrent(stepCurrent + 1)
                break;
            default:
                break;
        }
    }


    function onShowSizeChange(current, pageSize) {
        props.getAllStudents({ page: current, pageSize: pageSize })
        setPageSize(pageSize)
        setCurrentPage(current)
    }
    const onChange = (page) => {
        props.getAllStudents({ page: page, pageSize: pageSize })
        setCurrentPage(page)
    }

    const handleDel = (val) => {
        setDelData(val)
        setconfirm_both(true)
    }

    const handleSave = () => {
        const params = {
            firstName: firstName,
            lastName: lastName,
            address: address,
            birthday: birthday,
            gender: gender,
            selectlanguages: selectlanguages,
            mMon: mMon,
            mTue: mTue,
            mWed: mWed,
            mThu: mThu,
            mFri: mFri,
            aMon: aMon,
            aTue: aTue,
            aWed: aWed,
            aThu: aThu,
            aFri: aFri,
            motherName: motherName,
            motherEmail: motherEmail,
            motherAddress: motherAddress,
            motherSameAddress: motherSameAddress,
            motherHomePhone: motherHomePhone,
            motherCellPhone: motherCellPhone,
            motherOfficePhone: motherOfficePhone,
            fatherName: fatherName,
            fatherEmail: fatherEmail,
            fatherSameAddress: fatherSameAddress,
            fatherAddress: fatherAddress,
            fatherHomePhone: fatherHomePhone,
            fatherCellPhone: fatherCellPhone,
            fatherOfficePhone: fatherOfficePhone,
            pickerName: pickerName,
            pickerPhone: pickerPhone,
            linkChild: linkChild,
            pickerAddress: pickerAddress,
            urgencyName: urgencyName,
            urgencyPhone: urgencyPhone,
            urgencyLinkChild: urgencyLinkChild,
            urgencyAddress: urgencyAddress,
            healthSin: healthSin,
            healthExp: healthExp,
            specialCare: specialCare,
            specialCareDesc: specialCareDesc,
            medication: medication,
            medicationDesc: medicationDesc,
            sideEffects: sideEffects,
            sideEffectsDesc: sideEffectsDesc,
            vaccine: vaccine,
            vaccineUpToDate: vaccineUpToDate,
            allergies: allergies,
            allergyDesc: allergyDesc,
            provision: provision,
            provisionDesc: provisionDesc,
            doctorName: doctorName,
            doctorAddress: doctorAddress,
            doctorPhone: doctorPhone,
            drugSign: drugSign,
            firstPSign: firstPSign,
            firstSDate: firstSDate,
            signDesc: signDesc,
            secondPSign: secondPSign,
            secondSDate: secondSDate
        }
        // props.createStudent(params)
        clearStates()
    }

    const clearStates = () => {
        //step 1 states
        setFirstName('')
        setLastName('')
        setAddress('')
        setBirthday('')
        setGender('Gender')
        setSelectlanguages(null)

        //step 2 states
        setMMon(false)
        setMTue(false)
        setMWed(false)
        setMThu(false)
        setMFri(false)

        setAMon(false)
        setATue(false)
        setAWed(false)
        setAThu(false)
        setAFri(false)

        //step 3 states
        //* Mother info
        setMotherName('')
        setMotherEmail('')
        setMotherAddress('')
        setMotherSameAddress('Select')
        setMotherHomePhone("(999) 999-9999")
        setMotherCellPhone("(999) 999-9999")
        setMotherOfficePhone("(999) 999-9999")
        //* Father info
        setFatherName('')
        setFatherEmail('')
        setFatherSameAddress('Select')
        setFatherAddress('')
        setFatherHomePhone("(999) 999-9999")
        setFatherCellPhone("(999) 999-9999")
        setFatherOfficePhone("(999) 999-9999")
        // Step 4 states
        setPickerName('')
        setPickerPhone("(999) 999-9999")
        setLinkChild('')
        setPickerAddress('')
        //step 5 states
        setUrgencyName('')
        setUrgencyPhone("(999) 999-9999")
        setUrgencyLinkChild('')
        setUrgencyAddress('')
        //step 6 states
        setHealthSin('')
        setHealthExp(null)
        setSpecialCare('Special Care?')
        setSpecialCareDesc('')
        setMedication('Medication')
        setMedicationDesc('')
        setSideEffects('Side Effects')
        setSideEffectsDesc('')
        setVaccine('Vaccines')
        setVaccineUpToDate('Is the vaccination up to date?')
        setAllergies('Allergies')
        setAllergyDesc('')
        setProvision('Health prov')
        setProvisionDesc('')
        setDoctorName('')
        setDoctorAddress('')
        setDoctorPhone('(999) 999-9999')
        setDrugSign('')
        //step 7 states
        setFirstPSign(false)
        setFirstSDate(null)
        setSignDesc('')
        setSecondPSign(false)
        setSecondSDate(null)

        //checkbox
        setAllChecked(false)
    }

    const handlePreUpdate = () => {
        // setIsCreate(false)
        setmodal(true)
        if (props.student) {
            const student = props.student
            setId(student.id)
            if (student.mMon && student.mTue && student.mWed && student.mThu && student.mFri && student.aMon && student.aTue && student.aWed && student.aThu && student.aFri) {
                setAllChecked(true)
            }
            //step 1 states
            setFirstName(student.firstName ? student.firstName : '')
            setLastName(student.lastName ? student.lastName : '')
            setAddress(student.address ? student.address : '')
            setBirthday(student.birthday ? (student.birthday).substring(0, 10) : '')
            setGender(student.gender ? student.gender : 'Gender')
            setSelectlanguages(student.selectlanguages ? student.selectlanguages : null)

            //step 2 states
            setMMon(student.mMon ? student.mMon : false)
            setMTue(student.mTue ? student.mTue : false)
            setMWed(student.mWed ? student.mWed : false)
            setMThu(student.mThu ? student.mThu : false)
            setMFri(student.mFri ? student.mFri : false)

            setAMon(student.aMon ? student.aMon : false)
            setATue(student.aTue ? student.aTue : false)
            setAWed(student.aWed ? student.aWed : false)
            setAThu(student.aThu ? student.aThu : false)
            setAFri(student.aFri ? student.aFri : false)

            //step 3 states
            //* Mother info
            setMotherName(student.motherName ? student.motherName : '')
            setMotherEmail(student.motherEmail ? student.motherEmail : '')
            setMotherAddress(student.motherAddress ? student.motherAddress : '')
            setMotherSameAddress(student.motherSameAddress ? student.motherSameAddress : 'Select')
            setMotherHomePhone(student.motherHomePhone ? student.motherHomePhone : '(999) 999-9999')
            setMotherCellPhone(student.motherCellPhone ? student.motherCellPhone : '(999) 999-9999')
            setMotherOfficePhone(student.motherOfficePhone ? student.motherOfficePhone : '(999) 999-9999')
            //* Father info
            setFatherName(student.fatherName ? student.fatherName : '')
            setFatherEmail(student.fatherEmail ? student.fatherEmail : '')
            setFatherSameAddress(student.fatherSameAddress ? student.fatherSameAddress : 'Select')
            setFatherAddress(student.fatherAddress ? student.fatherAddress : '')
            setFatherHomePhone(student.fatherHomePhone ? student.fatherHomePhone : '(999) 999-9999')
            setFatherCellPhone(student.fatherCellPhone ? student.fatherCellPhone : '(999) 999-9999')
            setFatherOfficePhone(student.fatherOfficePhone ? student.fatherOfficePhone : '(999) 999-9999')
            // Step 4 states
            setPickerName(student.pickerName ? student.pickerName : '')
            setPickerPhone(student.pickerPhone ? student.pickerPhone : '(999) 999-9999')
            setLinkChild(student.linkChild ? student.linkChild : '')
            setPickerAddress(student.pickerAddress ? student.pickerAddress : '')
            //step 5 states
            setUrgencyName(student.urgencyName ? student.urgencyName : '')
            setUrgencyPhone(student.urgencyPhone ? student.urgencyPhone : '(999) 999-9999')
            setUrgencyLinkChild(student.urgencyLinkChild ? student.urgencyLinkChild : '')
            setUrgencyAddress(student.urgencyAddress ? student.urgencyAddress : '')
            //step 6 states
            setHealthSin(student.healthSin ? student.healthSin : '')
            setHealthExp(student.healthExp ? (student.healthExp).substring(0, 10) : null)
            setSpecialCare(student.specialCare ? student.specialCare : 'Special Care?')
            setSpecialCareDesc(student.specialCareDesc ? student.specialCareDesc : '')
            setMedication(student.medication ? student.medication : 'Medication')
            setMedicationDesc(student.medicationDesc ? student.medicationDesc : '')
            setSideEffects(student.sideEffects ? student.sideEffects : 'Side Effects')
            setSideEffectsDesc(student.sideEffectsDesc ? student.sideEffectsDesc : '')
            setVaccine(student.vaccine ? student.vaccine : 'Vaccines')
            setVaccineUpToDate(student.vaccineUpToDate ? student.vaccineUpToDate : 'Is the vaccination up to date?')
            setAllergies(student.allergies ? student.allergies : 'Allergies')
            setAllergyDesc(student.allergyDesc ? student.allergyDesc : '')
            setProvision(student.provision ? student.provision : 'Health prov')
            setProvisionDesc(student.provisionDesc ? student.provisionDesc : '')
            setDoctorName(student.doctorName ? student.doctorName : '')
            setDoctorAddress(student.doctorAddress ? student.doctorAddress : '')
            setDoctorPhone(student.doctorPhone ? student.doctorPhone : '(999) 999-9999')
            setDrugSign(student.drugSign ? student.drugSign : '')
            //step 7 states
            setFirstPSign(student.firstPSign ? student.firstPSign : false)
            setFirstSDate(student.firstSDate ? (student.firstSDate).substring(0, 10) : null)
            setSignDesc(student.signDesc ? student.signDesc : '')
            setSecondPSign(student.secondPSign ? student.secondPSign : false)
            setSecondSDate(student.secondSDate ? (student.secondSDate).substring(0, 10) : null)

        } else {
            console.log("")
        }
    }

    const handleUpdate = () => {
        if (!firstPSign) {
            toastr.warning("Please check to concerning outside activity authorization.")
            return false
        }
        if (!firstSDate) {
            toastr.warning("Please select date authorization above has been made")
            return false
        }
        if (!secondPSign) {
            toastr.warning("Please check to concerning authorization for the admission")
            return false
        }
        if (!secondSDate) {
            toastr.warning("Please select date authorization for the child admission has been made.")
            return false
        }
        const params = {
            firstName: firstName,
            lastName: lastName,
            address: address,
            birthday: birthday,
            gender: gender,
            selectlanguages: selectlanguages,
            mMon: mMon,
            mTue: mTue,
            mWed: mWed,
            mThu: mThu,
            mFri: mFri,
            aMon: aMon,
            aTue: aTue,
            aWed: aWed,
            aThu: aThu,
            aFri: aFri,
            motherName: motherName,
            motherEmail: motherEmail,
            motherAddress: motherSameAddress === "Yes" ? address : motherAddress,
            motherSameAddress: motherSameAddress,
            motherHomePhone: motherHomePhone,
            motherCellPhone: motherCellPhone,
            motherOfficePhone: motherOfficePhone,
            fatherName: fatherName,
            fatherEmail: fatherEmail,
            fatherSameAddress: fatherSameAddress,
            fatherAddress: fatherSameAddress === "Yes" ? address : fatherAddress,
            fatherHomePhone: fatherHomePhone,
            fatherCellPhone: fatherCellPhone,
            fatherOfficePhone: fatherOfficePhone,
            pickerName: pickerName,
            pickerPhone: pickerPhone,
            linkChild: linkChild,
            pickerAddress: pickerAddress,
            urgencyName: urgencyName,
            urgencyPhone: urgencyPhone,
            urgencyLinkChild: urgencyLinkChild,
            urgencyAddress: urgencyAddress,
            healthSin: healthSin === '' ? null : healthSin,
            healthExp: healthExp,
            specialCare: specialCare,
            specialCareDesc: specialCareDesc,
            medication: medication,
            medicationDesc: medicationDesc,
            sideEffects: sideEffects,
            sideEffectsDesc: sideEffectsDesc,
            vaccine: vaccine,
            vaccineUpToDate: vaccineUpToDate,
            allergies: allergies,
            allergyDesc: allergyDesc,
            provision: provision,
            provisionDesc: provisionDesc,
            doctorName: doctorName,
            doctorAddress: doctorAddress,
            doctorPhone: doctorPhone,
            drugSign: drugSign,
            firstPSign: firstPSign,
            firstSDate: firstSDate,
            signDesc: signDesc,
            secondPSign: secondPSign,
            secondSDate: secondSDate
        }
        params.id = id
        props.updateAStudent(params)
    }


    //Step1 functions

    const handleFirstName = (e) => {
        setFirstName(e.currentTarget.value)
    }

    const handleLastName = (e) => {
        setLastName(e.currentTarget.value)
    }

    const handleAddress = (e) => {
        setAddress(e.currentTarget.value)
    }

    const handleBirthChange = (e) => {
        setBirthday(e.currentTarget.value)
    }

    const handleGender = (e) => {
        setGender(e.currentTarget.value)
    }
    const handleMultiLang = (selectlanguages) => { setSelectlanguages(selectlanguages); }


    const postDelete = () => {
        const headers = JSON.parse(localStorage.getItem("authHeaders"))
        const config = {
            method: "delete",
            url: `/api/v1/medical_docs/${delData.id}`,
            headers: {
                accesstoken: headers.accesstoken,
                client: headers.client,
                uid: headers.uid,
            }
        }
        return axios(config).then(response => {
            if (response.status === 400 || response.status === 500)
                throw response.data;
            setsuccess_dlg(true);
            setdynamic_title("Deleted");
            setdynamic_description("Medical document has been deleted.");
        }).catch(err => {
            seterror_dlg(true)
            throw err[1];
        });
    }

    const steps = [
        {
            title: "Child Info",
            content:
                <Card className="mt-5">
                    <CardBody>
                        <AvForm className="needs-validation" >
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label htmlFor="validationCustom01">{props.t('First name')}</Label>
                                        <AvField
                                            name="firstname"
                                            placeholder="First name"
                                            value={firstName}
                                            type="text"
                                            errorMessage="Enter First Name"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="validationCustom01"
                                            onChange={handleFirstName}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label htmlFor="validationCustom02">{props.t('Last name')}</Label>
                                        <AvField
                                            name="lastname"
                                            placeholder="Last name"
                                            type="text"
                                            value={lastName}
                                            errorMessage="Enter Last name"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="validationCustom02"
                                            onChange={handleLastName}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label htmlFor="validationCustom03">{props.t('Address')}</Label>
                                        <AvField
                                            name="address"
                                            placeholder="Address"
                                            type="text"
                                            value={address}
                                            onChange={handleAddress}
                                            errorMessage=" Please provide a valid address."
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="validationCustom03"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="validationCustom04">{props.t('Birthday')}</Label>
                                        <AvField
                                            name="date"
                                            type="date"
                                            errorMessage="Please provide a valid date."
                                            className="form-control"
                                            id="validationCustom04"
                                            value={birthday}
                                            onChange={handleBirthChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label>{props.t('Gender')}</Label>
                                        <AvField type="select" value={gender} name="gender" validate={{ required: { value: true } }} onChange={handleGender}>
                                            <option>Gender</option>
                                            <option>M</option>
                                            <option>F</option>
                                        </AvField>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="select2-container">
                                        <label className="control-label">{props.t('Language')}</label>
                                        <Select
                                            value={selectlanguages}
                                            isMulti={true}
                                            onChange={handleMultiLang}
                                            options={optionGroup}
                                            closeMenuOnSelect={false}
                                            classNamePrefix="select2-selection"
                                            name="language"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </AvForm>
                    </CardBody>
                </Card>
        },
        {
            title: 'Planned Attendance',
            content:
                <Card className="mt-5">
                    <CardBody>
                        <AvForm className="needs-validation" >
                            <Row>
                                <div className="form-check form-check-right mb-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        // id="defaultCheck2"
                                        onChange={() => {
                                            setAllChecked(!allChecked)
                                            if (!allChecked) {
                                                Selected(true)
                                            } else {
                                                Selected(false)
                                            }
                                        }}
                                        checked={allChecked}
                                    />
                                    <label className="form-check-label" htmlFor="defaultCheck2" style={{ marginTop: "0.8px" }}>
                                        Click to select all days
                                    </label>
                                </div>
                                <Table id="tech-companies-1" className="table table-striped table-bordered">
                                    <Thead>
                                        <Tr>
                                            <Th data-priority="1">Period</Th>
                                            <Th data-priority="1">Monday</Th>
                                            <Th data-priority="3">Tuesday</Th>
                                            <Th data-priority="1">Wednesday</Th>
                                            <Th data-priority="1">Thursday</Th>
                                            <Th data-priority="1">Friday</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr>
                                            <Td>Morning</Td>
                                            <Td className="week-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setMMon(!mMon)} checked={mMon} />
                                            </Td>
                                            <Td className="week-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setMTue(!mTue)} checked={mTue} />
                                            </Td>
                                            <Td className="week-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setMWed(!mWed)} checked={mWed} />
                                            </Td>
                                            <Td className="week-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setMThu(!mThu)} checked={mThu} />
                                            </Td>
                                            <Td className="week-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setMFri(!mFri)} checked={mFri} />
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>Afternoon</Td>
                                            <Td className="week-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setAMon(!aMon)} checked={aMon} />
                                            </Td>
                                            <Td className="week-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setATue(!aTue)} checked={aTue} />
                                            </Td>
                                            <Td className="week-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setAWed(!aWed)} checked={aWed} />
                                            </Td>
                                            <Td className="week-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setAThu(!aThu)} checked={aThu} />
                                            </Td>
                                            <Td className="week-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setAFri(!aFri)} checked={aFri} />
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </Row>
                        </AvForm>
                    </CardBody>
                </Card>,
        },
        {
            title: "Parent's info",
            content:
                <Card className="mt-5">
                    <CardBody>
                        <AvForm className="needs-validation" >
                            <Row>
                                <Col md={6}>
                                    <p><strong>Mother/Tutor</strong></p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Label>{props.t('Name')}</Label>
                                    <AvField
                                        name="motherName"
                                        placeholder="Mother/Tutor"
                                        type="text"
                                        errorMessage="Enter Name"
                                        validate={{ required: { value: true } }}
                                        value={motherName}
                                        onChange={(e) => { setMotherName(e.currentTarget.value) }}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Email')}</Label>
                                    <AvField
                                        name="motherEmail"
                                        placeholder="Mother/Tutor E-mail"
                                        type="email"
                                        errorMessage="Invalid Email"
                                        validate={{
                                            required: { value: true },
                                            email: { value: true }
                                        }}
                                        value={motherEmail}
                                        onChange={(e) => { setMotherEmail(e.currentTarget.value) }}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Same Address Child?')}</Label>
                                    <AvField
                                        type="select"
                                        value={motherSameAddress}
                                        name="motherSameAddress"
                                        onChange={(e) => setMotherSameAddress(e.target.value)}
                                        validate={{ required: { value: true } }}>
                                        <option disabled={true}>Select</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </AvField>
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Address')}</Label>
                                    <AvField
                                        name="motherAddress"
                                        placeholder="Mother/Tutor Address"
                                        type="text"
                                        value={motherAddress}
                                        onChange={(e) => { setMotherAddress(e.currentTarget.value) }}
                                        errorMessage="Enter valid address"
                                        className="form-control"
                                        id="validationCustom03"
                                        disabled={motherSameAddress === "No" ? false : true}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Home phone number')}</Label>
                                    <InputMask
                                        mask="(999) 999-9999"
                                        value={motherHomePhone}
                                        onChange={(e) => { setMotherHomePhone(e.currentTarget.value) }}
                                        className="form-control input-color"
                                        required={true}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Cell phone number')}</Label>
                                    <InputMask
                                        name="motherCellPhone"
                                        mask="(999) 999-9999"
                                        value={motherCellPhone}
                                        onChange={(e) => { setMotherCellPhone(e.currentTarget.value) }}
                                        className="form-control input-color"
                                        required={true}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Office phone number*')}</Label>
                                    <InputMask
                                        name="motherOfficePhone"
                                        mask="(999) 999-9999"
                                        value={motherOfficePhone}
                                        onChange={(e) => { setMotherOfficePhone(e.currentTarget.value) }}
                                        className="form-control input-color"
                                        required={true}
                                    />
                                </Col>
                            </Row>
                        </AvForm>
                        <AvForm className="needs-validation mt-2" >
                            <Row>
                                <Col md={6}>
                                    <p><strong>Father/Tutor</strong></p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Label>{props.t('Name')}</Label>
                                    <AvField
                                        name="fatherName"
                                        placeholder="Father/Tutor"
                                        type="text"
                                        errorMessage="Enter Name"
                                        value={fatherName}
                                        onChange={(e) => { setFatherName(e.currentTarget.value) }}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Email')}</Label>
                                    <AvField
                                        name="FatherEmail"
                                        placeholder="Father/Tutor E-mail"
                                        type="email"
                                        errorMessage="Invalid Email"
                                        validate={{
                                            email: { value: true }
                                        }}
                                        value={fatherEmail}
                                        onChange={(e) => { setFatherEmail(e.currentTarget.value) }}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Same Address Child?')}</Label>
                                    <AvField
                                        type="select"
                                        value={fatherSameAddress}
                                        name="fatherSameAddress"
                                        onChange={(e) => setFatherSameAddress(e.target.value)}
                                    >
                                        <option disabled={true}>Select</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </AvField>
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Address')}</Label>
                                    <AvField
                                        name="fatherAddress"
                                        placeholder="Father/Tutor Address"
                                        type="text"
                                        value={fatherAddress}
                                        onChange={(e) => { setFatherAddress(e.currentTarget.value) }}
                                        errorMessage="Enter valid address"
                                        className="form-control"
                                        id="validationCustom03"
                                        disabled={fatherSameAddress === 'No' ? false : true}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Home phone number')}</Label>
                                    <InputMask
                                        name="fatherHomePhone"
                                        mask="(999) 999-9999"
                                        value={fatherHomePhone}
                                        onChange={(e) => { setFatherHomePhone(e.currentTarget.value) }}
                                        className="form-control input-color"
                                        required={false}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Cell phone number')}</Label>
                                    <InputMask
                                        name="fatherCellPhone"
                                        mask="(999) 999-9999"
                                        value={fatherCellPhone}
                                        onChange={(e) => { setFatherCellPhone(e.currentTarget.value) }}
                                        className="form-control input-color"
                                        required={false}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Office phone number*')}</Label>
                                    <InputMask
                                        name="fatherOfficePhone"
                                        mask="(999) 999-9999"
                                        value={fatherOfficePhone}
                                        onChange={(e) => { setFatherOfficePhone(e.currentTarget.value) }}
                                        className="form-control input-color"
                                        required={false}
                                    />
                                </Col>
                            </Row>
                        </AvForm>
                    </CardBody>
                </Card>,
        },
        {
            title: 'Pickup Child',
            content: <Card className="mt-5">
                <CardBody>
                    <AvForm className="needs-validation" >
                        <Row>
                            <Col md={12}>
                                <p><strong>Persons authorized to pickup the child</strong></p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Label>{props.t('Name')}</Label>
                                <AvField
                                    name="pickerName"
                                    placeholder="name"
                                    type="text"
                                    errorMessage="Enter Name"
                                    validate={{ required: { value: true } }}
                                    value={pickerName}
                                    onChange={(e) => { setPickerName(e.currentTarget.value) }}
                                />
                            </Col>
                            <Col md={4}>
                                <Label>{props.t('Link with child')}</Label>
                                <AvField
                                    name="linkchild"
                                    placeholder="Link with child"
                                    type="text"
                                    value={linkChild}
                                    onChange={(e) => { setLinkChild(e.currentTarget.value) }}
                                    errorMessage="Enter valid value"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom03"
                                />
                            </Col>
                            <Col md={4}>
                                <Label>{props.t('Phone Number')}</Label>
                                <InputMask
                                    mask="(999) 999-9999"
                                    value={pickerPhone}
                                    onChange={(e) => setPickerPhone(e.target.value)}
                                    className="form-control input-color"
                                />
                            </Col>
                            <Col md={4}>
                                <Label>{props.t('Address')}</Label>
                                <AvField
                                    name="pickerAddress"
                                    placeholder="address"
                                    type="text"
                                    value={pickerAddress}
                                    onChange={(e) => { setPickerAddress(e.currentTarget.value) }}
                                    errorMessage="Enter valid address"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom03"
                                />
                            </Col>

                        </Row>
                    </AvForm>
                </CardBody>
            </Card>,
        },
        {
            title: 'Case of urgency',
            content: <Card className="mt-5">
                <CardBody>
                    <AvForm className="needs-validation" >
                        <Row>
                            <Col md={12}>
                                <p><strong>{props.t('Contact in case of urgency (other than parents)')}</strong></p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Label>{props.t('Name')}</Label>
                                <AvField
                                    name="urgencyName"
                                    placeholder="name"
                                    type="text"
                                    errorMessage="Enter Name"
                                    validate={{ required: { value: true } }}
                                    value={urgencyName}
                                    onChange={(e) => { setUrgencyName(e.currentTarget.value) }}
                                />
                            </Col>
                            <Col md={4}>
                                <Label>{props.t('Link with child')}</Label>
                                <AvField
                                    name="urgencylinkchild"
                                    placeholder="Link with child"
                                    type="text"
                                    value={urgencyLinkChild}
                                    onChange={(e) => setUrgencyLinkChild(e.target.value)}
                                    errorMessage="Enter valid value"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom03"
                                />
                            </Col>
                            <Col md={4}>
                                <Label>{props.t('Phone Number')}</Label>
                                <InputMask
                                    mask="(999) 999-9999"
                                    value={urgencyPhone}
                                    onChange={(e) => { setUrgencyPhone(e.currentTarget.value) }}
                                    className="form-control input-color"
                                />
                            </Col>
                            <Col md={4}>
                                <Label>{props.t('Address')}</Label>
                                <AvField
                                    name="urgencyAddress"
                                    placeholder="address"
                                    type="text"
                                    value={urgencyAddress}
                                    onChange={(e) => { setUrgencyAddress(e.currentTarget.value) }}
                                    errorMessage="Enter valid address"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom03"
                                />
                            </Col>

                        </Row>
                    </AvForm>
                </CardBody>
            </Card>,
        },
        {
            title: 'Health Care',
            content:
                <Card className="mt-5">
                    <CardBody>
                        <AvForm className="needs-validation" >
                            <Row>
                                <Col md={12}>
                                    <p><strong>{props.t('Child health information')}</strong></p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>{props.t('SIN')}</Label>
                                        <InputMask
                                            mask="999.999.999"
                                            value={healthSin}
                                            onChange={(e) => setHealthSin(e.target.value)}
                                            className="form-control input-color"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>{props.t('Exp Date')}</Label>
                                        <AvField
                                            name="exp"
                                            type="date"
                                            placeholder='Exp Date'
                                            className="form-control"
                                            id="validationCustom04"
                                            value={healthExp}
                                            onChange={(e) => setHealthExp(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    Are there any health problems that require special care?
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        type="select"
                                        value={specialCare}
                                        name="fatherSameAddress"
                                        onChange={(e) => setSpecialCare(e.target.value)}
                                        validate={{ required: { value: true } }}>
                                        <option disabled={true}>Special Care?</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </AvField>
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        name="specialCareDesc"
                                        placeholder="Special care detail"
                                        type="text"
                                        value={specialCareDesc}
                                        onChange={(e) => { setSpecialCareDesc(e.currentTarget.value) }}
                                        className="form-control"
                                        disabled={specialCare === "Yes" ? false : true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    {props.t('Does he/she take medication on a regular basis?')}
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        type="select"
                                        value={medication}
                                        name="medication"
                                        onChange={(e) => setMedication(e.target.value)}
                                        validate={{ required: { value: true } }}>
                                        <option disabled={true}>Medication</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </AvField>
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        name="specialCareDesc"
                                        placeholder="Medication"
                                        type="text"
                                        value={medicationDesc}
                                        onChange={(e) => { setMedicationDesc(e.currentTarget.value) }}
                                        className="form-control"
                                        disabled={medication === "Yes" ? false : true}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    {props.t('Is there any possible side effects for these medication?')}
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        type="select"
                                        value={sideEffects}
                                        name="sideEffect"
                                        onChange={(e) => setSideEffects(e.target.value)}
                                        validate={{ required: { value: true } }}>
                                        <option disabled={true}>Side Effects</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </AvField>
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        name="specialCareDesc"
                                        placeholder="Medication"
                                        type="text"
                                        value={sideEffectsDesc}
                                        onChange={(e) => { setSideEffectsDesc(e.currentTarget.value) }}
                                        className="form-control"
                                        disabled={sideEffects === "Yes" ? false : true}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    {props.t('Has the child received all vaccines?')}
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        type="select"
                                        value={vaccine}
                                        name="vaccine"
                                        onChange={(e) => setVaccine(e.target.value)}
                                        validate={{ required: { value: true } }}>
                                        <option disabled={true}>Vaccines</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </AvField>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    {props.t('Is the vaccination up to date?')}
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        type="select"
                                        value={vaccineUpToDate}
                                        name="vaccine"
                                        onChange={(e) => setVaccineUpToDate(e.target.value)}
                                        validate={{ required: { value: true } }}>
                                        <option disabled={true}>Is the vaccination up to date?</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </AvField>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    {props.t('Does your child have allergies(medication or food)?')}
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        type="select"
                                        value={allergies}
                                        name="allergies"
                                        onChange={(e) => setAllergies(e.target.value)}
                                        validate={{ required: { value: true } }}>
                                        <option disabled={true}>Allergies</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </AvField>
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        name="specialCareDesc"
                                        placeholder="If yes, all prescription must be provided"
                                        type="text"
                                        value={allergyDesc}
                                        onChange={(e) => { setAllergyDesc(e.currentTarget.value) }}
                                        className="form-control"
                                        disabled={allergies === "Yes" ? false : true}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    {props.t('Are there provisions to be taken for his' +
                                        'health according to the disease and / or allergies' +
                                        'mentioned above in case of emergency?')}
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        type="select"
                                        value={provision}
                                        name="provision"
                                        onChange={(e) => setProvision(e.target.value)}
                                        validate={{ required: { value: true } }}>
                                        <option disabled={true}>Health prov</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </AvField>
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        name="provisionDesc"
                                        placeholder="If yes, what?"
                                        type="text"
                                        value={provisionDesc}
                                        onChange={(e) => { setProvisionDesc(e.currentTarget.value) }}
                                        className="form-control"
                                        disabled={provision === "Yes" ? false : true}
                                    />
                                </Col>
                            </Row>

                            <Row className='mt-2'>
                                <Col md={4}>
                                    {props.t('Name of Pediatrician And / or family doctor')}
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        name="doctorName"
                                        placeholder="Name"
                                        type="text"
                                        errorMessage="Enter Name"
                                        validate={{ required: { value: true } }}
                                        value={doctorName}
                                        onChange={(e) => { setDoctorName(e.currentTarget.value) }}
                                    />
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        name="doctorAddress"
                                        placeholder="Address"
                                        type="text"
                                        value={doctorAddress}
                                        onChange={(e) => { setDoctorAddress(e.currentTarget.value) }}
                                        errorMessage="Enter valid address"
                                        className="form-control"
                                        validate={{ required: { value: true } }}
                                        id="validationCustom03"
                                    />
                                </Col>
                                <Col md={{ size: 4, offset: 4 }}>
                                    <Label>{props.t('Phone')}</Label>
                                    <InputMask
                                        mask="(999) 999-9999"
                                        value={doctorPhone}
                                        onChange={(e) => setDoctorPhone(e.target.value)}
                                        className="form-control input-color"
                                    />
                                </Col>
                            </Row>

                            <Row className='mt-2'>
                                <Col md={4}>
                                    {props.t('Authorized Drugs. This is required by signing MFA forms')}
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        name="drugSign"
                                        placeholder="Tylenol, Sunscreen, insect repellent"
                                        type="text"
                                        errorMessage="Enter valid value"
                                        validate={{ required: { value: true } }}
                                        value={drugSign}
                                        onChange={(e) => { setDrugSign(e.currentTarget.value) }}
                                    />
                                </Col>
                            </Row>

                        </AvForm>
                    </CardBody>
                </Card>,
        },
        {
            title: 'Authorizations',
            content:
                <Card className="mt-5">
                    <CardBody>
                        <AvForm className="needs-validation" >
                            <Row>
                                <Col md={12}>
                                    <p><strong>{props.t('Authorizations')}</strong></p>
                                </Col>
                                <Col md={12}>
                                    {props.t('I authorize my child to participate on outdoors activities organized by Maplebeez' +
                                        'de Beauport school. (educational or sports activities)')}
                                </Col>
                                <br />
                                <Col md={6} className="form-check mt-1" style={{ padding: '32px' }}>
                                    <input className="form-check-input auth-check" type="checkbox" value="" id="defaultCheck1" onChange={() => setFirstPSign(!firstPSign)} checked={firstPSign} />
                                    <label className="form-check-label auth-label" htmlFor="defaultCheck1">
                                        Parent's Authorization
                                    </label>
                                </Col>

                                <Col md={6}>
                                    <FormGroup style={{ marginTop: '32px' }}>
                                        <Label>{props.t('Authorization Date')}</Label>
                                        <AvField
                                            name="authoriaztionDate"
                                            type="date"
                                            className="form-control"
                                            id="validationCustom04"
                                            value={firstSDate}
                                            onChange={(e) => setFirstSDate(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <br />
                                <p style={{ padding: '12px' }}>
                                    I authorize the Maplebeez de Beauport team to take the necessary measures in case of urgency concerning the security and healthy of my child.
                                </p>
                                <p style={{ padding: '12px' }}>
                                    Is there any particular or concerns we should know in case of urgency? (hospital, ambulance, first aid). If yes, please describe.
                                </p>
                                <Col md={12}>
                                    <Input
                                        type="textarea"
                                        id="textarea"
                                        value={signDesc}
                                        onChange={(e) => { setSignDesc(e.target.value) }}
                                        maxLength="225"
                                        rows="3"
                                        placeholder="+info"
                                    />
                                </Col>
                                <br />
                                <Col md={6} className="form-check mt-1" style={{ padding: '32px' }}>
                                    <input className="form-check-input auth-check" type="checkbox" value="" id="defaultCheck1" onChange={() => setSecondPSign(!secondPSign)} checked={secondPSign} />
                                    <label className="form-check-label auth-label" htmlFor="defaultCheck1">
                                        Parent's Authorization
                                    </label>
                                </Col>

                                <Col md={6}>
                                    <FormGroup style={{ marginTop: '32px' }}>
                                        <Label>{props.t('Authorization Date')}</Label>
                                        <AvField
                                            name="authoriaztionDate"
                                            type="date"
                                            className="form-control"
                                            id="validationCustom04"
                                            value={secondSDate}
                                            onChange={(e) => setSecondSDate(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </AvForm>
                    </CardBody>
                </Card>,
        },
    ];

    const next = () => {
        validate(stepCurrent)
    }

    const prev = () => {
        const current = stepCurrent - 1;
        setStepCurrent(current)
    }

    const changeFile = async (event) => {
        let image = document.getElementById('uploadedFile');
        image.src = URL.createObjectURL(event.target.files[0]);
        await firebaseUpload(event.target.files[0])
    }

    const handleDocUpload = async (event) => {
        let doc = document.getElementById('medicalDocs')
        doc.insertAdjacentHTML('beforeend', `<p class='mb-0'>${event.target.files[0].name}</p>`)
        await firebaseDocUpload(event.target.files[0])
    }

    const handleUploadingMat = (e) => {
        const file = e.target.files[0];
        props.uploadDoc({id: props.student.id, type: uploadingType, file})
    }

    const firebaseDocUpload = async (fileInfo) => {
        let fileName = fileInfo.name
        let filePath = "medicalDocs/" + fileName
        await firebase
            .storage()
            .ref(filePath)
            .put(fileInfo)
            .then(async (fileSnapshot) => {
                await fileSnapshot.ref
                    .getDownloadURL()
                    .then(async (url) => {
                        if (!url) {
                            toastr.error("Upload Failed. Please try again");
                            return false;
                        }
                        const child_id = props.match.params.id
                        const headers = JSON.parse(localStorage.getItem("authHeaders"))
                        const config = {
                            method: "post",
                            url: `/api/v1/medical_docs`,
                            headers: {
                                accesstoken: headers.accesstoken,
                                client: headers.client,
                                uid: headers.uid,
                            },
                            data: { docs: { url, child_id, fileName } }
                        }
                        await axios(config).then(async response => {
                            if (response.status === 400 || response.status === 500)
                                throw response.data;
                            if (response.status === 200) {
                                toastr.success(response.data.message, 'Success')
                                // props.getAStudent(child_id);
                            }
                        }).catch(err => {
                            toastr.error("Failed to upload document!", "Error")
                        });
                    })
                    .catch((e) => toastr.error(e.message));
            })
            .catch((e) => toastr.error(e.message));
    }


    const selectAll = () => {
        setAllChecked(!allChecked)
        if (!allChecked) {
            Selected(true)
        } else {
            Selected(false)
        }
    }

    const Selected = (params) => {
        //step 2 states
        setMMon(params)
        setMTue(params)
        setMWed(params)
        setMThu(params)
        setMFri(params)

        setAMon(params)
        setATue(params)
        setAWed(params)
        setAThu(params)
        setAFri(params)
    }

    const firebaseUpload = async (fileInfo) => {
        let filePath = "images/children/" + fileInfo.name
        await firebase
            .storage()
            .ref(filePath)
            .put(fileInfo)
            .then(async (fileSnapshot) => {
                await fileSnapshot.ref
                    .getDownloadURL()
                    .then(async (url) => {
                        if (!url) {
                            toastr.error("Upload Failed. Please try again");
                            return false;
                        }
                        const id = props.match.params.id
                        const headers = JSON.parse(localStorage.getItem("authHeaders"))
                        const config = {
                            method: "put",
                            url: `/api/v1/students/${id}`,
                            headers: {
                                accesstoken: headers.accesstoken,
                                client: headers.client,
                                uid: headers.uid,
                            },
                            data: { image_url: url }
                        }
                        await axios(config).then(async response => {
                            if (response.status === 400 || response.status === 500)
                                throw response.data;
                            if (response.status === 200) {
                                toastr.success(response.data.message, 'Success')
                                // props.getAStudent(id);
                            }
                        }).catch(err => {
                            toastr.error("Failed to upload image!", "Error")
                        });
                    })
                    .catch((e) => toastr.error(e.message));
            })
            .catch((e) => toastr.error(e.message));
    }

    const student = props.student

    const teachers = () => {
        if (props.teachers) {
            return props.teachers.map((user, index) => {
                return (
                    <span key={index}>
                        {(index ? ', ' : '') + user.name}
                    </span>
                )
            })
        }
    }

    const handleDelDoc = (item) => {
        setDelData(item)
        setconfirm_both(true)
    }

    const docs = () => {
        if (props.docs) {
            return props.docs.map((doc, index) => {
                return (
                    <p key={index} className="mb-0">
                        <a href={doc.url} key={index}>{doc.title}</a>
                        <span>
                            <i
                                className="fas fa-trash-alt actions"
                                style={{ cursor: "pointer", fontSize: "12px" }}
                                onClick={() => handleDelDoc(doc)}
                            ></i>
                        </span>
                    </p>
                )
            })
        }
    }

    const handleGuardChange = async (date, dateString) => {
        console.log(date, dateString);
        if(dateString) {
            const headers = JSON.parse(localStorage.getItem("authHeaders"))
            const config = {
                method: "post",
                url: `/api/v1/students/add_guard_service`,
                headers: {
                    accesstoken: headers.accesstoken,
                    client: headers.client,
                    uid: headers.uid,
                },
                data: { id:  props.match.params.id, started_at: dateString}
            }
            await axios(config).then(async response => {
                if (response.status === 400 || response.status === 500)
                    throw response.data;
                if (response.status === 200) {
                    toastr.success(response.data.message, 'Success');
                }
            }).catch(err => {
                toastr.error("Something went wrong!", "Error")
            });
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('Student Profile')} breadcrumbItem={props.t('Students')} />

                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    {localStorage.getItem("authUser") && JSON.parse(localStorage.getItem("authUser")).data.role === "Teacher" && (
                                        <Button
                                            variant="primary"
                                            className="btn btn-info"
                                            style={{ fontSize: '15px' }}
                                            onClick={handlePreUpdate}
                                        >
                                            {props.t('Edit Profile')}
                                        </Button>
                                    )}
                                    {student && (
                                        <Row className='mt-3'>
                                            <Col xs={12} sm={4}>
                                                <h5>
                                                    <strong>
                                                        {props.t("Child Info")} <i className="fas fa-address-card"></i>
                                                    </strong>
                                                </h5>
                                                <p className='mb-1'><strong>{props.t('Name')}:</strong> {student && student.firstName} {student && student.lastName}</p>
                                                {props.teachers && (
                                                    <p className='mb-1'><strong>{props.t("Teacher's name")}:</strong> {teachers()}</p>
                                                )}
                                                <p className='mb-1'><strong>{props.t('Address')}: </strong> {student && student.address}</p>
                                                <h5 className='mt-3'>
                                                    <strong>
                                                        {props.t('Health')} <i className="fas fa-medkit"></i>
                                                    </strong>
                                                </h5>
                                                <p className='mb-2'>
                                                    <strong>
                                                        {props.t('Allergies')}
                                                    </strong>
                                                </p>
                                                <p className='mb-1'>
                                                    {student.allergyDesc ? student.allergyDesc : ""}
                                                </p>
                                                <div className='mb-1' id="medicalDocs">
                                                    {docs()}
                                                </div>
                                                <p>
                                                    <input type="file" accept="*" name="doc" id="doc"
                                                        onChange={handleDocUpload} style={{ display: "none" }} />
                                                </p>
                                                {localStorage.getItem("authUser") && JSON.parse(localStorage.getItem("authUser")).data.role === "Teacher" && (
                                                    <p className='text-left'>
                                                        <label htmlFor="doc" style={{ cursor: "pointer", color: "blue" }}>Upload medical document</label>
                                                    </p>
                                                )}
                                                <p className='mb-2'>
                                                    <strong>
                                                        {props.t('Authorized drugs')}
                                                    </strong>
                                                </p>
                                                <p className='mb-1'>
                                                    {student.drugSign ? student.drugSign : ""}
                                                </p>
                                                <h5 className='mt-3'>
                                                    <strong>
                                                        {props.t('Documents')} <i className="fas fa-file-alt"></i>
                                                    </strong>
                                                </h5>
                                                <div className='mb-1'>
                                                    <div className="float-left width-80" style={{marginTop: '5px'}}>
                                                        Bank direct debit / Prélèvement bancaire
                                                    </div>
                                                    <div className="width-20 float-left pr-1">
                                                        <UncontrolledDropdown>
                                                          <DropdownToggle
                                                              href="#"
                                                              className="card-drop"
                                                              tag="i"
                                                          >
                                                            <i className="mdi mdi-dots-horizontal font-size-20 font-weight-bold" />
                                                          </DropdownToggle>
                                                          <DropdownMenu className="dropdown-menu-end">
                                                              {props.student.bank_balance_url && (
                                                                <DropdownItem
                                                                    href={props.student.bank_balance_url}
                                                                    download
                                                                >
                                                                  <i className="fas fa-cloud-download-alt font-size-14 me-1" />{" "}
                                                                    Download
                                                                </DropdownItem>
                                                              )}
                                                              <DropdownItem
                                                                  onClick={() => {
                                                                      setConfirmUpload(true);
                                                                      setUploadingType(1);
                                                                  }}
                                                              >
                                                                  <i className="fas fa-cloud-upload-alt font-size-14 me-1" />{" "}
                                                                  Upload
                                                              </DropdownItem>
                                                              {props.student.bank_balance_url && (
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        setConfirmDelete(true);
                                                                        setUploadingType(1);
                                                                    }}
                                                                >
                                                                  <i className="fas fa-trash-alt font-size-16 me-1" />{" "}
                                                                    &nbsp;Delete
                                                                </DropdownItem>
                                                              )}
                                                          </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </div>
                                                </div>
                                                <div className='mb-1'>
                                                    <div className="float-left width-80" style={{marginTop: '5px'}}>
                                                        Certificat de naissance / Birth certificate
                                                    </div>
                                                    <input
                                                        type="file"
                                                        accept="*"
                                                        name="certDoc"
                                                        id="certDoc"
                                                        ref={uploadRef}
                                                        onChange={handleUploadingMat}
                                                        style={{ display: "none" }}
                                                    />
                                                    <div className="width-20 float-left pr-1">
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle
                                                                href="#"
                                                                className="card-drop"
                                                                tag="i"
                                                            >
                                                                <i className="mdi mdi-dots-horizontal font-size-20 font-weight-bold" />
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-end">
                                                                {props.student.birth_cert_url && (
                                                                    <DropdownItem
                                                                        href={props.student.birth_cert_url}
                                                                        download
                                                                    >
                                                                        <i className="fas fa-cloud-download-alt font-size-14 me-1" />{" "}
                                                                        Download
                                                                    </DropdownItem>
                                                                )}
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        setConfirmUpload(true);
                                                                        setUploadingType(2);
                                                                    }}
                                                                >
                                                                    <i className="fas fa-cloud-upload-alt font-size-14 me-1" />{" "}
                                                                    Upload
                                                                </DropdownItem>
                                                                {props.student.birth_cert_url && (
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        setConfirmDelete(true);
                                                                        setUploadingType(2);
                                                                        }
                                                                    }
                                                                >
                                                                    <i className="fas fa-trash-alt font-size-16 me-1" />{" "}
                                                                    &nbsp;Delete
                                                                </DropdownItem>
                                                                )}
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </div>
                                                </div>
                                                <div className='float-left'>
                                                    <h5 className='mt-3'>
                                                        <strong>
                                                            {props.t("Admission SG")}
                                                        </strong>
                                                    </h5>
                                                    <div className='mt-1'>
                                                        <DatePicker defaultValue={
                                                            props.student.guard_service_start_date ? 
                                                            moment(props.student.guard_service_start_date, "YYYY-MM-DD") :
                                                            ""
                                                            }
                                                            size="middle" onChange={handleGuardChange} />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={4}>
                                                <h5 className='mt-0'>
                                                    <strong>
                                                        {props.t('Contacts')}
                                                    </strong>
                                                </h5>
                                                <p className='mb-1'>
                                                    <strong>{props.t("Mother/Tutor's Name")}:</strong> {student.motherName ? student.motherName : ""}
                                                </p>
                                                <p className='mb-1'>
                                                    <i className="fas fa-envelope"></i>&nbsp;{student.motherEmail ? student.motherEmail : ""}
                                                </p>
                                                {student.motherHomePhone !== '(999) 999-9999' && (
                                                    <p className='mb-1'>
                                                        <i className="fas fa-home"></i>&nbsp;{student.motherHomePhone}
                                                        (Home)
                                                    </p>
                                                )}
                                                {student.motherCellPhone !== '(999) 999-9999' && (
                                                    <p className='mb-1'>
                                                        <i className="fas fa-mobile-alt"></i> &nbsp;{student.motherCellPhone}
                                                        (Mobile)
                                                    </p>
                                                )}
                                                {student.motherOfficePhone !== '(999) 999-9999' && (
                                                    <p className='mb-1'>
                                                        <i className="fas fa-phone-alt"></i>&nbsp;{student.motherOfficePhone}
                                                        (Office)
                                                    </p>
                                                )}

                                                <p className='mt-3 mb-1'>
                                                    <strong>{props.t("Father/Tutor's Name")}:</strong> {student.fatherName ? student.fatherName : ""}
                                                </p>
                                                <p className='mb-1'>
                                                    <i className="fas fa-envelope"></i>&nbsp;{student.fatherEmail ? student.fatherEmail : ""}
                                                </p>

                                                {student.fatherHomePhone !== '(999) 999-9999' && (
                                                    <p className='mb-1'>
                                                        <i className="fas fa-home"></i>&nbsp;{student.fatherHomePhone ? student.fatherHomePhone : ""}
                                                        (Home)
                                                    </p>
                                                )}
                                                {student.fatherCellPhone !== '(999) 999-9999' && (
                                                    <p className='mb-1'>
                                                        <i className="fas fa-mobile-alt"></i> &nbsp;{student.fatherCellPhone ? student.fatherCellPhone : ""}
                                                        (Mobile)
                                                    </p>
                                                )}
                                                {student.fatherOfficePhone !== '(999) 999-9999' && (
                                                    <p className='mb-1'>
                                                        <i className="fas fa-phone-alt"></i>&nbsp;{student.fatherOfficePhone ? student.fatherOfficePhone : ""}
                                                        (Office)
                                                    </p>
                                                )}
                                                <h5 className='mt-3'>
                                                    <strong>
                                                        {props.t("Emergency")}
                                                    </strong>
                                                </h5>
                                                <p className='mb-1'>
                                                    {props.t("Name")}: {student.urgencyName ? student.urgencyName : ""}
                                                </p>
                                                <p className='mb-1'>
                                                    <i className="fas fa-mobile-alt"></i>&nbsp;{student.urgencyPhone ? student.urgencyPhone : ""}
                                                </p>
                                                <p className='mb-1'>
                                                    <i className="fas fa-search-location"></i>&nbsp;{student.urgencyAddress ? student.urgencyAddress : ""}
                                                </p>

                                                <h5 className='mt-3'>
                                                    <strong>
                                                        {props.t("Pediatrician")}
                                                    </strong>
                                                </h5>
                                                <p className='mb-1'>
                                                    <strong>{props.t("Name")}:</strong> {student.doctorName ? student.doctorName : ""}
                                                </p>
                                                <p className='mb-1'>
                                                    <i className="fas fa-mobile-alt"></i>&nbsp;{student.doctorPhone ? student.doctorPhone : ""}
                                                </p>
                                                <p className='mb-1'>
                                                    <i className="fas fa-search-location"></i>&nbsp;{student.doctorAddress ? student.doctorAddress : ""}
                                                </p>

                                                {/* <h5 className='mt-3'>
                                                    <strong>
                                                        {props.t("Reports")}
                                                    </strong>
                                                </h5>
                                                <p className='mb-1'>
                                                    <a href="#">Attendance</a>
                                                </p>
                                                <p className='mb-1'>
                                                    <a href="#">Assesments</a>
                                                </p> */}

                                            </Col>
                                            <Col xs={12} sm={4}>
                                                <div className='avatar-wrap'>
                                                    <img id="uploadedFile" className='avatar-size' src={student.image_url ? student.image_url : Avatar} alt="avatar" />
                                                </div>
                                                <p>
                                                    <input type="file" accept="image/*" name="image" id="file"
                                                        onChange={changeFile} style={{ display: "none" }} />
                                                </p>
                                                {student && (
                                                    <>
                                                        <p className='text-center mb-0'>
                                                            <i className="fas fa-gift"></i> &nbsp;
                                                        {student.birthday && (student.birthday).substring(0, 10)}
                                                        </p>
                                                        <p className='text-center mb-0'>
                                                            {student.birthday && (moment().format('YYYY') - moment(student.birthday).format('YYYY')).toString()}
                                                        &nbsp;years old
                                                    </p>
                                                    </>
                                                )}
                                                {localStorage.getItem("authUser") && JSON.parse(localStorage.getItem("authUser")).data.role === "Teacher" && (
                                                    <p className='text-center'>
                                                        <label htmlFor="file" style={{ cursor: "pointer" }}>Click here to upload a photo or update</label>
                                                    </p>
                                                )}
                                            </Col>
                                        </Row>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal isOpen={modal} size='xl' role="dialog" autoFocus={true} centered={true} backdrop={"static"} className="exampleModal" tabIndex="-1" toggle={() => { setmodal(!modal); setStepCurrent(0); clearStates(); }}>
                        <div className="modal-content">
                            <ModalHeader toggle={() => { setmodal(!modal); setStepCurrent(0); clearStates(); }}>
                                {props.t('Add new child')}
                            </ModalHeader >
                            <ModalBody>
                                <Steps current={stepCurrent}>
                                    {steps.map(item => (
                                        <Step key={item.title} title={item.title} />
                                    ))}
                                </Steps>
                                <div className="steps-content">{steps[stepCurrent].content}</div>
                                <div className="steps-action" style={{ marginLeft: "1.25rem" }}>
                                    {stepCurrent > 0 && (
                                        <Button
                                            color="success"
                                            className="btn btn-success waves-effect waves-light"
                                            onClick={prev}
                                            style={{ marginRight: "10px" }}
                                        >
                                            Previous
                                        </Button>
                                    )}
                                    {stepCurrent < steps.length - 1 && (
                                        <Button
                                            color="success"
                                            className="btn btn-success waves-effect waves-light"
                                            onClick={next}

                                        >
                                            Next
                                        </Button>
                                    )}
                                    {stepCurrent === steps.length - 1 && isCreate && (
                                        <Button
                                            color="success"
                                            className="btn btn-success waves-effect waves-light"
                                            style={{ marginRight: "10px" }}
                                            disabled={props.loading}
                                            onClick={handleSave}
                                        >
                                            {props.loading ? (
                                                <React.Fragment>
                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                    Saving
                                                </React.Fragment>
                                            ) : "Save"}
                                        </Button>
                                    )}
                                    {stepCurrent === steps.length - 1 && !isCreate && (
                                        <Button
                                            color="success"
                                            className="btn btn-success waves-effect waves-light"
                                            style={{ marginRight: "10px" }}
                                            disabled={props.loading}
                                            onClick={handleUpdate}
                                        >
                                            {props.loading ? (
                                                <React.Fragment>
                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                    Updating
                                                </React.Fragment>
                                            ) : "Update"}
                                        </Button>
                                    )}

                                </div>
                            </ModalBody>
                        </div>
                    </Modal>

                    {confirm_both ? (
                        <SweetAlert
                            title="Are you sure?"
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => {
                                setconfirm_both(false);
                                postDelete()

                            }
                            }
                            onCancel={() => {
                                setconfirm_both(false);
                                setsuccess_dlg(false);
                                setdynamic_title("Cancelled");
                                setdynamic_description("Your imaginary file is safe :)");
                            }
                            }
                        >
                            You won't be able to revert this!
                        </SweetAlert>
                    ) : null}

                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={dynamic_title}
                            onConfirm={() => {
                                const id = props.match.params.id
                                props.getAStudent(id)
                                setsuccess_dlg(false);
                                setDelData(null);
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {error_dlg ? (
                        <SweetAlert
                            error
                            title={dynamic_title}
                            onConfirm={() => {
                                seterror_dlg(false);
                                setDelData(null);
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {confirmUpload ? (
                        <SweetAlert
                            title="Are you sure?"
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => {
                                    setConfirmUpload(false);
                                    uploadRef.current.click();
                                }
                            }
                            onCancel={() => {
                                    setConfirmUpload(false);
                                }
                            }
                        >
                            If y ou upload another document, it will replace the one you already have here
                        </SweetAlert>
                    ) : null}

                    {confirmDelete ? (
                        <SweetAlert
                            title="Are you sure?"
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => {
                                    setConfirmDelete(false);
                                    props.deleteDoc({id: props.student.id, type: uploadingType});
                                }
                            }
                            onCancel={() => {
                                    setConfirmDelete(false);
                                }
                            }
                        >
                            You won't be able to revert this!
                        </SweetAlert>
                    ) : null}

                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { loading, error, isModal, student, teachers, docs } = state.StudentDetailReducer;
    return { loading, error, isModal, student, teachers, docs };
}

export default withRouter(connect(mapStatetoProps, { getAStudent, updateAStudent, uploadDoc, deleteDoc })(withNamespaces()(Student)));
