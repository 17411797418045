import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Pagination, Checkbox, Tooltip, Steps } from 'antd';
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { connect } from 'react-redux';

// Form Mask
import InputMask from "react-input-mask";

import { createStudent, getAllStudents, updateStudent } from '../../store/actions';
import { addNewUser } from '../../apiHelpers/userApiHelper';
import Select from "react-select";
//i18n
import { withNamespaces } from 'react-i18next';
import { AvForm, AvField } from "availity-reactstrap-validation";
import toastr from "toastr";
import axios from "axios";
const { Step } = Steps;




const Students = (props) => {

    const [modal, setmodal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [current, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20)

    const [modalName, setName] = useState('')
    const [modalEmail, setEmail] = useState('')
    const [modalRole, setRole] = useState('')
    const [id, setId] = useState('cool')
    const [index, setIndex] = useState('index')

    const [stepCurrent, setStepCurrent] = useState(0)

    const [isCreate, setIsCreate] = useState(null)
    //sweet alert settings
    const [confirm_both, setconfirm_both] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [error_dlg, seterror_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [delData, setDelData] = useState(null)

    const [mSave, setMSave] = useState(false)
    const [fSave, setFSave] = useState(false)
    const [mEmailIni, setMEmailIni] = useState(false)
    const [fEmailIni, setFEmailIni] = useState(false)


    const [allChecked, setAllChecked] = useState(false)
    //step 1 states
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [address, setAddress] = useState('')
    const [birthday, setBirthday] = useState('')
    const [gender, setGender] = useState('Gender')
    const [selectlanguages, setSelectlanguages] = useState(null);

    //step 2 states
    const [mMon, setMMon] = useState(false)
    const [mTue, setMTue] = useState(false)
    const [mWed, setMWed] = useState(false)
    const [mThu, setMThu] = useState(false)
    const [mFri, setMFri] = useState(false)

    const [aMon, setAMon] = useState(false)
    const [aTue, setATue] = useState(false)
    const [aWed, setAWed] = useState(false)
    const [aThu, setAThu] = useState(false)
    const [aFri, setAFri] = useState(false)

    //step 3 states
    //* Mother info
    const [motherName, setMotherName] = useState('')
    const [motherEmail, setMotherEmail] = useState('')
    const [motherAddress, setMotherAddress] = useState('')
    const [motherSameAddress, setMotherSameAddress] = useState('Select')
    const [motherHomePhone, setMotherHomePhone] = useState("(999) 999-9999")
    const [motherCellPhone, setMotherCellPhone] = useState("(999) 999-9999")
    const [motherOfficePhone, setMotherOfficePhone] = useState("(999) 999-9999")
    //* Father info
    const [fatherName, setFatherName] = useState('')
    const [fatherEmail, setFatherEmail] = useState('')
    const [fatherSameAddress, setFatherSameAddress] = useState('Select')
    const [fatherAddress, setFatherAddress] = useState('')
    const [fatherHomePhone, setFatherHomePhone] = useState("(999) 999-9999")
    const [fatherCellPhone, setFatherCellPhone] = useState("(999) 999-9999")
    const [fatherOfficePhone, setFatherOfficePhone] = useState("(999) 999-9999")
    // Step 4 states
    const [pickerName, setPickerName] = useState('')
    const [pickerPhone, setPickerPhone] = useState("(999) 999-9999")
    const [linkChild, setLinkChild] = useState('')
    const [pickerAddress, setPickerAddress] = useState('')
    //step 5 states
    const [urgencyName, setUrgencyName] = useState('')
    const [urgencyPhone, setUrgencyPhone] = useState("(999) 999-9999")
    const [urgencyLinkChild, setUrgencyLinkChild] = useState('')
    const [urgencyAddress, setUrgencyAddress] = useState('')
    //step 6 states
    const [healthSin, sethealthSin] = useState('')
    const [healthExp, setHealthExp] = useState(null)
    const [specialCare, setSpecialCare] = useState('Special Care?')
    const [specialCareDesc, setSpecialCareDesc] = useState('')
    const [medication, setMedication] = useState('Medication')
    const [medicationDesc, setMedicationDesc] = useState('')
    const [sideEffects, setSideEffects] = useState('Side Effects')
    const [sideEffectsDesc, setSideEffectsDesc] = useState('')
    const [vaccine, setVaccine] = useState('Vaccines')
    const [vaccineUpToDate, setVaccineUpToDate] = useState('Is the vaccination up to date?')
    const [allergies, setAllergies] = useState('Allergies')
    const [allergyDesc, setAllergyDesc] = useState('')
    const [provision, setProvision] = useState('Health prov')
    const [provisionDesc, setProvisionDesc] = useState('')
    const [doctorName, setDoctorName] = useState('')
    const [doctorAddress, setDoctorAddress] = useState('')
    const [doctorPhone, setDoctorPhone] = useState('(999) 999-9999')
    const [drugSign, setDrugSign] = useState('')
    //step 7 states
    const [firstPSign, setFirstPSign] = useState(false)
    const [firstSDate, setFirstSDate] = useState(null)
    const [signDesc, setSignDesc] = useState('')
    const [secondPSign, setSecondPSign] = useState(false)
    const [secondSDate, setSecondSDate] = useState(null)



    const optionGroup = [
        { label: "English", value: "English" },
        { label: "French", value: "French" },
        { label: "Spanish", value: "Spanish" },
        { label: "Portuguese", value: "Portuguese" },
        { label: "Arabic", value: "Arabic" }
    ];

    useEffect(() => {
        props.getAllStudents({ page: current, pageSize: pageSize })
    }, [])

    useEffect(() => {
        if (props.modal === "success" && isCreate) {
            clearStates()
            setmodal(false)
            setStepCurrent(0)
        }
    }, [props.modal])

    useEffect(() => {
        if (props.editModal === "success" && !isCreate) {
            clearStates()
            setmodal(false)
            setStepCurrent(0)
        }
    }, [props.editModal])

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const validate = (step) => {
        switch (step) {
            case 0:
                if (firstName.length < 3 || firstName.length > 100) {
                    toastr.warning(props.t("Please input valid First Name!"))
                    return false
                }
                if (lastName.length < 3 || lastName.length > 100) {
                    toastr.warning(props.t("Please input valid Last Name!"))
                    return false
                }
                if (address.length < 3 || address.length > 100) {
                    toastr.warning(props.t("Please input valid Address!"))
                    return false
                }
                if (birthday.length === 0) {
                    toastr.warning(props.t("Please select birthday!"))
                    return false
                }
                if (gender === "Gender") {
                    toastr.warning(props.t("Please select gender!"))
                    return false
                }
                if (!selectlanguages) {
                    toastr.warning(props.t("Please select languages!"))
                    return false
                }
                const current = stepCurrent + 1;
                setStepCurrent(current)
                break;
            case 1:
                let ckecks = [mMon, mTue, mWed, mThu, mFri, aMon, aTue, aWed, aThu, aFri]
                let total = 0
                ckecks.forEach(item => {
                    if (item) {
                        total++;
                    }
                });
                if (total < 3) {
                    toastr.warning("Have to check more than 3 days")
                    return false
                }
                setStepCurrent(stepCurrent + 1)
                break;
            case 2:
                if (motherName.length < 3 || motherName.length > 100) {
                    toastr.warning("Please input valid Mother/Tutor Name!")
                    return false
                }
                if (motherEmail.length === 0) {
                    toastr.warning("Please input Mother/Tutor Email!")
                    return false
                } else {
                    const valid = validateEmail(motherEmail)
                    if(!valid) {
                        toastr.warning("Please input valid email address!", "Info")
                        return false
                    }
                }
                if (motherSameAddress === 'Select') {
                    toastr.warning("Please select one!")
                    return false
                }
                if (motherSameAddress === 'No' && motherAddress === '') {
                    toastr.warning("Please input child address detail!")
                    return false
                }
                if (motherHomePhone === '(999) 999-9999') {
                    toastr.warning("Please input mother's home phone number!")
                    return false
                }
                if (fatherEmail) {
                    const valid = validateEmail(fatherEmail)
                    if(!valid) {
                        toastr.warning("Please input valid email address!", "Info")
                        return false
                    }
                }
                setStepCurrent(stepCurrent + 1)
                break;
            case 3:
                setStepCurrent(stepCurrent + 1)
                break;
            case 4:
                setStepCurrent(stepCurrent + 1)
                break;
            case 5:
                setStepCurrent(stepCurrent + 1)
                break;
            case 6:
                if (!firstPSign) {
                    toastr.warning("Please check to concerning outside activity authorization.")
                    return false
                }
                if (!firstSDate) {
                    toastr.warning("Please select date authorization above has been made")
                    return false
                }
                if (!secondPSign) {
                    toastr.warning("Please check to concerning authorization for the admission")
                    return false
                }
                if (!secondSDate) {
                    toastr.warning("Please select date authorization for the child admission has been made.")
                    return false
                }
                setStepCurrent(stepCurrent + 1)
                break;
            default:
                break;
        }
    }


    function onShowSizeChange(current, pageSize) {
        props.getAllStudents({ page: current, pageSize: pageSize })
        setPageSize(pageSize)
        setCurrentPage(current)
    }
    const onChange = (page) => {
        props.getAllStudents({ page: page, pageSize: pageSize })
        setCurrentPage(page)
    }

    const handleDel = (val) => {
        setDelData(val)
        setconfirm_both(true)
    }

    const handleSave = () => {
        if (!firstPSign) {
            toastr.warning("Please check to concerning outside activity authorization.")
            return false
        }
        if (!firstSDate) {
            toastr.warning("Please select date authorization above has been made")
            return false
        }
        if (!secondPSign) {
            toastr.warning("Please check to concerning authorization for the admission")
            return false
        }
        if (!secondSDate) {
            toastr.warning("Please select date authorization for the child admission has been made.")
            return false
        }

        const params = {
            firstName: firstName,
            lastName: lastName,
            address: address,
            birthday: birthday,
            gender: gender,
            selectlanguages: selectlanguages,
            mMon: mMon,
            mTue: mTue,
            mWed: mWed,
            mThu: mThu,
            mFri: mFri,
            aMon: aMon,
            aTue: aTue,
            aWed: aWed,
            aThu: aThu,
            aFri: aFri,
            motherName: motherName,
            motherEmail: motherEmail,
            motherAddress: motherSameAddress === "Yes" ? address : motherAddress,
            motherSameAddress: motherSameAddress,
            motherHomePhone: motherHomePhone,
            motherCellPhone: motherCellPhone,
            motherOfficePhone: motherOfficePhone,
            fatherName: fatherName,
            fatherEmail: fatherEmail,
            fatherSameAddress: fatherSameAddress,
            fatherAddress: fatherSameAddress === "Yes" ? address : fatherAddress,
            fatherHomePhone: fatherHomePhone,
            fatherCellPhone: fatherCellPhone,
            fatherOfficePhone: fatherOfficePhone,
            pickerName: pickerName,
            pickerPhone: pickerPhone,
            linkChild: linkChild,
            pickerAddress: pickerAddress,
            urgencyName: urgencyName,
            urgencyPhone: urgencyPhone,
            urgencyLinkChild: urgencyLinkChild,
            urgencyAddress: urgencyAddress,
            healthSin: healthSin === '' ? null : healthSin,
            healthExp: healthExp,
            specialCare: specialCare,
            specialCareDesc: specialCareDesc,
            medication: medication,
            medicationDesc: medicationDesc,
            sideEffects: sideEffects,
            sideEffectsDesc: sideEffectsDesc,
            vaccine: vaccine,
            vaccineUpToDate: vaccineUpToDate,
            allergies: allergies,
            allergyDesc: allergyDesc,
            provision: provision,
            provisionDesc: provisionDesc,
            doctorName: doctorName,
            doctorAddress: doctorAddress,
            doctorPhone: doctorPhone,
            drugSign: drugSign,
            firstPSign: firstPSign,
            firstSDate: firstSDate,
            signDesc: signDesc,
            secondPSign: secondPSign,
            secondSDate: secondSDate
        }
        props.createStudent(params)
    }

    const clearStates = () => {
        //step 1 states
        setFirstName('')
        setLastName('')
        setAddress('')
        setBirthday('')
        setGender('Gender')
        setSelectlanguages(null)

        //step 2 states
        setMMon(false)
        setMTue(false)
        setMWed(false)
        setMThu(false)
        setMFri(false)

        setAMon(false)
        setATue(false)
        setAWed(false)
        setAThu(false)
        setAFri(false)

        //step 3 states
        //* Mother info
        setMotherName('')
        setMotherEmail('')
        setMotherAddress('')
        setMotherSameAddress('Select')
        setMotherHomePhone("(999) 999-9999")
        setMotherCellPhone("(999) 999-9999")
        setMotherOfficePhone("(999) 999-9999")
        //* Father info
        setFatherName('')
        setFatherEmail('')
        setFatherSameAddress('Select')
        setFatherAddress('')
        setFatherHomePhone("(999) 999-9999")
        setFatherCellPhone("(999) 999-9999")
        setFatherOfficePhone("(999) 999-9999")
        // Step 4 states
        setPickerName('')
        setPickerPhone("(999) 999-9999")
        setLinkChild('')
        setPickerAddress('')
        //step 5 states
        setUrgencyName('')
        setUrgencyPhone("(999) 999-9999")
        setUrgencyLinkChild('')
        setUrgencyAddress('')
        //step 6 states
        sethealthSin('')
        setHealthExp(null)
        setSpecialCare('Special Care?')
        setSpecialCareDesc('')
        setMedication('Medication')
        setMedicationDesc('')
        setSideEffects('Side Effects')
        setSideEffectsDesc('')
        setVaccine('Vaccines')
        setVaccineUpToDate('Is the vaccination up to date?')
        setAllergies('Allergies')
        setAllergyDesc('')
        setProvision('Health prov')
        setProvisionDesc('')
        setDoctorName('')
        setDoctorAddress('')
        setDoctorPhone('(999) 999-9999')
        setDrugSign('')
        //step 7 states
        setFirstPSign(false)
        setFirstSDate(null)
        setSignDesc('')
        setSecondPSign(false)
        setSecondSDate(null)


        //checkbox
        setAllChecked(false)
        setMEmailIni(false)
        setFEmailIni(false)
        setMSave(false)
        setFSave(false)
    }

    const handlePreUpdate = (item, index) => {
        setIsCreate(false)
        setId(item.id)
        setIndex(index)
        setmodal(!modal)
        if (item.mMon && item.mTue && item.mWed && item.mThu && item.mFri && item.aMon && item.aTue && item.aWed && item.aThu && item.aFri) {
            setAllChecked(true)
        }
        //step 1 states
        setFirstName(item.firstName)
        setLastName(item.lastName)
        setAddress(item.address)
        setBirthday((item.birthday).substring(0, 10))
        setGender(item.gender)
        setSelectlanguages(item.selectlanguages)

        //step 2 states
        setMMon(item.mMon)
        setMTue(item.mTue)
        setMWed(item.mWed)
        setMThu(item.mThu)
        setMFri(item.mFri)

        setAMon(item.aMon)
        setATue(item.aTue)
        setAWed(item.aWed)
        setAThu(item.aThu)
        setAFri(item.aFri)

        //step 3 states
        //* Mother info
        setMotherName(item.motherName)
        setMotherEmail(item.motherEmail)
        setMotherAddress(item.motherAddress)
        setMotherSameAddress(item.motherSameAddress)
        setMotherHomePhone(item.motherHomePhone)
        setMotherCellPhone(item.motherCellPhone)
        setMotherOfficePhone(item.motherOfficePhone)
        //* Father info
        setFatherName(item.fatherName)
        setFatherEmail(item.fatherEmail)
        setFatherSameAddress(item.fatherSameAddress)
        setFatherAddress(item.fatherAddress)
        setFatherHomePhone(item.fatherHomePhone)
        setFatherCellPhone(item.fatherCellPhone)
        setFatherOfficePhone(item.fatherOfficePhone)
        // Step 4 states
        setPickerName(item.pickerName)
        setPickerPhone(item.pickerPhone)
        setLinkChild(item.linkChild)
        setPickerAddress(item.pickerAddress)
        //step 5 states
        setUrgencyName(item.urgencyName)
        setUrgencyPhone(item.urgencyPhone)
        setUrgencyLinkChild(item.urgencyLinkChild)
        setUrgencyAddress(item.urgencyAddress)
        //step 6 states
        sethealthSin(!item.healthSin ? '' : item.healthSin)
        setHealthExp(item.healthExp ? (item.healthExp).substring(0, 10) : '')
        setSpecialCare(item.specialCare)
        setSpecialCareDesc(item.specialCareDesc)
        setMedication(item.medication)
        setMedicationDesc(item.medicationDesc)
        setSideEffects(item.sideEffects)
        setSideEffectsDesc(item.sideEffectsDesc)
        setVaccine(item.vaccine)
        setVaccineUpToDate(item.vaccineUpToDate)
        setAllergies(item.allergies)
        setAllergyDesc(item.allergyDesc)
        setProvision(item.provision)
        setProvisionDesc(item.provisionDesc)
        setDoctorName(item.doctorName)
        setDoctorAddress(item.doctorAddress)
        setDoctorPhone(item.doctorPhone)
        setDrugSign(item.drugSign)
        //step 7 states
        setFirstPSign(item.firstPSign)
        setFirstSDate((item.firstSDate).substring(0, 10))
        setSignDesc(item.signDesc)
        setSecondPSign(item.secondPSign)
        setSecondSDate((item.secondSDate).substring(0, 10))
    }

    const handleUpdate = () => {
        if (!firstPSign) {
            toastr.warning("Please check to concerning outside activity authorization.")
            return false
        }
        if (!firstSDate) {
            toastr.warning("Please select date authorization above has been made")
            return false
        }
        if (!secondPSign) {
            toastr.warning("Please check to concerning authorization for the admission")
            return false
        }
        if (!secondSDate) {
            toastr.warning("Please select date authorization for the child admission has been made.")
            return false
        }
        const params = {
            firstName: firstName,
            lastName: lastName,
            address: address,
            birthday: birthday,
            gender: gender,
            selectlanguages: selectlanguages,
            mMon: mMon,
            mTue: mTue,
            mWed: mWed,
            mThu: mThu,
            mFri: mFri,
            aMon: aMon,
            aTue: aTue,
            aWed: aWed,
            aThu: aThu,
            aFri: aFri,
            motherName: motherName,
            motherEmail: motherEmail,
            motherAddress: motherSameAddress === "Yes" ? address : motherAddress,
            motherSameAddress: motherSameAddress,
            motherHomePhone: motherHomePhone,
            motherCellPhone: motherCellPhone,
            motherOfficePhone: motherOfficePhone,
            fatherName: fatherName,
            fatherEmail: fatherEmail,
            fatherSameAddress: fatherSameAddress,
            fatherAddress: fatherSameAddress === "Yes" ? address : fatherAddress,
            fatherHomePhone: fatherHomePhone,
            fatherCellPhone: fatherCellPhone,
            fatherOfficePhone: fatherOfficePhone,
            pickerName: pickerName,
            pickerPhone: pickerPhone,
            linkChild: linkChild,
            pickerAddress: pickerAddress,
            urgencyName: urgencyName,
            urgencyPhone: urgencyPhone,
            urgencyLinkChild: urgencyLinkChild,
            urgencyAddress: urgencyAddress,
            healthSin: healthSin === '' ? null : healthSin,
            healthExp: healthExp,
            specialCare: specialCare,
            specialCareDesc: specialCareDesc,
            medication: medication,
            medicationDesc: medicationDesc,
            sideEffects: sideEffects,
            sideEffectsDesc: sideEffectsDesc,
            vaccine: vaccine,
            vaccineUpToDate: vaccineUpToDate,
            allergies: allergies,
            allergyDesc: allergyDesc,
            provision: provision,
            provisionDesc: provisionDesc,
            doctorName: doctorName,
            doctorAddress: doctorAddress,
            doctorPhone: doctorPhone,
            drugSign: drugSign,
            firstPSign: firstPSign,
            firstSDate: firstSDate,
            signDesc: signDesc,
            secondPSign: secondPSign,
            secondSDate: secondSDate
        }
        params.id = id
        params.index = index
        props.updateStudent(params)
    }


    //Step1 functions

    const handleFirstName = (e) => {
        setFirstName(e.currentTarget.value)
    }

    const handleLastName = (e) => {
        setLastName(e.currentTarget.value)
    }

    const handleAddress = (e) => {
        setAddress(e.currentTarget.value)
    }

    const handleBirthChange = (e) => {
        setBirthday(e.currentTarget.value)
    }

    const handleGender = (e) => {
        setGender(e.currentTarget.value)
    }
    const handleMultiLang = (selectlanguages) => { setSelectlanguages(selectlanguages); }

    const allSelect = () => {
        setAllChecked(!allChecked)
        if (!allChecked) {
            allSelected(true)
        } else {
            allSelected(false)
        }
    }

    const allSelected = (param) => {
        //step 2 states
        setMMon(param)
        setMTue(param)
        setMWed(param)
        setMThu(param)
        setMFri(param)

        setAMon(param)
        setATue(param)
        setAWed(param)
        setAThu(param)
        setAFri(param)
    }
    const postDelete = () => {
        const headers = JSON.parse(localStorage.getItem("authHeaders"))
        const config = {
            method: "delete",
            url: `/api/v1/students/${delData.id}`,
            headers: {
                accesstoken: headers.accesstoken,
                client: headers.client,
                uid: headers.uid,
            }
        }
        return axios(config).then(response => {
            if (response.status === 400 || response.status === 500)
                throw response.data;
            setsuccess_dlg(true);
            setdynamic_title("Deleted");
            setdynamic_description("Your student has been deleted.");
        }).catch(err => {
            seterror_dlg(true)
            throw err[1];
        });
    }

    const steps = [
        {
            title: props.t("Child Info"),
            content:
                <Card className="mt-5">
                    <CardBody>
                        <AvForm className="needs-validation" >
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label htmlFor="validationCustom01">{props.t('First name')}</Label>
                                        <AvField
                                            name="firstname"
                                            placeholder={props.t('First name')}
                                            value={firstName}
                                            type="text"
                                            errorMessage={props.t("Enter First Name!")}
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="validationCustom01"
                                            onChange={handleFirstName}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label htmlFor="validationCustom02">{props.t('Last name')}</Label>
                                        <AvField
                                            name="lastname"
                                            placeholder={props.t('Last name')}
                                            type="text"
                                            value={lastName}
                                            errorMessage={props.t("Enter Last Name!")}
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="validationCustom02"
                                            onChange={handleLastName}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label htmlFor="validationCustom03">{props.t('Address')}</Label>
                                        <AvField
                                            name="address"
                                            placeholder={props.t("Address")}
                                            type="text"
                                            value={address}
                                            onChange={handleAddress}
                                            errorMessage={props.t("Please provide a valid address!")}
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="validationCustom03"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="validationCustom04">{props.t('Birthday')}</Label>
                                        <AvField
                                            name="date"
                                            type="date"
                                            errorMessage={props.t("Please provide a valid date!")}
                                            className="form-control"
                                            id="validationCustom04"
                                            value={birthday}
                                            onChange={handleBirthChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label>{props.t('Gender')}</Label>
                                        <AvField type="select" value={gender} name="gender" validate={{ required: { value: true } }} onChange={handleGender}>
                                            <option>{props.t('Gender')}</option>
                                            <option>M</option>
                                            <option>F</option>
                                        </AvField>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="select2-container">
                                        <label className="control-label">{props.t('Language')}</label>
                                        <Select
                                            value={selectlanguages}
                                            isMulti={true}
                                            onChange={handleMultiLang}
                                            options={optionGroup}
                                            closeMenuOnSelect={false}
                                            classNamePrefix="select2-selection"
                                            name="language"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </AvForm>
                    </CardBody>
                </Card>
        },
        {
            title: props.t('Attendance'),
            content:
                <Card className="mt-5">
                    <CardBody>
                        <AvForm className="needs-validation" >
                            <Row>
                                <div className="form-check form-check-right mb-3">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck2" onChange={allSelect} checked={allChecked} />
                                    <label className="form-check-label" htmlFor="defaultCheck2" style={{ marginTop: "0.8px" }}>
                                        {props.t('Click to select all days')}
                                    </label>
                                </div>
                                <Table id="tech-companies-1" className="table table-striped table-bordered">
                                    <Thead>
                                        <Tr>
                                            <Th data-priority="1">{props.t('Period')}</Th>
                                            <Th data-priority="1">{props.t('Monday')}</Th>
                                            <Th data-priority="3">{props.t('Tuesday')}</Th>
                                            <Th data-priority="1">{props.t('Wednesday')}</Th>
                                            <Th data-priority="1">{props.t('Thursday')}</Th>
                                            <Th data-priority="1">{props.t('Friday')}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr>
                                            <Td>{props.t('Morning')}</Td>
                                            <Td className="week-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setMMon(!mMon)} checked={mMon} />
                                            </Td>
                                            <Td className="week-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setMTue(!mTue)} checked={mTue} />
                                            </Td>
                                            <Td className="week-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setMWed(!mWed)} checked={mWed} />
                                            </Td>
                                            <Td className="week-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setMThu(!mThu)} checked={mThu} />
                                            </Td>
                                            <Td className="week-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setMFri(!mFri)} checked={mFri} />
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>{props.t('Afternoon')}</Td>
                                            <Td className="week-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setAMon(!aMon)} checked={aMon} />
                                            </Td>
                                            <Td className="week-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setATue(!aTue)} checked={aTue} />
                                            </Td>
                                            <Td className="week-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setAWed(!aWed)} checked={aWed} />
                                            </Td>
                                            <Td className="week-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setAThu(!aThu)} checked={aThu} />
                                            </Td>
                                            <Td className="week-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setAFri(!aFri)} checked={aFri} />
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </Row>
                        </AvForm>
                    </CardBody>
                </Card>,
        },
        {
            title: props.t("Parent's info"),
            content:
                <Card className="mt-5">
                    <CardBody>
                        <AvForm className="needs-validation" >
                            <Row>
                                <Col md={6}>
                                    <p><strong>{props.t('Mother/Tutor')}</strong></p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Label>{props.t('Name')}</Label>
                                    <AvField
                                        name="motherName"
                                        placeholder="Mother/Tutor"
                                        type="text"
                                        errorMessage="Enter Name"
                                        validate={{ required: { value: true } }}
                                        value={motherName}
                                        onChange={(e) => { setMotherName(e.currentTarget.value) }}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Row>
                                        <Col xs={9}>
                                            <Label>{props.t('Email')}</Label>
                                            <AvField
                                                name="motherEmail"
                                                placeholder="Mother/Tutor E-mail"
                                                type="email"
                                                errorMessage="Invalid Email"
                                                validate={{
                                                    required: { value: true },
                                                    email: { value: true }
                                                }}
                                                value={motherEmail}
                                                onChange={(e) => { setMotherEmail(e.currentTarget.value)}}
                                            />
                                        </Col>
                                        <Col xs={3}>
                                            <Tooltip placement="top" title='Once checked the parent user will be automatically be created.'>
                                                <label>Save</label>
                                            </Tooltip>
                                            <Checkbox
                                                className="form-control"
                                                style={{border: "none"}}
                                                checked = {mSave}
                                                onChange={e => saveMother(e.target.checked)}
                                                disabled={!isCreate ? true: mEmailIni}
                                            >
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Same Address Child?')}</Label>
                                    <AvField
                                        type="select"
                                        value={motherSameAddress}
                                        name="motherSameAddress"
                                        onChange={(e) => setMotherSameAddress(e.target.value)}
                                        validate={{ required: { value: true } }}>
                                        <option disabled={true}>Select</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </AvField>
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Address')}</Label>
                                    <AvField
                                        name="motherAddress"
                                        placeholder="Mother/Tutor Address"
                                        type="text"
                                        value={motherAddress}
                                        onChange={(e) => { setMotherAddress(e.currentTarget.value) }}
                                        errorMessage="Enter valid address"
                                        className="form-control"
                                        id="validationCustom03"
                                        disabled={motherSameAddress === "No" ? false : true}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Home phone number')}*</Label>
                                    <InputMask
                                        mask="(999) 999-9999"
                                        value={motherHomePhone}
                                        onChange={(e) => { setMotherHomePhone(e.currentTarget.value) }}
                                        className="form-control input-color"
                                        required={true}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Cell phone number')}</Label>
                                    <InputMask
                                        name="motherCellPhone"
                                        mask="(999) 999-9999"
                                        value={motherCellPhone}
                                        onChange={(e) => { setMotherCellPhone(e.currentTarget.value) }}
                                        className="form-control input-color"
                                        required={false}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Office phone number')}</Label>
                                    <InputMask
                                        name="motherOfficePhone"
                                        mask="(999) 999-9999"
                                        value={motherOfficePhone}
                                        onChange={(e) => { setMotherOfficePhone(e.currentTarget.value) }}
                                        className="form-control input-color"
                                        required={false}
                                    />
                                </Col>
                            </Row>
                        </AvForm>
                        <AvForm className="needs-validation mt-2" >
                            <Row>
                                <Col md={6}>
                                    <p><strong>Father/Tutor</strong></p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Label>{props.t('Name')}</Label>
                                    <AvField
                                        name="fatherName"
                                        placeholder="Father/Tutor"
                                        type="text"
                                        errorMessage="Enter Name"
                                        value={fatherName}
                                        onChange={(e) => { setFatherName(e.currentTarget.value) }}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Row>
                                        <Col xs={9}>
                                            <Label>{props.t('Email')}</Label>
                                            <AvField
                                                name="FatherEmail"
                                                placeholder="Father/Tutor E-mail"
                                                type="email"
                                                errorMessage="Invalid Email"
                                                validate={{
                                                    email: { value: true }
                                                }}
                                                value={fatherEmail}
                                                onChange={(e) => { setFatherEmail(e.currentTarget.value)}}
                                            />
                                        </Col>
                                        <Col xs={3}>
                                            <Tooltip placement="top" title='Once checked the parent user will be automatically be created.'>
                                                <label>Save</label>
                                            </Tooltip>
                                            <Checkbox
                                                className="form-control"
                                                style={{border: "none"}}
                                                checked = {fSave}
                                                onChange={e => saveFather(e.target.checked)}
                                                disabled={!isCreate ? true: fEmailIni}
                                            >
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Same Address Child?')}</Label>
                                    <AvField
                                        type="select"
                                        value={fatherSameAddress}
                                        name="fatherSameAddress"
                                        onChange={(e) => setFatherSameAddress(e.target.value)}
                                    >
                                        <option disabled={true}>Select</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </AvField>
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Address')}</Label>
                                    <AvField
                                        name="fatherAddress"
                                        placeholder="Father/Tutor Address"
                                        type="text"
                                        value={fatherAddress}
                                        onChange={(e) => { setFatherAddress(e.currentTarget.value) }}
                                        errorMessage="Enter valid address"
                                        className="form-control"
                                        id="validationCustom03"
                                        disabled={fatherSameAddress === 'No' ? false : true}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Home phone number')}*</Label>
                                    <InputMask
                                        name="fatherHomePhone"
                                        mask="(999) 999-9999"
                                        value={fatherHomePhone}
                                        onChange={(e) => { setFatherHomePhone(e.currentTarget.value) }}
                                        className="form-control input-color"
                                        required={false}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Cell phone number')}</Label>
                                    <InputMask
                                        name="fatherCellPhone"
                                        mask="(999) 999-9999"
                                        value={fatherCellPhone}
                                        onChange={(e) => { setFatherCellPhone(e.currentTarget.value) }}
                                        className="form-control input-color"
                                        required={false}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Label>{props.t('Office phone number')}</Label>
                                    <InputMask
                                        name="fatherOfficePhone"
                                        mask="(999) 999-9999"
                                        value={fatherOfficePhone}
                                        onChange={(e) => { setFatherOfficePhone(e.currentTarget.value) }}
                                        className="form-control input-color"
                                        required={false}
                                    />
                                </Col>
                            </Row>
                        </AvForm>
                    </CardBody>
                </Card>,
        },
        {
            title: 'Pickup Child',
            content: <Card className="mt-5">
                <CardBody>
                    <AvForm className="needs-validation" >
                        <Row>
                            <Col md={12}>
                                <p><strong>Persons authorized to pickup the child</strong></p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Label>{props.t('Name')}</Label>
                                <AvField
                                    name="pickerName"
                                    placeholder="name"
                                    type="text"
                                    errorMessage="Enter Name"
                                    validate={{ required: { value: true } }}
                                    value={pickerName}
                                    onChange={(e) => { setPickerName(e.currentTarget.value) }}
                                />
                            </Col>
                            <Col md={4}>
                                <Label>{props.t('Link with child')}</Label>
                                <AvField
                                    name="linkchild"
                                    placeholder="Link with child"
                                    type="text"
                                    value={linkChild}
                                    onChange={(e) => { setLinkChild(e.currentTarget.value) }}
                                    errorMessage="Enter valid value"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom03"
                                />
                            </Col>
                            <Col md={4}>
                                <Label>{props.t('Phone Number')}</Label>
                                <InputMask
                                    mask="(999) 999-9999"
                                    value={pickerPhone}
                                    onChange={(e) => setPickerPhone(e.target.value)}
                                    className="form-control input-color"
                                />
                            </Col>
                            <Col md={4}>
                                <Label>{props.t('Address')}</Label>
                                <AvField
                                    name="pickerAddress"
                                    placeholder="address"
                                    type="text"
                                    value={pickerAddress}
                                    onChange={(e) => { setPickerAddress(e.currentTarget.value) }}
                                    errorMessage="Enter valid address"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom03"
                                />
                            </Col>

                        </Row>
                    </AvForm>
                </CardBody>
            </Card>,
        },
        {
            title: 'Urgency',
            content: <Card className="mt-5">
                <CardBody>
                    <AvForm className="needs-validation" >
                        <Row>
                            <Col md={12}>
                                <p><strong>{props.t('Contact in case of urgency (other than parents)')}</strong></p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Label>{props.t('Name')}</Label>
                                <AvField
                                    name="urgencyName"
                                    placeholder="name"
                                    type="text"
                                    errorMessage="Enter Name"
                                    validate={{ required: { value: true } }}
                                    value={urgencyName}
                                    onChange={(e) => { setUrgencyName(e.currentTarget.value) }}
                                />
                            </Col>
                            <Col md={4}>
                                <Label>{props.t('Link with child')}</Label>
                                <AvField
                                    name="urgencylinkchild"
                                    placeholder="Link with child"
                                    type="text"
                                    value={urgencyLinkChild}
                                    onChange={(e) => setUrgencyLinkChild(e.target.value)}
                                    errorMessage="Enter valid value"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom03"
                                />
                            </Col>
                            <Col md={4}>
                                <Label>{props.t('Phone Number')}</Label>
                                <InputMask
                                    mask="(999) 999-9999"
                                    value={urgencyPhone}
                                    onChange={(e) => { setUrgencyPhone(e.currentTarget.value) }}
                                    className="form-control input-color"
                                />
                            </Col>
                            <Col md={4}>
                                <Label>{props.t('Address')}</Label>
                                <AvField
                                    name="urgencyAddress"
                                    placeholder="address"
                                    type="text"
                                    value={urgencyAddress}
                                    onChange={(e) => { setUrgencyAddress(e.currentTarget.value) }}
                                    errorMessage="Enter valid address"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom03"
                                />
                            </Col>

                        </Row>
                    </AvForm>
                </CardBody>
            </Card>,
        },
        {
            title: 'Health Care',
            content:
                <Card className="mt-5">
                    <CardBody>
                        <AvForm className="needs-validation" >
                            <Row>
                                <Col md={12}>
                                    <p><strong>{props.t('Child health information')}</strong></p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Numéro d’assurance maladie:</Label>
                                        <InputMask
                                            // mask="999.999.999"
                                            value={healthSin}
                                            onChange={(e) => sethealthSin(e.target.value)}
                                            className="form-control input-color"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>{props.t('Exp Date')}</Label>
                                        <AvField
                                            name="exp"
                                            type="date"
                                            placeholder='Exp Date'
                                            className="form-control"
                                            id="validationCustom04"
                                            value={healthExp}
                                            onChange={(e) => setHealthExp(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    Are there any health problems that require special care?
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        type="select"
                                        value={specialCare}
                                        name="fatherSameAddress"
                                        onChange={(e) => setSpecialCare(e.target.value)}
                                        validate={{ required: { value: true } }}>
                                        <option disabled={true}>Special Care?</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </AvField>
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        name="specialCareDesc"
                                        placeholder="Special care detail"
                                        type="text"
                                        value={specialCareDesc}
                                        onChange={(e) => { setSpecialCareDesc(e.currentTarget.value) }}
                                        className="form-control"
                                        disabled={specialCare === "Yes" ? false : true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    {props.t('Does he/she take medication on a regular basis?')}
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        type="select"
                                        value={medication}
                                        name="medication"
                                        onChange={(e) => setMedication(e.target.value)}
                                        validate={{ required: { value: true } }}>
                                        <option disabled={true}>Medication</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </AvField>
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        name="specialCareDesc"
                                        placeholder="Medication"
                                        type="text"
                                        value={medicationDesc}
                                        onChange={(e) => { setMedicationDesc(e.currentTarget.value) }}
                                        className="form-control"
                                        disabled={medication === "Yes" ? false : true}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    {props.t('Is there any possible side effects for these medication?')}
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        type="select"
                                        value={sideEffects}
                                        name="sideEffect"
                                        onChange={(e) => setSideEffects(e.target.value)}
                                        validate={{ required: { value: true } }}>
                                        <option disabled={true}>Side Effects</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </AvField>
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        name="specialCareDesc"
                                        placeholder="Medication"
                                        type="text"
                                        value={sideEffectsDesc}
                                        onChange={(e) => { setSideEffectsDesc(e.currentTarget.value) }}
                                        className="form-control"
                                        disabled={sideEffects === "Yes" ? false : true}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    {props.t('Has the child received all vaccines?')}
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        type="select"
                                        value={vaccine}
                                        name="vaccine"
                                        onChange={(e) => setVaccine(e.target.value)}
                                        validate={{ required: { value: true } }}>
                                        <option disabled={true}>Vaccines</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </AvField>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    {props.t('Is the vaccination up to date?')}
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        type="select"
                                        value={vaccineUpToDate}
                                        name="vaccine"
                                        onChange={(e) => setVaccineUpToDate(e.target.value)}
                                        validate={{ required: { value: true } }}>
                                        <option disabled={true}>Is the vaccination up to date?</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </AvField>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    {props.t('Does your child have allergies(medication or food)?')}
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        type="select"
                                        value={allergies}
                                        name="allergies"
                                        onChange={(e) => setAllergies(e.target.value)}
                                        validate={{ required: { value: true } }}>
                                        <option disabled={true}>Allergies</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </AvField>
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        name="specialCareDesc"
                                        placeholder="If yes, all prescription must be provided"
                                        type="text"
                                        value={allergyDesc}
                                        onChange={(e) => { setAllergyDesc(e.currentTarget.value) }}
                                        className="form-control"
                                        disabled={allergies === "Yes" ? false : true}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    {props.t('Are there provisions to be taken for his' +
                                        'health according to the disease and / or allergies' +
                                        'mentioned above in case of emergency?')}
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        type="select"
                                        value={provision}
                                        name="provision"
                                        onChange={(e) => setProvision(e.target.value)}
                                        validate={{ required: { value: true } }}>
                                        <option disabled={true}>Health prov</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </AvField>
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        name="provisionDesc"
                                        placeholder="If yes, what?"
                                        type="text"
                                        value={provisionDesc}
                                        onChange={(e) => { setProvisionDesc(e.currentTarget.value) }}
                                        className="form-control"
                                        disabled={provision === "Yes" ? false : true}
                                    />
                                </Col>
                            </Row>

                            <Row className='mt-2'>
                                <Col md={4}>
                                    {props.t('Name of Pediatrician And / or family doctor')}
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        name="doctorName"
                                        placeholder="Name"
                                        type="text"
                                        errorMessage="Enter Name"
                                        validate={{ required: { value: true } }}
                                        value={doctorName}
                                        onChange={(e) => { setDoctorName(e.currentTarget.value) }}
                                    />
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        name="doctorAddress"
                                        placeholder="Address"
                                        type="text"
                                        value={doctorAddress}
                                        onChange={(e) => { setDoctorAddress(e.currentTarget.value) }}
                                        errorMessage="Enter valid address"
                                        className="form-control"
                                        validate={{ required: { value: true } }}
                                        id="validationCustom03"
                                    />
                                </Col>
                                <Col md={{ size: 4, offset: 4 }}>
                                    <Label>{props.t('Phone')}</Label>
                                    <InputMask
                                        mask="(999) 999-9999"
                                        value={doctorPhone}
                                        onChange={(e) => setDoctorPhone(e.target.value)}
                                        className="form-control input-color"
                                    />
                                </Col>
                            </Row>

                            <Row className='mt-2'>
                                <Col md={4}>
                                    {props.t('Authorized Drugs. This is required by signing MFA forms')}
                                </Col>
                                <Col md={4}>
                                    <AvField
                                        name="drugSign"
                                        // placeholder="Tylenol, Sunscreen, insect repellent"
                                        type="text"
                                        errorMessage="Enter valid value"
                                        validate={{ required: { value: true } }}
                                        value={drugSign}
                                        onChange={(e) => { setDrugSign(e.currentTarget.value) }}
                                    />
                                </Col>
                            </Row>

                        </AvForm>
                    </CardBody>
                </Card>,
        },
        {
            title: 'Authorizations',
            content:
                <Card className="mt-5">
                    <CardBody>
                        <AvForm className="needs-validation" >
                            <Row>
                                <Col md={12}>
                                    <p><strong>{props.t('Authorizations')}</strong></p>
                                </Col>
                                <Col md={12}>
                                    {props.t('I authorize my child to participate on outdoors activities organized by Maplebeez' +
                                        'de Beauport school. (educational or sports activities)')}
                                </Col>
                                <br />
                                <Col md={6} className="form-check mt-1" style={{ padding: '32px' }}>
                                    <input className="form-check-input auth-check" type="checkbox" value="" id="defaultCheck1" onChange={() => setFirstPSign(!firstPSign)} checked={firstPSign} />
                                    <label className="form-check-label auth-label" htmlFor="defaultCheck1">
                                        Parent's Authorization
                                    </label>
                                </Col>

                                <Col md={6}>
                                    <FormGroup style={{ marginTop: '32px' }}>
                                        <Label>{props.t('Authorization Date')}</Label>
                                        <AvField
                                            name="authoriaztionDate"
                                            type="date"
                                            className="form-control"
                                            id="validationCustom04"
                                            value={firstSDate}
                                            onChange={(e) => setFirstSDate(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <br />
                                <p style={{ padding: '12px' }}>
                                    I authorize the Maplebeez to take the necessary measures in case of urgency concerning the security and healthy of my child.
                                </p>
                                <p style={{ padding: '12px' }}>
                                    Is there any particular or concerns we should know in case of urgency? (hospital, ambulance, first aid). If yes, please describe.
                                </p>
                                <Col md={12}>
                                    <Input
                                        type="textarea"
                                        id="textarea"
                                        value={signDesc}
                                        onChange={(e) => { setSignDesc(e.target.value) }}
                                        maxLength="225"
                                        rows="3"
                                        placeholder="+info"
                                    />
                                </Col>
                                <br />
                                <Col md={6} className="form-check mt-1" style={{ padding: '32px' }}>
                                    <input className="form-check-input auth-check" type="checkbox" value="" id="defaultCheck1" onChange={() => setSecondPSign(!secondPSign)} checked={secondPSign} />
                                    <label className="form-check-label auth-label" htmlFor="defaultCheck1">
                                        Parent's Authorization
                                    </label>
                                </Col>

                                <Col md={6}>
                                    <FormGroup style={{ marginTop: '32px' }}>
                                        <Label>{props.t('Authorization Date')}</Label>
                                        <AvField
                                            name="authoriaztionDate"
                                            type="date"
                                            className="form-control"
                                            id="validationCustom04"
                                            value={secondSDate}
                                            onChange={(e) => setSecondSDate(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </AvForm>
                    </CardBody>
                </Card>,
        },
    ];

    const next = () => {
        validate(stepCurrent)
    }

    const prev = () => {
        const current = stepCurrent - 1;
        setStepCurrent(current)
    }

    const studentList = () => {
        if (props.students) {
            return props.students.map((item, index) => {
                return (
                    <Tr key={index}>
                        <Th>{item.firstName + " " + item.lastName}</Th>
                        {/* <Td>{(moment().format('YYYY') - moment(item.birthday).format('YYYY')).toString()}</Td> */}
                        <Td>{item.active ? 'active' : 'inactive'}</Td>
                        <Td>
                            {item.motherName}
                        </Td>
                        <Td>
                            {item.motherHomePhone}
                        </Td>
                        <Td>
                            {item.motherEmail}
                        </Td>
                        <Td>
                            <i className="fas fa-address-card actions" onClick={() => { window.location.href = `/students/${item.id}` }}></i>
                            <i className="fas fa-pencil-alt actions" onClick={() => { handlePreUpdate(item, index) }}></i>
                            <i className="fas fa-trash-alt actions" onClick={() => handleDel(item)}></i>
                        </Td>
                    </Tr>
                )
            })
        }
    }

    const saveMother = (checked) => {
        if(!motherName) {
            toastr.info("The name of mother is missing", "info")
            setMSave(false)
            return false
        }
        if(!motherEmail) {
            toastr.info("The email address of mother is missing", "info")
            setMSave(false)
            return false
        } else {
            const valid = validateEmail(motherEmail)
            if(!valid) {
                toastr.warning("Please input valid email address!", "Info")
                setMSave(false)
                return false
            }
        }
        setMEmailIni(true)
        setMSave(checked)
        const params = {name: motherName, role: "Parent", email: motherEmail, password: "123456", password_confirmation: "123456"}
        addNewUser('/api/v1/auth', params)
            .then((result) => {
                if(result.status === "success") {
                    toastr.success("The user has been saved!", "Success")
                } else {
                    setMEmailIni(false)
                    setMSave(false)
                    toastr.error("It seems the email informed already exists","Error")
                }
            })
            .catch((error) => {
                setMEmailIni(false)
                setMSave(false)
            })
    }

    const saveFather = (checked) => {
        if(!fatherName) {
            toastr.info("The name of father is missing", "info")
            setFSave(false)
            return false
        }
        if(!fatherEmail) {
            toastr.info("The email address of father is missing", "info")
            setFSave(false)
            return false
        } else {
            const valid = validateEmail(fatherEmail)
            if(!valid) {
                toastr.warning("Please input valid email address!", "Info")
                setFSave(false)
                return false
            }
        }
        setFEmailIni(true)
        setFSave(checked)
        const params = {name: fatherName, role: "Parent", email: fatherEmail, password: "123456", password_confirmation: "123456"}
        addNewUser('/api/v1/auth', params)
            .then((result) => {
                if(result.status === "success") {
                    toastr.success("The user has been saved!", "Success")
                } else {
                    setFEmailIni(false)
                    setFSave(false)
                    toastr.error("It seems the email informed already exists","Error")
                }
            })
            .catch((error) => {
                setFEmailIni(false)
                setFSave(false)
            })
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {/* Render Breadcrumb */}
                    <Breadcrumbs title={props.t('Students')} breadcrumbItem={props.t('Students')} />

                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Button type="button" color="info" className="mb-3 float-md-right" onClick={() => { setmodal(!modal); setIsCreate(true) }}>
                                        <span style={{ fontSize: "15px" }}>{props.t('Add Student')}</span>
                                    </Button>
                                    {/*<Button type="button" color="danger" className="mb-3 float-md-left" onClick={()=> { setIsCreate(true); handleSave() }}>*/}
                                    {/*    <span style={{fontSize: "15px"}}>Test create</span>*/}
                                    {/*</Button>*/}
                                    {/*<Button type="button" color="warning" className="mb-3 float-md-left" onClick={()=> { setIsCreate(true); handleUpdate() }}>*/}
                                    {/*    <span style={{fontSize: "15px"}}>Test Edit</span>*/}
                                    {/*</Button>*/}
                                    <div className="table-rep-plugin">
                                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                                            <Table id="tech-companies-1" className="table table-striped table-bordered">
                                                <Thead>
                                                    <Tr>
                                                        <Th data-priority="1">{props.t('Name')}</Th>
                                                        {/* <Th data-priority="1">Age</Th> */}
                                                        <Th data-priority="3">Status</Th>
                                                        <Th data-priority="1">Contact</Th>
                                                        <Th data-priority="1">Contact Phone</Th>
                                                        <Th data-priority="1">Contact E-mail</Th>
                                                        <Th data-priority="1">Actions</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {studentList()}
                                                </Tbody>
                                            </Table>
                                        </div>
                                    </div>
                                    <Pagination className="float-right" current={current} defaultPageSize={20} onShowSizeChange={onShowSizeChange} onChange={onChange} total={props.studentsCount ? props.studentsCount : 0} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal isOpen={modal} size='xl' role="dialog" autoFocus={true} centered={true} backdrop={"static"} className="exampleModal" tabIndex="-1" toggle={() => { setmodal(!modal); setStepCurrent(0); clearStates() }}>
                        <div className="modal-content">
                            <ModalHeader toggle={() => { setmodal(!modal); setStepCurrent(0); clearStates() }}>
                                {props.t('Add Student')}
                            </ModalHeader >
                            <ModalBody>
                                <Steps current={stepCurrent}>
                                    {steps.map(item => (
                                        <Step key={item.title} title={item.title} />
                                    ))}
                                </Steps>
                                <div className="steps-content">{steps[stepCurrent].content}</div>
                                <div className="steps-action" style={{ marginLeft: "1.25rem" }}>
                                    {stepCurrent > 0 && (
                                        <Button
                                            color="success"
                                            className="btn btn-success waves-effect waves-light"
                                            onClick={prev}
                                            style={{ marginRight: "10px" }}
                                        >
                                            {props.t('Previous')}
                                        </Button>
                                    )}
                                    {stepCurrent < steps.length - 1 && (
                                        <Button
                                            color="success"
                                            className="btn btn-success waves-effect waves-light"
                                            onClick={next}

                                        >
                                            {props.t('Next')}
                                        </Button>
                                    )}
                                    {stepCurrent === steps.length - 1 && isCreate && (
                                        <Button
                                            color="success"
                                            className="btn btn-success waves-effect waves-light"
                                            style={{ marginRight: "10px" }}
                                            disabled={props.loading}
                                            onClick={handleSave}
                                        >
                                            {props.loading ? (
                                                <React.Fragment>
                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                    Saving
                                                </React.Fragment>
                                            ): "Save"}
                                        </Button>
                                    )}
                                    {stepCurrent === steps.length - 1 && !isCreate && (
                                        <Button
                                            color="success"
                                            className="btn btn-success waves-effect waves-light"
                                            style={{ marginRight: "10px" }}
                                            disabled={props.loading}
                                            onClick={handleUpdate}
                                        >
                                            {props.loading ? (
                                                <React.Fragment>
                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                    Updating
                                                </React.Fragment>
                                            ): "Update"}
                                        </Button>
                                    )}

                                </div>
                            </ModalBody>
                            {/*<ModalFooter>*/}
                            {/*    <Button type="button" color="secondary" onClick={() => { setmodal(!modal); }}>Close</Button>*/}
                            {/*</ModalFooter>*/}
                        </div>
                    </Modal>


                    <Modal isOpen={editModal} role="dialog" autoFocus={true} centered={true} className="myModal" tabIndex="-1" toggle={() => { setEditModal(!editModal); }}>
                        <div className="modal-content">
                            <ModalHeader toggle={() => { setEditModal(!editModal); }}>
                                Edit User
                            </ModalHeader >
                            <ModalBody>
                                <Col sm="12">
                                    <Card>
                                        <CardBody>
                                            <AvForm onValidSubmit={(e, v) => { handleEditUser(e, v) }}>
                                                <AvField
                                                    name="name"
                                                    label="Name"
                                                    placeholder="First Name Last Name"
                                                    type="text"
                                                    errorMessage="Enter Name"
                                                    value={modalName}
                                                    // onChange={(e) => {setName(e.value)}}
                                                    validate={{ required: { value: true } }}
                                                />
                                                <AvField type="select" value={modalRole} name="role" label="Profile" validate={{ required: { value: true } }}>
                                                    <option>Teacher</option>
                                                    <option>Parent</option>
                                                </AvField>
                                                <AvField
                                                    name="email"
                                                    label="E-Mail"
                                                    value={modalEmail}
                                                    placeholder="Enter Valid Email"
                                                    type="email"
                                                    errorMessage="Invalid Email"
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />
                                                <FormGroup className="mb-0">
                                                    <div>
                                                        <Button type="submit" color="primary" className="mr-1">
                                                            Update
                                                        </Button>{" "}
                                                    </div>
                                                </FormGroup>
                                            </AvForm>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </ModalBody>
                            <ModalFooter>
                                <Button type="button" color="secondary" onClick={() => { setEditModal(!editModal); }}>Close</Button>
                            </ModalFooter>
                        </div>
                    </Modal>

                    {confirm_both ? (
                        <SweetAlert
                            title="Are you sure?"
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => {
                                setconfirm_both(false);
                                postDelete()

                            }
                            }
                            onCancel={() => {
                                setconfirm_both(false);
                                setsuccess_dlg(false);
                                setdynamic_title("Cancelled");
                                setdynamic_description("Your imaginary file is safe :)");
                            }
                            }
                        >
                            You won't be able to revert this!
                        </SweetAlert>
                    ) : null}

                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={dynamic_title}
                            onConfirm={() => {
                                props.getAllStudents({ page: current, pageSize: pageSize })
                                setsuccess_dlg(false);
                                setDelData(null);
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {error_dlg ? (
                        <SweetAlert
                            error
                            title={dynamic_title}
                            onConfirm={() => {
                                seterror_dlg(false);
                                setDelData(null);
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { loading, error, modal, editModal, students, studentsCount } = state.StudentReducer;
    return { loading, error, modal, editModal, students, studentsCount };
}

export default withRouter(connect(mapStatetoProps, { createStudent, getAllStudents, updateStudent })(withNamespaces()(Students)));
