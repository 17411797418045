export const REGISTER_STUDENT = 'REGISTER_STUDENT';
export const REGISTER_STUDENT_SUCCESS = 'REGISTER_STUDENT_SUCCESS';
export const REGISTER_STUDENT_FAILED = 'REGISTER_STUDENT_FAILED';
export const GET_REGISTERED_LIST = "GET_REGISTERED_LIST";
export const GET_REGISTERED_LIST_SUCCESS = "GET_REGISTERED_LIST_SUCCESS";
export const GET_REGISTERED_LIST_FAILED = "GET_REGISTERED_LIST_FAILED";
export const UPDATE_REGISTRATION = "UPDATE_REGISTRATION";
export const UPDATE_REGISTRATION_SUCCESS = "UPDATE_REGISTRATION_SUCCESS";
export const UPDATE_REGISTRATION_FAILED = "UPDATE_REGISTRATION_FAILED";
export const DELETE_ONE_REGISTRATION = "DELETE_ONE_REGISTRATION";
