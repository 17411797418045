import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";

import { postJwtForgetPwd } from '../../../apiHelpers/authHelper';

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtForgetPwd, '/api/v1/auth/password', { email: user.email, redirect_url: 'https://beauport.maplebeez.ca/reset-password' });
    if (response) {
      yield put(
        userForgetPasswordSuccess(
          "No worries. We have just sent an email with a reset link to your email address :)"
        )
      )
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
