import toastr from 'toastr'

const Error = () => {
    toastr.error("Your session has been expired. Please login again!", "Error")
    setTimeout(() => {
        localStorage.removeItem("authUser");
        localStorage.removeItem("authHeaders");
        localStorage.removeItem("locale")
        window.location.href = '/login';
    }, 3000)
}

export default Error 