import React from "react";
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// actions
import { resetPwd, resetPwdError } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

const Login = (props) => {
  // handleValidSubmit
  function handleValidSubmit(event, values) {
    if (values.password.length < 6) {
      toastr.warning("Your password is less than 6 characters", "Warning");
      return false;
    }
    props.resetPwd(values, props.history);
  }
  return (
    <React.Fragment>
      <div className="account-pages pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Montessori de Beauport</h5>
                        <p>Reset Password</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      {props.error && props.error ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Password"
                          value=""
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="form-group">
                        <AvField
                          name="password_confirmation"
                          label="Confirmation Password"
                          value=""
                          type="password"
                          required
                          placeholder="Confirm Password"
                        />
                      </div>

                      <div className="mt-3">
                        {props.loading ? (
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light disabled"
                            type="button"
                          >
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                              Sending...
                          </button>
                        ) : (
                            <button
                              className="btn btn-primary btn-block waves-effect waves-light"
                              type="submit"
                            >
                              Reset
                            </button>
                          )}
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-1 text-center">
                <p>© {new Date().getFullYear()} Montessori de Beauport</p>
                <p>With <i className="mdi mdi-heart text-danger"></i>, Maplebeez!</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error, loading } = state.ResetPassword;
  return { error, loading };
};

export default withRouter(
  connect(mapStatetoProps, { resetPwd, resetPwdError })(Login)
);
