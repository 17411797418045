import React, {useState, useEffect, Fragment} from 'react';
import {Container, Row, Col, Button, Card, CardBody, FormGroup} from "reactstrap";
import {withRouter} from "react-router-dom";
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {connect} from 'react-redux';
import {getStudentsList, getGroupList} from '../../store/actions';
//i18n
import {withNamespaces} from 'react-i18next';
import {AvForm, AvField} from "availity-reactstrap-validation";
import {findOrCreateReport, saveReportActivity, saveComment, releaseNote} from "../../apiHelpers/schoolReportApiHelper";
import {paramPost} from "../../apiHelpers/commonAPi";
import toastr from "toastr";
import axios from "axios";
import {callStudents} from "../../apiHelpers/activityApiHelper";
import SweetAlert from "react-bootstrap-sweetalert";
import {Checkbox, FormControlLabel, Typography} from "@material-ui/core";

const ActivityDetail = (props) => {


    useEffect(() => {
        props.getStudentsList()
        props.getGroupList()
    }, [])

    //sweet alert settings
    const [confirmBoth, setConfirmBoth] = useState(false);
    const [successDlg, setSuccessDlg] = useState(false);
    const [errorDlg, setErrorDlg] = useState(false);
    const [dynamicTitle, setDynamicTitle] = useState("");
    const [dynamicDescription, setDynamicDescription] = useState("");

    const [allActivities, setAllActivities] = useState(undefined)
    const [report, setReport] = useState(undefined)
    const [isSendEmail, setIsSendEmail] = useState(true);

    const [firstComment, setFirstComment] = useState(undefined)
    const [secondComment, setSecondComment] = useState(undefined)
    const [thirdComment, setThirdComment] = useState(undefined)

    const [findingState, setFindingState] = useState(false)

    const [saveState1, setSaveState1] = useState(false)
    const [releaseState1, setReleaseState1] = useState(false)

    const [saveState2, setSaveState2] = useState(false)
    const [releaseState2, setReleaseState2] = useState(false)

    const [saveState3, setSaveState3] = useState(false)
    const [releaseState3, setReleaseState3] = useState(false)

    const [selectedStudent, setSelectedStudent] = useState("Select Student")
    const [students, setStudents] = useState([])
    const [printing, setPrinting] = useState(false)
    const [reportId, setReportId] = useState(undefined)

    const [releaseData, setReleaseData] = useState(null);
    const [seasonReport, setSeasonReport] = useState(null);

    const handleFindActivity = (event, values) => {
        event.persist()
        if (values.grade === "Select Year Duration") {
            toastr.info("Please select a Year Duration", "Info")
            return false
        }

        if (values.student === "Select Student") {
            toastr.info("Please select a student", "Info")
            return false
        }
        setReport(undefined)
        setAllActivities(undefined)
        setFindingState(true)
        findOrCreateReport(values)
            .then((result) => {
                setFindingState(false)
                if (result.status === 200) {
                    setAllActivities(JSON.parse(result.data.activities))
                    setReportId(JSON.parse(result.data.report).id);
                    setReport(JSON.parse(result.data.report))
                    let report = JSON.parse(result.data.report)
                    setFirstComment(report.first_t_comment && report.first_t_comment)
                    setSecondComment(report.second_t_comment && report.second_t_comment)
                    setThirdComment(report.third_t_comment && report.third_t_comment)
                } else {
                    toastr.error(result.data.error, "Error")
                }
            })
            .catch((error) => {
                setFindingState(false)
                toastr.error("Something went wrong!", "Error")
            })
    }

    const grades = () => {
        const today = new Date()
        const thisYear = today.getFullYear()
        const firstDayOfSep = new Date(thisYear, 8, 1);
        const lastYear = thisYear - 1
        const nextYear = thisYear + 1
        return (
            <Fragment>
                <option disabled={true}>Select Year Duration</option>
                {today < firstDayOfSep && <option>{`${lastYear}-${thisYear}`}</option>}
                <option>{`${thisYear}-${nextYear}`}</option>
            </Fragment>
        )
    }

    const selectOptions = () => {
        const options = ["Select", 1, 2, 3, 4, 5, "-"]
        return options.map((item, index) => {
            return (
                <option key={index} value={item} disabled={index === 0 && true}>
                    {item}
                </option>
            )
        })
    }


    const reportItems = () => {
        if (allActivities) {
            return allActivities.map((item, index) => {
                return (
                    <Fragment key={index}>
                        <tr style={{backgroundColor: "#CCCCCC"}}>
                            <td colSpan={4}>
                                <strong>
                                    <h4 style={{marginBottom: "unset"}}>
                                        {item.title}
                                    </h4>
                                </strong>
                            </td>
                        </tr>
                        {reportActivities(item.report_activities)}
                    </Fragment>
                )
            })
        }
    }

    const reportActivities = (activities) => {
        if (activities) {
            return activities.map((item, index) => {
                return (
                    <Fragment key={index}>
                        <tr style={{backgroundColor: "#C9DAF8"}}>
                            <td colSpan={4} style={{textAlign: "left"}}>
                                <h5 style={{marginBottom: "unset"}}>
                                    {item.name}
                                </h5>
                            </td>
                        </tr>
                        {reportSubActivities(item.report_sub_activities)}
                    </Fragment>
                )
            })
        }
    }

    const reportSubActivities = (subs) => {
        if (subs) {
            return subs.map((item, index) => {
                return (
                    <Fragment key={index}>
                        <tr>
                            <td style={{textAlign: "left"}}>
                                {item.name}
                            </td>
                            <td>
                                <select
                                    defaultValue={item.report_subs[0].first_result ? item.report_subs[0].first_result : "Select"}
                                    className="form-control" onChange={(e) => handleTermOne(e, item.report_subs[0].id)}>
                                    {selectOptions()}
                                </select>
                            </td>
                            <td>
                                <select disabled={report && (report.is_first_release ? false : true)}
                                        defaultValue={item.report_subs[0].second_result ? item.report_subs[0].second_result : "Select"}
                                        className="form-control"
                                        onChange={(e) => handleTermTwo(e, item.report_subs[0].id)}>
                                    {selectOptions()}
                                </select>
                            </td>
                            <td>
                                <select disabled={report && (report.is_second_release ? false : true)}
                                        defaultValue={item.report_subs[0].third_result ? item.report_subs[0].third_result : "Select"}
                                        className="form-control"
                                        onChange={(e) => handleTermThree(e, item.report_subs[0].id)}>
                                    {selectOptions()}
                                </select>
                            </td>
                        </tr>
                    </Fragment>
                )
            })
        }
    }

    const handleTermOne = (e, id) => {
        const params = {sub_id: id, value: e.target.value, type: 1}
        activitySave(params)
    }
    const handleTermTwo = (e, id) => {
        const params = {sub_id: id, value: e.target.value, type: 2}
        activitySave(params)
    }
    const handleTermThree = (e, id) => {
        const params = {sub_id: id, value: e.target.value, type: 3}
        activitySave(params)
    }

    const activitySave = (params) => {
        saveReportActivity(params)
            .then((result) => {
                if (result.status === 200) {
                    toastr.success(result.data.success, "Success")
                } else {
                    toastr.error(result.data.error, "Error")
                }
            })
            .catch((error) => {
                toastr.error("Something went wrong!", "Error")
            })
    }

    const handleFirstComment = () => {
        if (!firstComment) {
            toastr.info("Please input your comments", "Info")
            return false
        }
        const params = {comment: firstComment, report_id: report.id, type: 1}
        setSaveState1(true)
        commentSave(params)
    }

    const handleSecondComment = () => {
        if (!secondComment) {
            toastr.info("Please input your comments", "Info")
            return false
        }
        const params = {comment: secondComment, report_id: report.id, type: 2}
        setSaveState2(true)
        commentSave(params)
    }

    const handleThirdComment = () => {
        if (!thirdComment) {
            toastr.info("Please input your comments", "Info")
            return false
        }
        const params = {comment: thirdComment, report_id: report.id, type: 3}
        setSaveState3(true)
        commentSave(params)
    }

    const clearSaveState = () => {
        setSaveState1(false)
        setSaveState2(false)
        setSaveState3(false)
    }

    const commentSave = (params) => {
        saveComment(params)
            .then((result) => {
                clearSaveState()
                if (result.status === 200) {
                    setReport(JSON.parse(result.data.report))
                    toastr.success(result.data.success, "Success")

                } else {
                    toastr.error(result.data.error, "Error")
                }
            })
            .catch((error) => {
                clearSaveState()
                toastr.error("Something went wrong!", "Error")
            })
    }


    const confirmRelease = () => {
        if(releaseData.type === 1) {
            setReleaseState1(true);
        } else if(releaseData.type === 2) {
            setReleaseState2(true)
        } else {
            setReleaseState3(true)
        }
        submitRelease(releaseData);
    }

    const handleFirstRelease = () => {
        setIsSendEmail(true);
        const params = {type: 1, report_id: report.id}
        setReleaseData(params)
        setConfirmBoth(true)
    }

    const handleSecondRelease = () => {
        setIsSendEmail(true);
        const params = {type: 2, report_id: report.id}
        setReleaseData(params)
        setConfirmBoth(true)
    }

    const handleThirdRelease = () => {
        setIsSendEmail(true);
        const params = {type: 3, report_id: report.id}
        setReleaseData(params)
        setConfirmBoth(true)
    }

    const handleSendingEmail = (season) => {
        setSeasonReport(season);
        const params = {type: season, report_id: report.id};
        paramPost("/api/v1/send_release_email", params)
            .then((result) => {
                setSeasonReport(null)
                if (result.status === 200) {
                    toastr.success(result.data.success, "Success")
                } else {
                    toastr.error(result.data.error, "Error")
                }
            })
            .catch((error) => {
                setSeasonReport(null)
                toastr.error("Something went wrong!", "Error")
            })
    }

    const clearReleaseState = () => {
        setReleaseState1(false)
        setReleaseState2(false)
        setReleaseState3(false)
    }

    const submitRelease = (params) => {
        params.isSend = isSendEmail;
        releaseNote(params)
            .then((result) => {
                clearReleaseState()
                if (result.status === 200) {
                    setReport(JSON.parse(result.data.report))
                    toastr.success(result.data.success, "Success")

                } else {
                    toastr.error(result.data.error, "Error")
                }
            })
            .catch((error) => {
                clearReleaseState()
                toastr.error("Something went wrong!", "Error")
            })
    }

    const options = () => {
        if (props.groups) {
            return props.groups.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.name}
                    </option>
                )
            })
        }
    }

    const activityOptions = () => {
        if (students.length > 0) {
            return students.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.firstName + " " + item.lastName}
                    </option>
                )
            })
        }
    }

    const getStudent = (e) => {
        setStudents([])
        // clear()
        callStudents({group_id: e.target.value})
            .then((result) => {
                if (result.status === 200) {
                    setStudents(JSON.parse(result.data.students))
                    setSelectedStudent(JSON.parse(result.data.students)[0].id);
                } else {
                    toastr.warning(result.data.message, "Warning")
                }
            })
            .catch((error) => {
                toastr.error("Something went wrong!", "Error")
            })
    }

    const generateReport = () => {
        if (reportId) {
            setPrinting(true);
            const headers = JSON.parse(localStorage.getItem("authHeaders"))
            const config = {
                method: 'post',
                url: `/api/v1/generate_pdf`,
                headers: {
                    accesstoken: headers.accesstoken,
                    client: headers.client,
                    uid: headers.uid,
                },
                responseType: 'blob',
                data: {report_id: reportId}
            }
            axios
                .request(config)
                .then(response => {
                    setPrinting(false);
                    const blob = URL.createObjectURL(response.data);
                    window.open(blob, '_blank');
                })
                .catch((err) => {
                    setPrinting(false);
                    toastr.error("Something went wrong!", "Error")
                });
        }
    }

    const handleEmailSenderChange = (event) => {
        setIsSendEmail(event.target.checked);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('School Report - Detail')} breadcrumbItem={props.t('Activity')}/>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <AvForm className="outer-repeater row m-auto" style={{width: "100%"}}
                                                onValidSubmit={(e, v) => {
                                                    handleFindActivity(e, v)
                                                }}>
                                            <Col xs={12} sm={6} md={3}>
                                                <div className="form-group">
                                                    <AvField type="select" value={"Select Year Duration"} name="grade"
                                                             validate={{required: {value: true}}}>
                                                        {grades()}
                                                    </AvField>
                                                </div>
                                            </Col>

                                            <Col xs={12} sm={6} md={3}>
                                                <div className="form-group">
                                                    <AvField
                                                        type="select"
                                                        value={"Select Group"}
                                                        name="group"
                                                        validate={{required: {value: true}}}
                                                        onChange={getStudent}
                                                    >
                                                        <option disabled={true}>Select Group</option>
                                                        {options()}
                                                    </AvField>
                                                </div>
                                            </Col>

                                            <Col xs={12} sm={6} md={3}>
                                                <div className="form-group">
                                                    <AvField
                                                        type="select"
                                                        // value={"Select Student"}
                                                        value={selectedStudent}
                                                        name="student"
                                                        validate={{required: {value: true}}}
                                                        onChange={(e) => {
                                                            let index = e.nativeEvent.target.selectedIndex;
                                                            let text = e.nativeEvent.target[index].text;
                                                        }}
                                                        // disabled={groupUpload}
                                                    >
                                                        <option disabled={true}>Select Student</option>
                                                        {activityOptions()}
                                                    </AvField>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6} md={3}>
                                                <FormGroup className="mb-0">
                                                    <div>
                                                        <Button type="submit" color="primary" className="mr-1"
                                                                style={{width: "100%"}} disabled={findingState}>
                                                            {findingState ? (
                                                                <React.Fragment>
                                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                    Finding
                                                                </React.Fragment>
                                                            ) : "Find"}
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </AvForm>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={9} className="float-right report-guide">
                                            <p className="comment p-2"
                                               style={{float: "left", background: "#e1e1e1", color: "black"}}>
                                                Légende: <br/>
                                                1: Non-observé à ce jour 2: À travailler 3: Progrès 4: Évolution
                                                continue 5: Bonne maitrise
                                            </p>
                                        </Col>
                                        <Col xs={12} sm={3} className="report-print-btn">
                                            {!printing ? (
                                                <button type="button"
                                                        className="mt-sm-1 float-right btn btn-success waves-effect waves-light w-sm"
                                                        disabled={!report} onClick={generateReport}>
                                                    <i className="fa fa-print d-block font-size-16"></i>
                                                    Print
                                                </button>) : (
                                                <button type="button"
                                                        className="mt-sm-1 float-right btn btn-success waves-effect waves-light w-sm"
                                                        disabled={true}>
                                                    <i className="fa fa-print d-block font-size-16"></i>
                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                    Generating Report...
                                                </button>)}
                                        </Col>
                                    </Row>
                                    <Row className="mt-2" style={{padding: "0px 10px"}}>
                                        <Table id="tech-companies-1" className="table table-striped table-bordered">
                                            <Thead>
                                                <Tr style={{backgroundColor: "#3B78D8", color: "white"}}>
                                                    <Th data-priority="1" style={{width: "40%"}}>Étapes</Th>
                                                    <Th data-priority="1">1</Th>
                                                    <Th data-priority="1">2</Th>
                                                    <Th data-priority="1">3</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {reportItems()}
                                            </Tbody>
                                        </Table>

                                        {report && (
                                            <Fragment>
                                                <Table id="tech-companies-1"
                                                       className="table table-striped table-bordered">
                                                    <Thead>
                                                        <Tr style={{backgroundColor: "#D9D9D9"}}>
                                                            <Th className="text-left" data-priority="1" colSpan={2}>
                                                                Release 1 notes
                                                            </Th>
                                                            <Th data-priority="1">Actions</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <tr>
                                                            <td style={{backgroundColor: "#D9D9D9"}}><b>Teachers</b>
                                                            </td>
                                                            <td style={{backgroundColor: "#D9D9D9"}}>
                                                            <textarea defaultValue={report.first_t_comment}
                                                                      onChange={(e) => setFirstComment(e.target.value)}
                                                                      className="form-control" name="" id="" cols="30"
                                                                      rows="3">
                                                            </textarea>
                                                            </td>
                                                            <td style={{backgroundColor: "#D9D9D9"}}>
                                                                <button
                                                                    className="btn btn-primary mr-1"
                                                                    onClick={handleFirstComment}
                                                                    disabled={saveState1}
                                                                >
                                                                    {report.first_t_comment ?
                                                                        saveState1 ? (
                                                                            <React.Fragment>
                                                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                                Updating
                                                                            </React.Fragment>
                                                                        ) : "Update" :
                                                                        saveState1 ? (
                                                                            <React.Fragment>
                                                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                                Saving
                                                                            </React.Fragment>
                                                                        ) : "Save"
                                                                    }
                                                                </button>
                                                                <button
                                                                    className="btn btn-success"
                                                                    onClick={handleFirstRelease}
                                                                    disabled={report.is_first_release || releaseState1 ? true : false}
                                                                >
                                                                    {releaseState1 ? (
                                                                        <React.Fragment>
                                                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                            Releasing
                                                                        </React.Fragment>
                                                                    ) : "Release"}
                                                                </button>
                                                                <button
                                                                    className="btn btn-outline-danger ml-1"
                                                                    onClick={() => handleSendingEmail(1)}
                                                                    disabled={!report.is_first_release || seasonReport === 1 ? true : false}
                                                                >
                                                                    <span className="danger">
                                                                        {seasonReport === 1 ? "Sending report" : "Send report"}
                                                                    </span>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{backgroundColor: "#D9D9D9"}}><b>Parents</b></td>
                                                            <td colSpan={2} className="text-left"
                                                                style={{backgroundColor: "#CFE2F3"}}>
                                                                {report.first_p_comment}
                                                            </td>
                                                        </tr>
                                                    </Tbody>
                                                </Table>

                                                <Table id="tech-companies-1"
                                                       className="table table-striped table-bordered">
                                                    <Thead>
                                                        <Tr style={{backgroundColor: "#D9D9D9"}}>
                                                            <Th className="text-left" data-priority="1" colSpan={2}>Release
                                                                2 notes</Th>
                                                            <Th data-priority="1">Actions</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <tr>
                                                            <td style={{backgroundColor: "#D9D9D9"}}><b>Teachers</b>
                                                            </td>
                                                            <td style={{backgroundColor: "#D9D9D9"}}>
                                                            <textarea defaultValue={report.second_t_comment}
                                                                      onChange={(e) => setSecondComment(e.target.value)}
                                                                      className="form-control" name="" id="" cols="30"
                                                                      rows="3">
                                                            </textarea>
                                                            </td>
                                                            <td style={{backgroundColor: "#D9D9D9"}}>
                                                                <button
                                                                    className="btn btn-primary mr-1"
                                                                    disabled={report.is_first_release && !saveState2 ? false : true}
                                                                    onClick={handleSecondComment}
                                                                >
                                                                    {report.second_t_comment ?
                                                                        saveState2 ? (
                                                                            <React.Fragment>
                                                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                                Updating
                                                                            </React.Fragment>
                                                                        ) : "Update" :
                                                                        saveState2 ? (
                                                                            <React.Fragment>
                                                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                                Saving
                                                                            </React.Fragment>
                                                                        ) : "Save"
                                                                    }
                                                                </button>
                                                                <button
                                                                    onClick={handleSecondRelease}
                                                                    className="btn btn-success"
                                                                    disabled={report.is_first_release && !report.is_second_release && !releaseState2 ? false : true}
                                                                >
                                                                    {releaseState2 ? (
                                                                        <React.Fragment>
                                                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                            Releasing
                                                                        </React.Fragment>
                                                                    ) : "Release"}
                                                                </button>
                                                                <button
                                                                    className="btn btn-outline-danger ml-1"
                                                                    onClick={() => handleSendingEmail(2)}
                                                                    disabled={!report.is_second_release || seasonReport === 2 ? true : false}
                                                                >
                                                                    <span className="danger">
                                                                        {seasonReport === 2 ? "Sending report" : "Send report"}
                                                                    </span>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{backgroundColor: "#D9D9D9"}}><b>Parents</b></td>
                                                            <td colSpan={2} className="text-left"
                                                                style={{backgroundColor: "#CFE2F3"}}>
                                                                {report.second_p_comment}
                                                            </td>
                                                        </tr>
                                                    </Tbody>
                                                </Table>

                                                <Table id="tech-companies-1"
                                                       className="table table-striped table-bordered">
                                                    <Thead>
                                                        <Tr style={{backgroundColor: "#D9D9D9"}}>
                                                            <Th className="text-left" data-priority="1" colSpan={2}>Release
                                                                3 notes</Th>
                                                            <Th data-priority="1">Actions</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <tr>
                                                            <td style={{backgroundColor: "#D9D9D9"}}><b>Teachers</b>
                                                            </td>
                                                            <td style={{backgroundColor: "#D9D9D9"}}>
                                                            <textarea defaultValue={report.third_t_comment}
                                                                      onChange={(e) => setThirdComment(e.target.value)}
                                                                      className="form-control" name="" id="" cols="30"
                                                                      rows="3">
                                                            </textarea>
                                                            </td>
                                                            <td style={{backgroundColor: "#D9D9D9"}}>
                                                                <button
                                                                    className="btn btn-primary mr-1"
                                                                    disabled={report.is_second_release && !saveState3 ? false : true}
                                                                    onClick={handleThirdComment}
                                                                >
                                                                    {report.third_t_comment ?
                                                                        saveState3 ? (
                                                                            <React.Fragment>
                                                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                                Updating
                                                                            </React.Fragment>
                                                                        ) : "Update" :
                                                                        saveState3 ? (
                                                                            <React.Fragment>
                                                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                                Saving
                                                                            </React.Fragment>
                                                                        ) : "Save"
                                                                    }
                                                                </button>
                                                                <button
                                                                    onClick={handleThirdRelease}
                                                                    className="btn btn-success"
                                                                    disabled={report.is_second_release && !report.is_third_release && !releaseState3 ? false : true}
                                                                >
                                                                    {releaseState3 ? (
                                                                        <React.Fragment>
                                                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                            Releasing
                                                                        </React.Fragment>
                                                                    ) : "Release"}
                                                                </button>
                                                                <button
                                                                    className="btn btn-outline-danger ml-1"
                                                                    onClick={() => handleSendingEmail(3)}
                                                                    disabled={!report.is_third_release || seasonReport === 3 ? true : false}
                                                                >
                                                                    <span className="danger">
                                                                        {seasonReport === 3 ? "Sending report" : "Send report"}
                                                                    </span>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{backgroundColor: "#D9D9D9"}}><b>Parents</b></td>
                                                            <td colSpan={2} className="text-left"
                                                                style={{backgroundColor: "#CFE2F3"}}>
                                                                {report.third_p_comment}
                                                            </td>
                                                        </tr>
                                                    </Tbody>
                                                </Table>
                                            </Fragment>
                                        )}
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {confirmBoth ? (
                        <SweetAlert
                            title="Are you sure?"
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => {
                                setConfirmBoth(false);
                                confirmRelease();
                            }
                            }
                            onCancel={() => {
                                setConfirmBoth(false);
                                setSuccessDlg(false);
                                setDynamicTitle("Cancelled");
                            }
                            }
                        >
                            <p>One email is about to be sent to parents regarding this release.</p>
                            <FormControlLabel
                                control={<Checkbox checked={isSendEmail} onChange={handleEmailSenderChange}
                                                   name="isSend"/>}
                                label={
                                    <Typography style={{fontSize: '13px'}}>
                                        Check if you want to send this report to parent's email
                                    </Typography>}/>
                        </SweetAlert>
                    ) : null}

                    {successDlg ? (
                        <SweetAlert
                            success
                            title={dynamicTitle}
                            onConfirm={() => {
                                setSuccessDlg(false);
                            }}
                        >
                            {dynamicDescription}
                        </SweetAlert>
                    ) : null}

                    {errorDlg ? (
                        <SweetAlert
                            error
                            title={dynamicTitle}
                            onConfirm={() => {
                                setErrorDlg(false);
                            }}
                        >
                            {dynamicDescription}
                        </SweetAlert>
                    ) : null}

                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    const {students} = state.ReportAttReducer;
    const {groups} = state.ActivityReducer;
    return {students, groups};
}

export default withRouter(connect(mapStateToProps,
    {getStudentsList, getGroupList}
)(withNamespaces()(ActivityDetail)));
