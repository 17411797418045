import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { GET_DASHBOARD_ACTIVITIES } from './actionTypes';
import {
    getDashboardActivitiesSuccess,
    getDashboardActivitiesFailed
} from './actions';
import {noParamGet} from '../../../apiHelpers/commonAPi';
import toastr from 'toastr';

function* getDashboardData() {
    try {
        const response = yield call(noParamGet, '/api/v1/dashboard_activities');
        if(response.status === 200) {
            yield put(getDashboardActivitiesSuccess(response.data.activities));
        } else {
            yield put(getDashboardActivitiesFailed());
            // toastr.error("Something went wrong","Error")
        }
    } catch (error) {
        yield put(getDashboardActivitiesFailed());
    }
}

export function* watchGetDashboardData() {
    yield takeEvery(GET_DASHBOARD_ACTIVITIES, getDashboardData)
}

function* dashboardSaga() {
    yield all([
        fork(watchGetDashboardData)
    ]);
}

export default dashboardSaga;
