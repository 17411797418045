import { combineReducers } from "redux";

import Layout from "./layout/reducer";

import Login from "./auth/login/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import ResetPassword from "./auth/resetPwd/reducer";
import UserReducer from "./contents/user/reducer";
import StudentReducer from "./contents/students/reducer";
import StudentDetailReducer from "./contents/studentDetail/reducer";
import GroupReducer from "./contents/group/reducer";
import ActivityReducer from "./contents/activity/reducer";
import ActivityListReducer from "./contents/activity_list/reducer";
import AttendanceReducer from "./contents/attendance/reducer";
import ReportAttReducer from "./contents/report/reducer";
import SchoolReportReducer from "./contents/school_report/reducer";
import SchoolReportListReducer from "./contents/report_items/reducer";
import NotificationReducer from "./contents/notification/reducer";
import DashboardReducer from "./contents/dashboard/reducer";
import WaitingListReducer from "./contents/waiting_list/reducer";
import ContactReducer from "./contents/contact/reducer";
import StudentRegisterReducer from "./contents/registerForm/reducer";

const rootReducer = combineReducers({
    Layout,
    Login,
    ForgetPassword,
    ResetPassword,
    UserReducer,
    StudentReducer,
    StudentDetailReducer,
    GroupReducer,
    ActivityReducer,
    ActivityListReducer,
    AttendanceReducer,
    ReportAttReducer,
    SchoolReportReducer,
    SchoolReportListReducer,
    NotificationReducer,
    DashboardReducer,
    WaitingListReducer,
    ContactReducer,
    StudentRegisterReducer
});

export default rootReducer;
