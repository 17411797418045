import {
    GET_CONTACTS,
    GET_CONTACTS_SUCCESS,
    UPDATE_CONTACTS,
    UPDATE_CONTACTS_SUCCESS,
    UPDATE_CONTACTS_FAILED,
    DELETE_ONE_CONTACT
} from './actionTypes';

export const deleteOneContact = (index) => {
    return {
        type: DELETE_ONE_CONTACT,
        payload: index
    }
}

export const getContacts = (page) => {
    return {
        type: GET_CONTACTS,
        payload: page
    }
}

export const getContactsSuccess = (contacts, total) => {
    return {
        type: GET_CONTACTS_SUCCESS,
        payload: contacts,
        total: total
    }
}

export const updateContacts = (newContact) => {
    return {
        type: UPDATE_CONTACTS,
        payload: { newContact }
    }
}

export const updateContactsSuccess = (updatedInfo, index) => {
    return {
        type: UPDATE_CONTACTS_SUCCESS,
        payload: updatedInfo,
        index: index
    }
}

export const updateContactsFailed = () => {
    return {
        type: UPDATE_CONTACTS_FAILED
    }
}
