import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, CardTitle, FormGroup, Label } from "reactstrap";
import { Link, Redirect, withRouter } from "react-router-dom";
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';
import Avatar from '../../assets/images/avatar.png';
import { Pagination } from 'antd';
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { connect } from 'react-redux';


import { getAllGroups, updateGroup, createGroup } from '../../store/actions';
import Select from "react-select";
//i18n
import { withNamespaces } from 'react-i18next';
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import {addTeacherToGroup, addStudentToGroup} from "../../apiHelpers/groupApiHelper";
import toastr from "toastr";
import axios from "axios";




const Student = (props) => {

    const [modal, setmodal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedGroup, setselectedGroup] = useState(null);
    const [current, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10)

    const [groupName, setGroupName] = useState('')
    const [modalEmail, setEmail] = useState('')
    const [modalRole, setRole] = useState('')
    const [id, setId] = useState('cool')
    const [index, setIndex] = useState('index')

    const [stepCurrent, setStepCurrent] = useState(0)

    const [isCreate, setIsCreate] = useState(null)
    //sweet alert settings
    const [confirm_both, setconfirm_both] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [error_dlg, seterror_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [delData, setDelData] = useState(null)

    const [multiTeachers, setMultiTeachers] = useState(null)
    const [multiStudents, setMultiStudents] = useState(null)
    const [teacherIds, setTeacherIds] = useState([])
    const [studentIds, setStudentIds] = useState([])

    const [tAssignState, setTAssignState] = useState(false)
    const [sAssignState, setSAssignState] = useState(false)




    useEffect(() => {
        props.getAllGroups({page: current, pageSize: pageSize})
    }, [])


    useEffect(() => {
        if(props.modal === "success" && isCreate) {
            setmodal(false)
            setGroupName('')
        }
    }, [props.modal])

    useEffect(() => {
        if(props.editModal === "success" && !isCreate){
            setmodal(false)
            setGroupName('')
        }
    }, [props.editModal])




    function onShowSizeChange(current, pageSize) {
        props.getAllGroups({page: current, pageSize: pageSize})
        setPageSize(pageSize)
        setCurrentPage(current)
    }
    const onChange = (page) => {
        props.getAllGroups({page: page, pageSize: pageSize})
        setCurrentPage(page)
    }




    const postDelete = () => {
        const headers = JSON.parse(localStorage.getItem("authHeaders"))
        const config = {
            method: "delete",
            url: `/api/v1/group/${delData.id}`,
            headers: {
                accesstoken: headers.accesstoken,
                client: headers.client,
                uid: headers.uid,
            }
        }
        return axios(config).then(response => {
            if (response.status === 400 || response.status === 500)
                throw response.data;
            setsuccess_dlg(true);
            setdynamic_title("Deleted");
            setdynamic_description("Group has been deleted.");
        }).catch(err => {
            seterror_dlg(true)
            throw err[1];
        });
    }

    const handleAddGroup = (event, values) => {
        event.persist();
        if (isCreate) {
            props.createGroup(values)
        } else {
            const params = {
                ...values,
                id: id,
                index: index
            }
            props.updateGroup(params)
        }

    }

    const  handleUpdate = (item, index) => {
        setIndex(index)
        setId(item.id)
        setIsCreate(false)
        setmodal(true)
        setGroupName(item.name)
    }

    const handleDel = (val) => {
        setDelData(val)
        setconfirm_both(true)
    }

    const tcs = (users) => {
        return users.map((user, index) => {
            return (
                <span key={index}>
                    {(index ? ', ': '') + user.name}
                </span>
            )
        })
    }

    const groupList = () => {
        if(props.groups) {
            return props.groups.map((item, index) => {
                return (
                    <Tr key={index}>
                        <Td>{item.name}</Td>
                        <Td>{
                            item.users ? tcs(item.users) : " "}
                        </Td>
                        <Td>
                            {item.total ? item.total : "0"}
                        </Td>
                        <Td>
                            <i className="fas fa-address-card actions" onClick={() => {window.location.href = `/group/${item.id}`}}></i>
                            <i className="fas fa-pencil-alt actions" onClick={() => {handleUpdate(item, index)}}></i>
                            <i className="fas fa-trash-alt actions" onClick={() => handleDel(item)}></i>
                        </Td>

                    </Tr>
                )
            })
        }
    }
    const options = () => {
        if(props.groups) {
            return props.groups.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.name}
                    </option>
                )
            })
        }
    }




    const teachers = () => {
        let teachers = [];
        props.teachers && props.teachers.map((item) => {
            teachers.push({label: item.name, value: item.id})
        })
        return teachers
    }

    const students = () => {
        let students = [];
        props.students && props.students.map((item) => {
            students.push({label: item.firstName + " " + item.lastName, value: item.id})
        })
        return students
    }

    const handleMultiTeacher = (multiTeachers) => {
        setMultiTeachers(multiTeachers)
        let ids = []
        multiTeachers.map((item) => {
            ids.push(item.value)
        })
        setTeacherIds(ids)
    }


    const handleMultiStudent = (multiStudents) => {
        setMultiStudents(multiStudents)
        let ids = []
        multiStudents.map((item) => {
            ids.push(item.value)
        })
        setStudentIds(ids)
    }

    const handleSetTeachers = (event, values) => {
        event.persist()
        if(values.group_id === "Select") {
            toastr.info("Please select one group", "Info")
            return false
        }
        if(teacherIds.length === 0) {
            toastr.info("Please select teacher(s)", "Info")
            return false
        }
        setTAssignState(true)
        addTeacherToGroup({group_id: values.group_id, teacher_ids: teacherIds})
            .then(res => {
                setTAssignState(false)
                if(res.status === 200) {
                 toastr.success(res.data.message, "Success");
                 setMultiTeachers(null)
                 setTeacherIds([])
                 props.getAllGroups({page: current, pageSize: pageSize})
                } else {
                    toastr.error("Failed to register in group", "Error")
                }
            })
            .catch(error => {
                setTAssignState(false)
                toastr.error(error.message)
            })
    }


    const handleSetStudents = (event, values) => {
        event.persist()
        if(values.group_id === "Select") {
            toastr.info("Please select one group", "Info")
            return false
        }
        if(studentIds.length === 0) {
            toastr.info("Please select student(s)", "Info")
            return false
        }
        setSAssignState(true)
        addStudentToGroup({group_id: values.group_id, student_ids: studentIds})
            .then(res => {
                setSAssignState(false)
                if(res.status === 200) {
                    toastr.success(res.data.message, "Success");
                    setMultiStudents(null)
                    setStudentIds([])
                    props.getAllGroups({page: current, pageSize: pageSize})
                } else {
                    toastr.error("Failed to register in group", "Error")
                }
            })
            .catch(error => {
                setSAssignState(false)
                toastr.error(error.message)
            })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('Group')} breadcrumbItem={props.t('Group')} />

                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Button type="button" color="info" className="mb-3 float-md-left" onClick={()=> {setmodal(!modal); setIsCreate(true)}}>
                                                <span style={{fontSize: "15px"}}>Add Group</span>
                                            </Button>
                                            <div className="table-rep-plugin">
                                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                    <Table id="tech-companies-1" className="table table-striped table-bordered">
                                                        <Thead>
                                                            <Tr>
                                                                <Th data-priority="1">Name</Th>
                                                                <Th data-priority="1">Teacher</Th>
                                                                <Th data-priority="1">Students</Th>
                                                                <Th data-priority="1">Actions</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {groupList()}
                                                        </Tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                            <Pagination className="float-right" current={current} defaultPageSize={10} onShowSizeChange={onShowSizeChange} onChange={onChange} total={10} />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <p><strong>Assign a group to teacher</strong></p>
                                            <AvForm onValidSubmit={(e,v) => { handleSetTeachers(e, v) }}>
                                                <FormGroup>
                                                    <Label>{props.t('Select Group')}</Label>
                                                    <AvField type="select" value='Select' name="group_id" validate={{ required: { value: true } }}>
                                                        <option disabled={true}>Select</option>
                                                        {options()}
                                                    </AvField>
                                                </FormGroup>
                                                <FormGroup className="select2-container">
                                                    <label className="control-label">{props.t('Select Teachers')}</label>
                                                    <Select
                                                        isMulti={true}
                                                        options={teachers()}
                                                        closeMenuOnSelect={false}
                                                        classNamePrefix="select2-selection"
                                                        className='teacher-multi-select'
                                                        name="teacher"
                                                        isSearchable={true}
                                                        value={multiTeachers}
                                                        onChange={handleMultiTeacher}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-0">
                                                    <div>
                                                        <Button type="submit" color="primary" className="mr-1 float-right" disabled={tAssignState}>
                                                            {tAssignState ? (
                                                                <React.Fragment>
                                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                    Saving
                                                                </React.Fragment>
                                                            ): "Save"}
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                            </AvForm>
                                            <hr style={{marginTop: '70px'}} />
                                            <p><strong>Add students to a group</strong></p>
                                            <AvForm onValidSubmit={(e,v) => { handleSetStudents(e,v) }}>
                                                <FormGroup>
                                                    <Label>{props.t('Select Group')}</Label>
                                                    <AvField type="select" name="group_id" value={"Select"} validate={{ required: { value: true } }}>
                                                        <option disabled={true}>Select</option>
                                                        {options()}
                                                    </AvField>
                                                </FormGroup>
                                                <FormGroup className="select2-container">
                                                    <label className="control-label">{props.t('Select Children')}</label>
                                                    <Select
                                                        isMulti={true}
                                                        options={students()}
                                                        closeMenuOnSelect={false}
                                                        classNamePrefix="select2-selection"
                                                        className='stu-multi-select'
                                                        name="student"
                                                        isSearchable={true}
                                                        value={multiStudents}
                                                        onChange={handleMultiStudent}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-0">
                                                    <div>
                                                        <Button type="submit" color="primary" className="mr-1 float-right" disabled={sAssignState}>
                                                            {sAssignState ? (
                                                                <React.Fragment>
                                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                    Saving
                                                                </React.Fragment>
                                                            ): "Save"}
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                            </AvForm>
                                        </Col>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <Modal isOpen={modal} role="dialog" autoFocus={true} centered={true} className="myModal" tabIndex="-1" toggle={() => { setmodal(!modal); }}>
                        <div className="modal-content">
                            <ModalHeader toggle={() => { setmodal(!modal); }}>
                                Add Group
                            </ModalHeader >
                            <ModalBody>
                                <Col sm="12">
                                    <Card>
                                        <CardBody>
                                            <AvForm onValidSubmit={(e,v) => { handleAddGroup(e,v) }}>
                                                <AvField
                                                    name="name"
                                                    label="Name"
                                                    placeholder="Group Name"
                                                    type="text"
                                                    errorMessage="Enter Name"
                                                    value={groupName}
                                                    onChange={(e) => {setGroupName(e.target.value)}}
                                                    validate={{ required: { value: true } }}
                                                />
                                                <FormGroup className="mb-0">
                                                    <div>
                                                        {isCreate ? (
                                                        <Button type="submit" color="primary" className="mr-1" disabled={props.loading}>
                                                            {props.loading ? (
                                                                <React.Fragment>
                                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                    Saving
                                                                </React.Fragment>
                                                            ): "Save"}
                                                        </Button>
                                                            ) : (
                                                        <Button type="submit" color="primary" className="mr-1" disabled={props.loading}>
                                                            {props.loading ? (
                                                                <React.Fragment>
                                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                    Updating
                                                                </React.Fragment>
                                                            ): "Update"}
                                                        </Button>
                                                        )}
                                                    </div>
                                                </FormGroup>
                                            </AvForm>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </ModalBody>
                        </div>
                    </Modal>

                    {confirm_both ? (
                        <SweetAlert
                            title="Are you sure?"
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => {
                                setconfirm_both(false);
                                postDelete()

                            }
                            }
                            onCancel={() =>
                            {
                                setconfirm_both(false);
                                setsuccess_dlg(false);
                                setdynamic_title("Cancelled");
                                setdynamic_description("Your imaginary file is safe :)");
                            }
                            }
                        >
                            You won't be able to revert this!
                        </SweetAlert>
                    ) : null}

                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={dynamic_title}
                            onConfirm={() => {
                                props.getAllGroups({page: current,  pageSize: pageSize})
                                setsuccess_dlg(false);
                                setDelData(null);
                            } }
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {error_dlg ? (
                        <SweetAlert
                            error
                            title={dynamic_title}
                            onConfirm={() => {
                                seterror_dlg(false);
                                setDelData(null);
                            } }
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    const { loading, error, modal, editModal, groups, teachers, students } = state.GroupReducer;
    return { loading, error, modal, editModal, groups, teachers, students };
}

export default withRouter(connect(mapStateToProps, { createGroup, updateGroup, getAllGroups })(withNamespaces()(Student)));
