import {
    GET_A_STUDENT,
    GET_A_STUDENT_SUCCESS,
    GET_A_STUDENT_FAILED,
    UPDATE_A_STUDENT,
    UPDATE_A_STUDENT_SUCCESS,
    UPDATE_A_STUDENT_FAILED,
    GET_TEACHERS_FOR_A_STUDENT,
    GET_MEDICAL_DOCS,
    UPLOAD_DOC,
    UPLOAD_DOC_SUCCESS,
    UPLOAD_DOC_FAILED,
    DELETE_DOC,
    DELETE_DOC_SUCCESS,
    DELETE_DOC_FAILED
} from './actionTypes';

const initialState = {
    error: "",
    loading: false,
    student: undefined,
    isModal: undefined,
    teachers: undefined,
    docs: undefined,
    uploading: false,
    deleting: false
}

const student = (state = initialState, action) => {
    switch (action.type) {
        case UPLOAD_DOC:
            state = {
                ...state,
                uploading: true
            };
            break;
        case UPLOAD_DOC_SUCCESS:
            state = {
                ...state,
                uploading: false,
                student: action.payload
            };
            break;
        case UPLOAD_DOC_FAILED:
            state = {
                ...state,
                uploading: false
            };
            break;
        case DELETE_DOC:
            state = {
                ...state,
                deleting: true
            };
            break;
        case DELETE_DOC_SUCCESS:
            state = {
                ...state,
                deleting: false,
                student: action.payload
            };
            break;
        case DELETE_DOC_FAILED:
            state = {
                ...state,
                deleting: false
            };
            break;
        case GET_A_STUDENT:
            state = {
                ...state,
                loading: true
            };
            break;
        case GET_A_STUDENT_SUCCESS:
            state = {
                ...state,
                loading: false,
                student: action.payload
            };
            break;
        case GET_TEACHERS_FOR_A_STUDENT:
            state = {
                ...state,
                teachers: action.payload
            };
            break;
        case GET_MEDICAL_DOCS:
            state = {
                ...state,
                docs: action.payload
            };
            break;
        case GET_A_STUDENT_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload
            };
            break;
        case UPDATE_A_STUDENT:
            state = {
                ...state,
                loading: true,
                isModal: "start"
            };
            break;
        case UPDATE_A_STUDENT_SUCCESS:
            state = {
                ...state,
                loading: false,
                isModal: "success"
            };
            break;
        case UPDATE_A_STUDENT_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload,
                isModal: "failed"
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default student;