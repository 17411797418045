import {
    GET_ATTENDANCES,
    GET_ATTENDANCES_SUCCESS,
    GET_ATTENDANCES_FAILED,
    GET_STUDENTS_LIST,
    GET_STUDENTS_LIST_SUCCESS,
    GET_OLD_STUDENTS_LIST,
    GET_OLD_STUDENTS_LIST_SUCCESS
} from './actionTypes';

export const getAttendances = (duration) => {
    return {
        type: GET_ATTENDANCES,
        payload: duration
    }
}

export const getAttendancesSuccess = (attendances) => {
    return {
        type: GET_ATTENDANCES_SUCCESS,
        payload: attendances
    }
}
export const getAttendancesFailed = (error) => {
    return {
        type: GET_ATTENDANCES_FAILED,
        payload: error
    }
}

export const getStudentsList = () => {
    return {
        type: GET_STUDENTS_LIST
    }
}

export const getStudentsListSuccess = (students) => {
    return {
        type: GET_STUDENTS_LIST_SUCCESS,
        payload: students
    }
}

export const getOldStudentsList = () => {
    return {
        type: GET_OLD_STUDENTS_LIST
    }
}

export const getOldStudentsListSuccess = (students) => {
    return {
        type: GET_OLD_STUDENTS_LIST_SUCCESS,
        payload: students
    }
}




