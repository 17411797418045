import React from 'react';
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";

import Breadcrumbs from '../../components/Common/Breadcrumb';

import { connect } from 'react-redux';


import { getAttendances } from '../../store/actions';
//i18n
import { withNamespaces } from 'react-i18next';
import moment from "moment";
import { DatePicker } from "antd";


const { RangePicker } = DatePicker

const ReportAttendances = (props) => {

    const getDate = (value, dateString) => {
        const params = { start: dateString[0], end: dateString[1] };
        if (value) {
            props.getAttendances(params)
        }
    }

    const tableHeader = () => {
        if (props.attendances && props.attendances.length > 0) {
            return props.attendances[0].attendances.map((item, index) => {
                return (
                    <td key={index}>
                        <p className="font-weight-bold">
                            {moment(item.date).format('MMM Do YY')}
                        </p>
                    </td>
                )
            })
        }
    }

    const tableBody = () => {
        if (props.attendances && props.attendances.length > 0) {
            return props.attendances.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="font-weight-bold fixed" style={{ cursor: "pointer", background: "#3b7ad9" }} onClick={() => goToStudent(item)}>
                            {item.name}
                        </td>
                        {tableBodyItems(item.attendances)}
                    </tr>
                )
            })
        }
    }

    const tableBodyItems = (attendances) => {
        if (attendances.length > 0) {
            return attendances.map((item, index) => {
                return (
                    <td key={index}>{symbol(item.child_present)}</td>
                )
            })
        }
    }

    const goToStudent = (info) => {
        window.location.href = `/students/${info.student_id}`
    }

    const symbol = (item) => {
        switch (item) {
            case "Présent(P)":
                return "P";
            case "Absent(A)":
                return "A"
            case "Maladie(M)":
                return "M"
            case "Vacances(V)":
                return "V"
            case "Férié(F)":
                return "F"
            case "Fermeture du SG(X)":
                return "X"
            case "Demi-Journée(DJ)":
                return "DJ"
            default:
                return ""
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('Report-Attendances')} breadcrumbItem={props.t('Report')} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <span>{props.t('Select start and end date')}:</span>
                                    <div className="mb-3">
                                        <RangePicker onChange={getDate} />
                                    </div>
                                    <Row>
                                        <Col xs={12} className="float-right">
                                            <p className="comment p-2" style={{ float: "left", background: "#3b7ad9", color: "white" }}>
                                                (P)Présent, (A)Absent, (M)Maladie, (V)Vacances, (F)Férié, (X)Fermeture du SG, (DJ)Demi-Journée
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row className="mt-4 overflow-auto report-wrapper search-table-outter">
                                        <table id="tech-companies-1" className="table-striped search-table inner">
                                            <thead className="report-thead">
                                                <tr>
                                                    {props.attendances && (
                                                        <td>
                                                            <p className="font-weight-bold">Child Name</p>
                                                        </td>
                                                    )}
                                                    {tableHeader()}
                                                </tr>
                                            </thead>
                                            <tbody className="report-tbody">
                                                {tableBody()}
                                            </tbody>
                                        </table>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    const { attendances } = state.ReportAttReducer;
    return { attendances };
}

export default withRouter(connect(mapStateToProps, { getAttendances })(withNamespaces()(ReportAttendances)));
