const firebaseConfig = {
    apiKey: "AIzaSyAgmRFP0jIH-6hGALRReJRxW_1Yynzj9uw",
    authDomain: "beauport-firebase-app.firebaseapp.com",
    databaseURL: "https://beauport-firebase-app.firebaseio.com",
    projectId: "beauport-firebase-app",
    storageBucket: "beauport-firebase-app.appspot.com",
    messagingSenderId: "598078648976",
    appId: "1:598078648976:web:e0d40072c28adf6cc9de15"
};

export { firebaseConfig }