import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Pagination } from "antd";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { connect } from "react-redux";
import moment from "moment";
import {
  deleteOneContact,
  getContacts,
  updateContacts,
} from "../../store/actions";
//i18n
import { withNamespaces } from "react-i18next";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
// Form Mask
import InputMask from "react-input-mask";
import axios from "axios";

const ContactList = (props) => {
  const [confirm_both, setconfirm_both] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [error_dlg, seterror_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");

  const [editModal, setEditModal] = useState(false);
  const [current, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  // For editing modal
  const [editingList, setEditingList] = useState(null);
  const [index, setIndex] = useState("index");

  const [delData, setDelData] = useState(null);

  useEffect(() => {
    props.getContacts({ page: 1, pageSize: pageSize });
  }, []);

  useEffect(() => {
    if (props.editModalStatus === 1) {
      setEditModal(false);
    }
  }, [props.editModalStatus]);

  const onChange = (page) => {
    props.getAllUsers({ page: page, pageSize: pageSize });
    setCurrentPage(page);
  };

  function onShowSizeChange(current, pageSize) {
    props.getAllUsers({ page: current, pageSize: pageSize });
    setPageSize(pageSize);
    setCurrentPage(current);
  }

  const handleDel = (val, index) => {
    setDelData(val);
    setIndex(index)
    setconfirm_both(true);
  };

  const postDelete = () => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"));
    const config = {
      method: "delete",
      url: `/api/v1/contacts/${delData.id}`,
      headers: {
        accesstoken: headers.accesstoken,
        client: headers.client,
        uid: headers.uid,
      },
    };
    return axios(config)
      .then((response) => {
        if (response.status === 400 || response.status === 500)
          throw response.data;
        setsuccess_dlg(true);
        setdynamic_title("Deleted");
        setdynamic_description("Waiting list has been deleted.");
      })
      .catch((err) => {
        seterror_dlg(true);
        throw err[1];
      });
  };

  const handleEdit = (val, index) => {
    setEditingList(val);
    setIndex(index);
    setEditModal(!editModal);
  };

  const handleEditWaitingList = (event, values) => {
    event.persist();
    values["id"] = editingList.id;
    values["index"] = index;
    props.updateContacts(values);
  };

  const contactListTable = () => {
    if (props.contactList) {
      return props.contactList.map((item, index) => {
        return (
          <Tr key={index}>
            <Td>{index + 1}</Td>
            <Td>{item.contact_name}</Td>
            <Td>{item.email}</Td>
            <Td>{item.phone}</Td>
            <Td>{item.message_content}</Td>
            <Td>{moment(item.created_at).format("YYYY-MM-DD")}</Td>
            <Td>{item.notes}</Td>
            <Td>{item.user_id ? item.user.name : 'API'}</Td>
            <Td>{moment(item.updated_at).format("YYYY-MM-DD")}</Td>
            <Td>
              <i
                className="fas fa-pencil-alt actions"
                style={{cursor: "pointer"}}
                onClick={() => handleEdit(item, index)}
              />
              <i
                className="fas fa-trash-alt actions"
                style={{cursor: "pointer"}}
                onClick={() => handleDel(item, index)}
              />
            </Td>
          </Tr>
        );
      });
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Contacts received from website")}
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">#</Th>
                            <Th data-priority="1">{props.t("Contact Name")}</Th>
                            <Th data-priority="1">{props.t("E-mail")}</Th>
                            <Th data-priority="1">{props.t("Phone")}</Th>
                            <Th data-priority="1">{props.t("Message")}</Th>
                            <Th data-priority="1">{props.t("Sent date")}</Th>
                            <Th data-priority="1">{props.t("Notes")}</Th>
                            <Th data-priority="1">{props.t("Updated by")}</Th>
                            <Th data-priority="1">{props.t("Last updated")}</Th>
                            <Th data-priority="1">{props.t("Actions")}</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {contactListTable()}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                  <Pagination
                    className="float-right"
                    current={current}
                    defaultPageSize={20}
                    onShowSizeChange={onShowSizeChange}
                    onChange={onChange}
                    total={props.totalContacts ? props.totalContacts : 0}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={editModal}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="myModal"
            tabIndex="-1"
            toggle={() => {
              setEditModal(!editModal);
            }}
          >
            <div className="modal-content">
              <ModalHeader
                toggle={() => {
                  setEditModal(!editModal);
                }}
              >
                {props.t("Edit")}
              </ModalHeader>
              <ModalBody>
                <Col sm="12">
                  <Card>
                    <CardBody>
                      {editingList && (
                      <AvForm
                        onValidSubmit={(e, v) => {
                          handleEditWaitingList(e, v);
                        }}
                      >
                        <AvField
                            name="contactName"
                            value={editingList.contact_name}
                            label={props.t("Contact Name*")}
                            placeholder={props.t("contact name")}
                            type="text"
                            errorMessage={props.t("Enter contact name")}
                            validate={{ required: { value: true } }}
                        />
                        <AvField
                            name="email"
                            value={editingList.email}
                            label={props.t("E-mail*")}
                            placeholder={props.t("email")}
                            type="email"
                            errorMessage={props.t("Invalid Email")}
                            validate={{
                              required: { value: true },
                              email: { value: true }
                            }}
                        />
                        <AvField
                            tag={InputMask}
                            value={editingList.phone}
                            mask="(999) 999-9999"
                            className="form-control input-color"
                            name="phone"
                            label="Phone*"
                            errorMessage={"Please input phone number"}
                            validate={{
                              required: true
                            }}
                        />

                        <AvField
                            type="textarea"
                            value={editingList.notes}
                            name="notes"
                            placeholder="notes..."
                            rows={5}
                        />
                        <FormGroup className="mb-0">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                              disabled={props.loading}
                            >
                              {props.loading ? (
                                <React.Fragment>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                    Updating
                                </React.Fragment>
                              ) : "Update"}
                            </Button>{" "}
                          </div>
                        </FormGroup>
                      </AvForm>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </ModalBody>
            </div>
          </Modal>

          {confirm_both ? (
            <SweetAlert
              title={props.t("Are you sure?")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                setconfirm_both(false);
                postDelete();
              }}
              onCancel={() => {
                setconfirm_both(false);
                setsuccess_dlg(false);
                setdynamic_title("Cancelled");
                setdynamic_description("Your imaginary file is safe :)");
              }}
            >
              {props.t("You won't be able to revert this!")}
            </SweetAlert>
          ) : null}

          {success_dlg ? (
            <SweetAlert
              success
              title={props.t(dynamic_title)}
              onConfirm={() => {
                props.deleteOneContact(index)
                setsuccess_dlg(false);
                setDelData(null);
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {error_dlg ? (
            <SweetAlert
              error
              title={props.t(dynamic_title)}
              onConfirm={() => {
                seterror_dlg(false);
                setDelData(null);
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { loading, contactList, totalContacts, editModalStatus } = state.ContactReducer;
  return { loading, contactList, totalContacts, editModalStatus };
};

export default withRouter(
  connect(mapStatetoProps, {
    getContacts,
    updateContacts,
    deleteOneContact
  })(withNamespaces()(ContactList))
);
