import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { GET_ALL_WAITING_LIST, CREATE_WAITING_LIST, UPDATE_WAITING_LIST, DELETE_USER } from './actionTypes';
import {
    getWaitingListSuccess,
    createWaitingListSuccess,
    createWaitingListFailed,
    updateWaitingListSuccess,
    updateWaitingListFailed,
    getWaitingList
} from './actions';
import { paramPost, paramGet, paramPut } from "../../../apiHelpers/commonAPi";
import toastr from 'toastr';

function* getAllWaitingList({ payload: { page, pageSize } }) {
    try {
        const response = yield call(paramGet, '/api/v1/waiting_lists', {page, pageSize});
        if (response.status === 200) {
            yield put(getWaitingListSuccess(JSON.parse(response.data.waiting_list), response.data.total));
        }
    } catch (error) {
        throw error;
    }
}

function* createWaitingList({ payload: { waitingList } }) {
    try {
        const response = yield call(paramPost, '/api/v1/waiting_lists', {...waitingList});
        if (response.status === 201) {
            yield put(getWaitingList({page: 1, pageSize: 20}))
            toastr.success(response.data.message);
            yield put(createWaitingListSuccess());
        } else {
            toastr.error(response.data.message);
            yield put(createWaitingListFailed());
        }
    } catch (error) {
        yield put(createWaitingListFailed());
    }
}

function* updateWaitingListInfo({ payload: { newWaiter } }) {
    try {
        const index = newWaiter.index
        const id = newWaiter.id
        delete newWaiter.id
        delete newWaiter.index
        const response = yield call(paramPut, `/api/v1/waiting_lists/${id}`, {...newWaiter});
        if (response.status === 200) {
            yield put(updateWaitingListSuccess(JSON.parse(response.data.waiter), index));
            toastr.success(response.data.message, "Success");
        } else {
            toastr.error(response.data.message, "Error");
            yield put(updateWaitingListFailed());
        }
    } catch (error) {
        yield put(updateWaitingListFailed());
    }
}


export function* watchGetWaitingList() {
    yield takeEvery(GET_ALL_WAITING_LIST, getAllWaitingList)
}

export function* watchCreateWaitingList() {
    yield takeEvery(CREATE_WAITING_LIST, createWaitingList)
}

export function* watchUpdateWaitingList() {
    yield takeEvery(UPDATE_WAITING_LIST, updateWaitingListInfo)
}

function* waitingListSaga() {
    yield all([
        fork(watchGetWaitingList),
        fork(watchCreateWaitingList),
        fork(watchUpdateWaitingList)
    ]);
}

export default waitingListSaga;