import {
    REGISTER_STUDENT,
    REGISTER_STUDENT_SUCCESS,
    REGISTER_STUDENT_FAILED,
    GET_REGISTERED_LIST,
    GET_REGISTERED_LIST_SUCCESS,
    UPDATE_REGISTRATION,
    UPDATE_REGISTRATION_SUCCESS,
    UPDATE_REGISTRATION_FAILED,
    DELETE_ONE_REGISTRATION
} from './actionTypes';

export const delOneRegistration = (index) => {
    return {
        type: DELETE_ONE_REGISTRATION,
        payload: index
    }
}

export const getRegistrationList = (page) => {
    return {
        type: GET_REGISTERED_LIST,
        payload: page
    }
}

export const getRegistrationListSuccess = (students, total) => {
    return {
        type: GET_REGISTERED_LIST_SUCCESS,
        payload: students,
        total: total
    }
}

export const registerStudent = (param) => {
    return {
        type: REGISTER_STUDENT,
        payload: param
    }
}

export const registerStudentSuccess = () => {
    return {
        type: REGISTER_STUDENT_SUCCESS
    }
}

export const registerStudentFailed = () => {
    return {
        type: REGISTER_STUDENT_FAILED
    }
}

export const updateRegistration = (data) => {
    return {
        type: UPDATE_REGISTRATION,
        payload: { data }
    }
}

export const updateRegistrationSuccess = (updatedInfo, index) => {
    return {
        type: UPDATE_REGISTRATION_SUCCESS,
        payload: updatedInfo,
        index: index
    }
}

export const updateRegistrationFailed = () => {
    return {
        type: UPDATE_REGISTRATION_FAILED
    }
}
