import React, { useState, useEffect, Fragment } from 'react';
import { Container, Row, Col, Button, Card, CardBody, FormGroup} from "reactstrap";
import { withRouter } from "react-router-dom";
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';

import Breadcrumbs from '../../components/Common/Breadcrumb';

import { connect } from 'react-redux';


import { getOldStudentsList } from '../../store/actions';
//i18n
import { withNamespaces } from 'react-i18next';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { saveComment, releaseNote } from "../../apiHelpers/schoolReportApiHelper";
import toastr from "toastr";
import axios from "axios";
import {paramPost} from "../../apiHelpers/commonAPi";
import SweetAlert from "react-bootstrap-sweetalert";

const OldActivityDetail = (props) => {


    useEffect(() => {
        props.getOldStudentsList()
    }, [])

    const [allActivities, setAllActivities] =  useState(undefined)
    const [report, setReport] = useState(undefined)

    const [findingState, setFindingState] = useState(false)

    const [selectedStudent, setSelectedStudent] = useState(1)
    const [printing, setPrinting] = useState(false)
    const [reportId, setReportId] = useState(undefined)

    const [studentName, setStudentName] = useState('');
    const [reportOptions, setReportOptions] = useState(null);
    const [duration, setDuration] = useState(1)

    const handleFindActivity = (event, values) => {
        event.persist()
        if (parseInt(values.grade) === 1) {
            toastr.info("Please select a Year Duration", "Info")
            return false
        }

        if (parseInt(values.student) === 1) {
            toastr.info("Please select a student", "Info")
            return false
        }
        setReport(undefined)
        setAllActivities(undefined)
        setFindingState(true)
        paramPost("/api/v1/old_report", values)
            .then((result) => {
                setFindingState(false)
                if(result.status === 200) {
                    setAllActivities(JSON.parse(result.data.activities))
                    setReportId(JSON.parse(result.data.report).id);
                    setReport(JSON.parse(result.data.report))
                } else {
                    toastr.error(result.data.error,"Error")
                }
            })
            .catch((error) => {
                setFindingState(false)
                toastr.error("Something went wrong!","Error")
            })
    }

    const grades = () => {
        if(reportOptions && reportOptions.reports.length > 0) {
            return reportOptions.reports.map((item, index) => {
                return (
                    <option key={index} value={item.id + 1}>
                        {`Report${index + 1} - ${item.duration}`}
                    </option>
                )
            })
        }
    }


    const reportItems = () => {
        if(allActivities) {
            return allActivities.map((item, index) => {
                return (
                    <Fragment key={index}>
                        <tr style={{backgroundColor: "#CCCCCC"}}>
                            <td colSpan={4}>
                                <strong>
                                    <h4 style={{marginBottom: "unset"}}>
                                        {item.title}
                                    </h4>
                                </strong>
                            </td>
                        </tr>
                        {reportActivities(item.report_activities)}
                    </Fragment>
                )
            })
        }
    }

    const reportActivities = (activities) => {
        if(activities) {
            return activities.map((item, index) => {
                return (
                    <Fragment key={index}>
                        <tr style={{backgroundColor: "#C9DAF8"}}>
                            <td colSpan={4} style={{textAlign: "left"}}>
                                <h5 style={{marginBottom: "unset"}}>
                                    {item.name}
                                </h5>
                            </td>
                        </tr>
                        {reportSubActivities(item.report_sub_activities)}
                    </Fragment>
                )
            })
        }
    }

    const reportSubActivities = (subs) => {
        if(subs) {
            return subs.map((item, index) => {
                return (
                    <Fragment key={index}>
                        <tr>
                            <td style={{textAlign: "left"}}>
                                {item.name}
                            </td>
                            <td>
                                {item.report_subs[0].first_result ? item.report_subs[0].first_result : "-"}
                            </td>
                            <td>
                                {item.report_subs[0].second_result ? item.report_subs[0].second_result : "-"}
                            </td>
                            <td>
                                {item.report_subs[0].third_result ? item.report_subs[0].third_result : "-"}
                            </td>
                        </tr>
                    </Fragment>
                )
            })
        }
    }


    const activityOptions = () => {
        if (props.oldStudents) {
            return props.oldStudents.map((item, index) => {
                return (
                    <option key={index} value={item.id + 1}>
                        {item.firstName + " " + item.lastName}
                    </option>
                )
            })
        }
    }

    const generateReport = () => {
        if(reportId) {
            setPrinting(true);
            const headers = JSON.parse(localStorage.getItem("authHeaders"))
            const config = {
                method: 'post',
                url: `/api/v1/generate_pdf`,
                headers: {
                    accesstoken: headers.accesstoken,
                    client: headers.client,
                    uid: headers.uid,
                },
                responseType: 'blob',
                data: {report_id: reportId}
            }
            axios
                .request(config)
                .then(response => {
                    setPrinting(false);
                    const blob = URL.createObjectURL(response.data);
                    window.open(blob, '_blank');
                })
                .catch((err) => {
                    setPrinting(false);
                    toastr.error("Something went wrong!", "Error")
                });
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('School Report - Old')} breadcrumbItem={props.t('Activity')} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <AvForm className="outer-repeater row m-auto" style={{ width: "100%" }} onValidSubmit={(e, v) => { handleFindActivity(e, v) }}>
                                            <Col xs={12} sm={4} md={4}>
                                                <div className="form-group">
                                                    <AvField
                                                        type="select"
                                                        value={selectedStudent}
                                                        name="student"
                                                        validate={{ required: { value: true } }}
                                                        onChange={(e) => {
                                                            let index = e.nativeEvent.target.selectedIndex;
                                                            let text = e.nativeEvent.target[index].text;
                                                            setStudentName(text.replace(' ', '_'));
                                                            setDuration(1);
                                                            setReportOptions(props.oldStudents[index - 1]);
                                                        }}
                                                    >
                                                        <option value={1} disabled={true}>Select Student</option>
                                                        {activityOptions()}
                                                    </AvField>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={4} md={4}>
                                                <div className="form-group">
                                                    <AvField type="select" value={duration} name="grade"
                                                             onChange={e => {
                                                                 setDuration(e.target.value + 1);
                                                             }}
                                                    >
                                                        <option value={1} disabled={true}>Select Year Duration</option>
                                                        {grades()}
                                                    </AvField>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={4} md={4}>
                                                <FormGroup className="mb-0">
                                                    <div>
                                                        <Button type="submit" color="primary" className="mr-1" style={{ width: "100%" }} disabled={findingState}>
                                                            {findingState ? (
                                                                <React.Fragment>
                                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                    Finding
                                                                </React.Fragment>
                                                            ) : "Find"}
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </AvForm>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={9} className="float-right report-guide">
                                            <p className="comment p-2" style={{float: "left", background: "#e1e1e1", color: "black"}}>
                                                Légende: <br />
                                                1: Non-observé à ce jour 2: À travailler 3: Progrès 4: Évolution continue 5: Bonne maitrise
                                            </p>
                                        </Col>
                                        <Col xs={12} sm={3} className="report-print-btn">
                                            {!printing ? (
                                                <button type="button" className="mt-sm-1 float-right btn btn-success waves-effect waves-light w-sm" disabled={!report} onClick={generateReport}>
                                                    <i className="fa fa-print d-block font-size-16"></i>
                                                    Print
                                                </button> ) : (
                                                <button type="button" className="mt-sm-1 float-right btn btn-success waves-effect waves-light w-sm" disabled={true}>
                                                    <i className="fa fa-print d-block font-size-16"></i>
                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                    Generating Report...
                                                </button> )}
                                        </Col>
                                    </Row>
                                    <Row className="mt-2" style={{padding: "0px 10px"}}>
                                        <Table id="tech-companies-1" className="table table-striped table-bordered">
                                            <Thead>
                                                <Tr style={{backgroundColor: "#3B78D8", color: "white"}}>
                                                    <Th data-priority="1" style={{width: "40%"}}>Étapes</Th>
                                                    <Th data-priority="1">1</Th>
                                                    <Th data-priority="1">2</Th>
                                                    <Th data-priority="1">3</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {reportItems()}
                                            </Tbody>
                                        </Table>

                                        {report && (
                                            <Fragment>
                                                <Table id="tech-companies-1" className="table table-striped table-bordered">
                                                    <Thead>
                                                        <Tr style={{backgroundColor: "#D9D9D9"}}>
                                                            <Th className="text-left" data-priority="1" colSpan={2}>Release 1 notes</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <tr>
                                                            <td style={{backgroundColor: "#D9D9D9"}}><b>Teachers</b></td>
                                                            <td style={{backgroundColor: "#D9D9D9"}}>
                                                            <textarea defaultValue={report.first_t_comment} className="form-control" name="" id="" cols="30" rows="3">
                                                            </textarea>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{backgroundColor: "#D9D9D9"}}><b>Parents</b></td>
                                                            <td colSpan={2} className="text-left" style={{backgroundColor: "#CFE2F3"}}>
                                                                {report.first_p_comment}
                                                            </td>
                                                        </tr>
                                                    </Tbody>
                                                </Table>

                                                <Table id="tech-companies-1" className="table table-striped table-bordered">
                                                    <Thead>
                                                        <Tr style={{backgroundColor: "#D9D9D9"}}>
                                                            <Th className="text-left" data-priority="1" colSpan={2}>Release 2 notes</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <tr>
                                                            <td style={{backgroundColor: "#D9D9D9"}}><b>Teachers</b></td>
                                                            <td style={{backgroundColor: "#D9D9D9"}}>
                                                            <textarea defaultValue={report.second_t_comment} className="form-control" name="" id="" cols="30" rows="3">
                                                            </textarea>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{backgroundColor: "#D9D9D9"}}><b>Parents</b></td>
                                                            <td colSpan={2} className="text-left" style={{backgroundColor: "#CFE2F3"}}>
                                                                {report.second_p_comment}
                                                            </td>
                                                        </tr>
                                                    </Tbody>
                                                </Table>

                                                <Table id="tech-companies-1" className="table table-striped table-bordered">
                                                    <Thead>
                                                        <Tr style={{backgroundColor: "#D9D9D9"}}>
                                                            <Th className="text-left" data-priority="1" colSpan={2}>Release 3 notes</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <tr>
                                                            <td style={{backgroundColor: "#D9D9D9"}}><b>Teachers</b></td>
                                                            <td style={{backgroundColor: "#D9D9D9"}}>
                                                            <textarea defaultValue={report.third_t_comment} className="form-control" name="" id="" cols="30" rows="3">
                                                            </textarea>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{backgroundColor: "#D9D9D9"}}><b>Parents</b></td>
                                                            <td colSpan={2} className="text-left" style={{backgroundColor: "#CFE2F3"}}>
                                                                {report.third_p_comment}
                                                            </td>
                                                        </tr>
                                                    </Tbody>
                                                </Table>
                                            </Fragment>
                                        )}
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    const { oldStudents } = state.ReportAttReducer;
    return { oldStudents };
}

export default withRouter(connect(mapStateToProps, { getOldStudentsList })(withNamespaces()(OldActivityDetail)));
