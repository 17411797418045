import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, CardBody, Modal, ModalHeader, ModalBody, FormGroup } from "reactstrap";
import { withRouter } from "react-router-dom";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { connect } from 'react-redux';


import { getReportActivityLists, getGroupList } from '../../store/actions';
//i18n
import { withNamespaces } from 'react-i18next';
import { AvForm, AvField } from "availity-reactstrap-validation";
import {updateActivities} from "../../apiHelpers/schoolReportApiHelper";
import toastr from "toastr";
import axios from "axios";

import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});



const ReportActivityList = (props) => {


    useEffect(() => {
        props.getReportActivityLists()
        props.getGroupList()
    }, [])


    const [editModal, setEditModal] = useState(false);
    const [modalName, setName] = useState('');
    const [type, setType] = useState('');
    const [id, setId] = useState(undefined);


    //sweet alert settings
    const [confirm_both, setconfirm_both] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [error_dlg, seterror_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [delData, setDelData] = useState(null)

    const [groupId, setGroupId] = useState(undefined);

    const classes = useStyles();

    const subActivities = (subs) => {
        if(subs && subs.length > 0) {
            return subs.map((item, index) => {
                return (
                    <TreeItem
                        nodeId={item.name}
                        key={index}
                        label={
                            <>
                                <span>
                                    {item.name}
                                </span>
                                <span className="float-right" id={`${item.name}` + `${item.id}`}>
                                    <i className="fas fa-pencil-alt actions" onClick={() => {handleUpdateSub(item)}}></i>
                                    <i className="fas fa-trash-alt actions" onClick={() => handleDel({id: item.id, type: "sub", title: item.name})}></i>
                                </span>
                            </>
                        }
                    >
                    </TreeItem>
                )
            })
        }
    }


    const activities = (activities) => {
        if(activities && activities.length > 0) {
            return activities.map((item, index) => {
                return (
                    <TreeItem
                        key={index}
                        nodeId={item.name}
                        label={
                            <>
                                <span>
                                    {item.name}
                                </span>
                                <span className="float-right" id={`${item.name}` + `${item.id}`}>
                                    <i className="fas fa-pencil-alt actions" onClick={() => {handleUpdateAct(item)}}></i>
                                    <i className="fas fa-trash-alt actions" onClick={() => handleDel({id: item.id, type: "act", title: item.name})}></i>
                                </span>
                            </>
                        }
                    >
                        {subActivities(item.report_sub_activities)}
                    </TreeItem>
                )
            })
        }
    }

    const getAllLists = () => {
        if(props.allLists && props.allLists.length > 0) {
            return props.allLists.map((item, index) => {
                return (
                    <TreeItem
                        nodeId={item.title}
                        key={index}
                        label={
                            <>
                            <span>{item.title}</span>
                            <span className="action111 float-right" id={`${item.title}` + `${item.id}`}>
                                <i className="fas fa-pencil-alt actions" onClick={() => {handleUpdateWorkbook(item)}}></i>
                                <i className="fas fa-trash-alt actions" onClick={() => handleDel({id: item.id, type: "wb", title: item.title})}></i>
                            </span>
                            </>
                        }>
                        {activities(item.report_activities)}
                    </TreeItem>
                )
            })
        }
    }



    const clear = () => {
        setEditModal(false)
        //hide()
        setName('')
        setType('')
        setId(undefined)
        setGroupId(undefined)
    }

    const handleUpdateSub = (item) => {
        setEditModal(!editModal)
        setName(item.name)
        setType("sub")
        setId(item.id)
    }

    const handleUpdateWorkbook = (item) => {
        setEditModal(!editModal)
        setName(item.title)
        setType("wb")
        setId(item.id)
        setGroupId(item.group_id)
    }

    const handleUpdateAct = (item) => {
        setEditModal(!editModal)
        setName(item.name)
        setType("act")
        setId(item.id)
    }

    const handleDel = (item) => {
        setName(item.title)
        setType(item.type)
        setId(item.id)
        setconfirm_both(true)
    }

    const handleEdit = (event, value) => {
        const params = {type: type, id: id, title: value.title}
        if(value.group && value.group !== "Select Group") {
            params.group = value.group;
        }
        updateActivities(params)
            .then((result) => {
                if(result.status === 200) {
                    clear()
                    toastr.success(result.data.message, "Success")
                    props.getReportActivityLists()
                } else {
                    toastr.warning(result.data.message, "Warning")
                }
            })
            .catch(error => {
                toastr.error("Something went wrong. Please try again!", "Error")
            })
    }

    const postDelete = () => {
        let url = ""
        if(type === "wb") {
            url = `/api/v1/report_items/${id}`
        } else if(type === "act") {
            url = `/api/v1/report_activities/${id}`
        } else {
            url = `/api/v1/report_sub_activities/${id}`
        }
        const headers = JSON.parse(localStorage.getItem("authHeaders"))
        const config = {
            method: "delete",
            url: url,
            headers: {
                accesstoken: headers.accesstoken,
                client: headers.client,
                uid: headers.uid,
            }
        }
        return axios(config).then(response => {
            if (response.status === 400 || response.status === 500)
                throw response.data;
            setsuccess_dlg(true);
            setdynamic_title("Deleted");
            setdynamic_description(response.data.message);
        }).catch(err => {
            seterror_dlg(true)
            throw err[1];
        });
    }

    const options = () => {
        if(props.groups.length > 0) {
            return props.groups.map((item, index) => {
                return (
                    <option key={index} value={item.id}>{item.name}</option>
                )
            })
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('Activity-List')} breadcrumbItem={props.t('Group')} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col id="tree-view">
                                           <TreeView
                                                className={classes.root}
                                                defaultCollapseIcon={<ExpandMoreIcon />}
                                                defaultExpandIcon={<ChevronRightIcon />}
                                            >
                                                {getAllLists()}
                                            </TreeView>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal isOpen={editModal} role="dialog" autoFocus={true} backdrop={"static"} centered={true} className="myModal" tabIndex="-1" toggle={() => { setEditModal(!editModal);}}>
                        <div className="modal-content">
                            <ModalHeader toggle={() => { setEditModal(!editModal); clear()}}>
                                Edit
                            </ModalHeader >
                            <ModalBody>
                                <Col sm="12">
                                    <Card>
                                        <CardBody>
                                            <AvForm onValidSubmit={(e,v) => { handleEdit(e,v) }}>
                                                <AvField
                                                    name="title"
                                                    label="Title"
                                                    placeholder="title"
                                                    type="text"
                                                    errorMessage="Enter Title"
                                                    value={modalName}
                                                    validate={{ required: { value: true } }}
                                                />
                                                {type === "wb" && (
                                                    <AvField
                                                        type="select"
                                                        value={groupId ? groupId : "Select Group"}
                                                        name="group"
                                                        label={props.t("Select A Group")}
                                                    >
                                                        <option disabled={true}>Select Group</option>
                                                        {options()}
                                                    </AvField>
                                                )}
                                                <FormGroup className="mb-0">
                                                    <div>
                                                        <Button type="submit" color="primary" className="mr-1">
                                                            Update
                                                        </Button>{" "}
                                                    </div>
                                                </FormGroup>
                                            </AvForm>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </ModalBody>
                        </div>
                    </Modal>
                    {confirm_both ? (
                        <SweetAlert
                            title="Are you sure?"
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => {
                                setconfirm_both(false);
                                postDelete()

                            }
                            }
                            onCancel={() =>
                            {
                                clear()
                                setconfirm_both(false);
                                setsuccess_dlg(false);
                                setdynamic_title("Cancelled");
                                setdynamic_description("Your imaginary file is safe :)");
                            }
                            }
                        >
                            You won't be able to revert this!
                        </SweetAlert>
                    ) : null}

                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={props.t(dynamic_title)}
                            onConfirm={() => {
                                setsuccess_dlg(false);
                                setDelData(null);
                                clear();
                                props.getReportActivityLists();
                            } }
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {error_dlg ? (
                        <SweetAlert
                            error
                            title={props.t(dynamic_title)}
                            onConfirm={() => {
                                seterror_dlg(false);
                                setDelData(null);
                                clear();
                            } }
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    const { loading, error, allLists} = state.SchoolReportListReducer;
    const { groups } = state.ActivityReducer;
    return { loading, error, allLists, groups };
}

export default withRouter(connect(mapStateToProps, { getReportActivityLists, getGroupList })(withNamespaces()(ReportActivityList)));
