import {
    GET_ATTENDANCES,
    GET_ATTENDANCES_SUCCESS,
    GET_ATTENDANCES_FAILED,
    GET_STUDENTS_LIST_SUCCESS,
    GET_OLD_STUDENTS_LIST_SUCCESS
} from './actionTypes';

const initialState = {
    error: "",
    loading: false,
    attendances: undefined,
    students: undefined,
    oldStudents: null
}

const attendances = (state = initialState, action) => {
    switch (action.type) {
        case GET_ATTENDANCES:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_ATTENDANCES_SUCCESS:
            state = {
                ...state,
                loading: false,
                attendances: action.payload
            }
            break;
        case GET_ATTENDANCES_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload
            };
            break;
        case GET_STUDENTS_LIST_SUCCESS:
            state = {
                ...state,
                students: action.payload
            };
            break;
        case GET_OLD_STUDENTS_LIST_SUCCESS:
            state = {
                ...state,
                oldStudents: action.payload
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default attendances;