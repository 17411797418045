import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";

import Breadcrumbs from '../../components/Common/Breadcrumb';

import { connect } from 'react-redux';


import { getAttendances } from '../../store/actions';
//i18n
import { withNamespaces } from 'react-i18next';
import moment from "moment";
import { DatePicker } from "antd";
import toastr from 'toastr';


const { RangePicker } = DatePicker

const ReportExpenses = (props) => {

    const getDate = (value, dateString) => {
        const params = { start: dateString[0], end: dateString[1] };
        if (value) {
            props.getAttendances(params)
        }
    }
    const tableHeader = () => {
        if (props.attendances && props.attendances.length > 0) {
            return props.attendances[0].attendances.map((item, index) => {
                return (
                    <td key={index}>
                        <p className="font-weight-bold">
                            {moment(item.date).format('MMM Do YY')}
                        </p>
                    </td>
                )
            })
        }
    }

    let grandTotal = 0;

    const totalByDay = () => {
        if (props.attendances && props.attendances.length > 0) {
            return props.attendances[0].attendances.map((item, index) => {
                return (
                    <td key={index}>
                        {calByDay(props.attendances, index)}
                    </td>
                )
            })
        }
    }

    const calByDay = (data, index) => {
        let total = 0
        data.forEach((day) => {
            total += day.attendances[index].total
        });
        if (total === 0) {
            return ""
        }
        grandTotal += total
        return total
    }

    const tableBody = () => {
        if (props.attendances) {
            return props.attendances.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="font-weight-bold fixed" style={{ cursor: "pointer", background: "#3b7ad9" }} onClick={() => goToStudent(item)}>
                            {item.name}
                        </td>
                        {tableBodyItems(item.attendances)}
                    </tr>
                )
            })
        }
    }

    const tableBodyItems = (attendances) => {
        if (attendances.length > 0) {
            return attendances.map((item, index) => {
                return (
                    <td key={index}>{symbol(item.total, item.cafe)}</td>
                )
            })
        }
    }

    const goToStudent = (info) => {
        window.location.href = `/students/${info.student_id}`
    }

    const symbol = (item, cafe) => {
        switch (item) {
            case 0:
                return "";
            case 5:
                if (cafe) {
                    return "C"
                }
                return "SG"
            case 10:
                return "SG, C"
            default:
                return ""
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('Report-Expenses')} breadcrumbItem={props.t('Report')} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <span>{props.t('Select start and end date')}:</span>
                                    <div className="mb-3">
                                        <RangePicker onChange={getDate} />
                                    </div>
                                    <Row>
                                        <Col xs={12} className="float-right">
                                            <p className="comment p-2" style={{ float: "left", background: "#3b7ad9", color: "white" }}>
                                                {/* (SG)Service de Garde, (C)Cafeteria */}
                                                (SG)Service de Garde
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row className="mt-4 overflow-auto report-wrapper search-table-outter">
                                        <table id="tech-companies-1" className="table-striped search-table inner">
                                            <thead className="report-thead">
                                                <tr>
                                                    {props.attendances && props.attendances.length > 0 && (
                                                        <td>
                                                            <p className="font-weight-bold">Child Name</p>
                                                        </td>
                                                    )}
                                                    {tableHeader()}
                                                </tr>
                                            </thead>
                                            <tbody className="report-tbody">
                                                {tableBody()}
                                                <tr>
                                                    {props.attendances && props.attendances.length > 0 && (
                                                        <td className="font-weight-bold fixed" style={{ backgroundColor: "green" }}>
                                                            Total by Day
                                                        </td>
                                                    )}
                                                    {totalByDay()}
                                                </tr>
                                                <tr>
                                                    {props.attendances && props.attendances.length > 0 && (
                                                        <Fragment>
                                                            <td className="font-weight-bold fixed" style={{ backgroundColor: "#133013" }}>
                                                                Grand Total
                                                    </td>
                                                            <td className="font-weight-bold" style={{ color: "red" }}>
                                                                {grandTotal}
                                                            </td>
                                                        </Fragment>
                                                    )}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    const { attendances } = state.ReportAttReducer;
    return { attendances };
}

export default withRouter(connect(mapStateToProps, { getAttendances })(withNamespaces()(ReportExpenses)));
