import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, CardTitle, FormGroup, Label, Form } from "reactstrap";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { connect } from 'react-redux';


import { getAttStudents, getGroupList, createAttendance, updateAttendance } from '../../store/actions';
import Select from "react-select";
//i18n
import { withNamespaces } from 'react-i18next';
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import toastr from "toastr";





const Attendances = (props) => {


    useEffect(() => {
        props.getGroupList()
    }, [])

    useEffect(() => {
        if (props.attendances) {
            let presents = 0
            let absent = 0
            let service_garde = 0
            // let cafeteria = 0
            props.attendances.forEach((item) => {
                if (item.garde) {
                    service_garde++
                }
                // if (item.cafe) {
                //     cafeteria++
                // }
                if (item.present === "Présent(P)") {
                    presents++
                } else {
                    absent++
                }
            })
            setPre(presents)
            setAbs(absent)
            setGar(service_garde)
            // setCaf(cafeteria)
            // setTot((service_garde + cafeteria) * 5)
            setTot((service_garde) * 5)
        }
    }, [props.attendances])

    useEffect(() => {
        if (props.modal === "success" && isCreate) {
            clear()
            setmodal(false)
        }
    }, [props.modal])

    useEffect(() => {
        if (props.editModal === "success" && !isCreate) {
            clear()
            setmodal(false)
        }
    }, [props.editModal])

    const [rows1, setrows1] = useState([]);

    const [students, setStudents] = useState([]);


    const [allActivities, setAllActivities] = useState([]);

    const [modal, setmodal] = useState(false);

    //////////// Total /////////////////
    const [pre, setPre] = useState(undefined)
    const [abs, setAbs] = useState(undefined)
    const [gar, setGar] = useState(undefined)
    // const [caf, setCaf] = useState(undefined)
    const [tot, setTot] = useState(undefined)

    //////// updating values ////////////////
    const [present, setPresent] = useState(undefined)
    const [garde, setGarge] = useState(undefined)
    // const [cafe, setCafe] = useState(undefined)
    const [completed, setCompleted] = useState(undefined)
    const [id, setId] = useState(undefined)
    const [index, setIndex] = useState(undefined)
    //////////////////////////////////////////
    const [date, setDate] = useState('')
    const [group, setGroup] = useState("Select Group")
    const [studentId, setStudentId] = useState(undefined)
    const [attId, setAttId] = useState(undefined)
    ////////////////Modal///////////////
    const [isCreate, setIsCreate] = useState(undefined)



    const options = () => {
        if (props.groups) {
            return props.groups.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.name}
                    </option>
                )
            })
        }
    }



    const attendances = () => {
        if (props.attendances.length !== 0) {
            return props.attendances.map((item, index) => {
                return (
                    <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>{item.firstName + " " + item.lastName}</Td>
                        <Td>
                            {item.present ? (item.present) : ""}
                        </Td>
                        <Td>
                            {item.garde === 1 ? "Yes" : item.garde !== 0 ? "" : "No"}
                        </Td>
                        {/* <Td>
                            {item.cafe === 1 ? "Yes" : item.cafe !== 0 ? "" : "No"}
                        </Td> */}
                        <Td>
                            {item.total === 0 ? "0.00" : item.total}
                        </Td>
                        <Td>{!item.atten_id ? (
                            <i className="fas fa-plus" style={{ cursor: "pointer" }} onClick={() => handleCreate(item, index)}></i>
                        ) : (
                                <i className="fas fa-pencil-alt" style={{ cursor: "pointer" }} onClick={() => handleUpdate(item, index)}></i>)}
                        </Td>
                    </Tr>
                )
            })
        }
    }



    const clear = () => {
        setmodal(false)
        setIsCreate(undefined)
        setPresent(undefined)
        setGarge(undefined)
        // setCafe(undefined)
        setIndex(undefined)
        setStudentId(undefined)
        setAttId(undefined)
    }

    const handleCreate = (item, index) => {
        setIsCreate(true)
        setmodal(true)
        setIndex(index)
        setStudentId(item.id)
    }

    const handleUpdate = (item, index) => {
        setIsCreate(false)
        setPresent(item.present)
        setGarge(item.garde && item.garde === 1 ? "Yes" : "No")
        // setCafe(item.cafe && item.cafe === 1 ? "Yes" : "No")
        setAttId(item.atten_id)
        setIndex(index)
        setmodal(true)
    }

    const handleEdit = (event, values) => {
        if (values.actions === "Se  lect") {
            toastr.info(props.t('Please select one of actions below'), "Info")
            return false
        }

        if (values.garde === "Select") {
            toastr.info(props.t('Please select one of Service De Garde options'), "Info")
            return false
        }

        // if (values.cafe === "Select") {
        //     toastr.info(props.t('Please select one of Cafeteria options'), "Info")
        //     return false
        // }
        const garde = values.garde === "Yes" ? true : false
        // const cafe = values.cafe === "Yes" ? true : false
        let total
        // if (garde && cafe) {
        if (garde) {
            total = 5
            // } else if (!garde && !cafe) {
        } else if (!garde) {
            total = 0.00
        } else {
            total = 5
        }
        if (isCreate) {
            const params = {
                action: values.actions,
                garde: garde,
                // cafe: cafe,
                date: date,
                total: total,
                student_id: studentId,
                group_id: group
            }
            props.createAttendance(params)
        } else {
            const params = {
                action: values.actions,
                garde: garde,
                // cafe: cafe,
                total: total,
                att_id: attId,
                group_id: group,
                date: date,
            }
            props.updateAttendance(params)
        }
    }

    const handelDate = (e) => {
        if (group === "Select Group") {
            toastr.info(props.t('Please select the group'), "Info")
            setDate('')
            return false
        }
        setDate(e.target.value)
        props.getAttStudents({ date: e.target.value, group_id: group })
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('Attendances')} breadcrumbItem={props.t('Attendances')} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={4} sm={3}>
                                            <span className="form-group">
                                                <Input
                                                    type="select"
                                                    value={'Select Group'}
                                                    name="group"
                                                    value={group}
                                                    onChange={(e) => setGroup(e.target.value)}
                                                >
                                                    <option disabled={true}>Select Group</option>
                                                    {options()}
                                                </Input>
                                            </span>
                                            <span className={"ml-2"}>
                                                <Input
                                                    name="accomplished"
                                                    type="date"
                                                    className="form-control"
                                                    id="validationCustom04"
                                                    value={date}
                                                    onChange={(e) => handelDate(e)}
                                                />
                                            </span>
                                        </Col>
                                        <Col xs={8} sm={9} className="mt-auto d-flex">
                                            {/* <span className="p-2 ml-auto" style={{ outline: "0px solid", backgroundColor: "#3b7ad9", color: "white" }}>{props.t('Presents')}: <strong>{pre}</strong> :: {props.t('Absences')}: <strong>{abs}</strong> :: {props.t('Service de Garde')}: <strong>{gar}</strong> :: {props.t('Cafeteria')}: <strong>{caf}</strong> | {props.t('Total Cafeteria')} + {props.t('Service de Garde')} = <strong>{tot}.00</strong></span> */}
                                            <span className="p-2 ml-auto" style={{ outline: "0px solid", backgroundColor: "#3b7ad9", color: "white" }}>{props.t('Presents')}: <strong>{pre}</strong> :: {props.t('Absences')}: <strong>{abs}</strong> :: {props.t('Service de Garde')}: <strong>{gar}</strong>  | {props.t('Total')} = <strong>$ {tot}.00</strong></span>
                                        </Col>
                                    </Row>

                                    <Row className="mt-4">
                                        <Table id="tech-companies-1" className="table table-striped table-bordered">
                                            <Thead>
                                                <Tr>
                                                    <Th data-priority="1">#</Th>
                                                    <Th data-priority="1">{props.t('Name')}</Th>
                                                    <Th data-priority="1">{props.t('Present')}</Th>
                                                    <Th data-priority="1">{props.t('Service De Garde')}</Th>
                                                    {/* <Th data-priority="1">{props.t('Cafeteria')}</Th> */}
                                                    <Th data-priority="1">{props.t('Total')}</Th>
                                                    <Th data-priority="1">{props.t('Action')}</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {attendances()}
                                            </Tbody>
                                        </Table>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="float-right">
                            <p className="comment p-2">
                                {props.t('Present')}(P), Absent(A), Maladie(M), Vacances(V), Férié(F), Fermeture du SG(X), Demi-Journée(DJ)
                            </p>
                        </Col>
                    </Row>

                    <Modal size='md' backdrop={"static"} isOpen={modal} role="dialog" autoFocus={true} centered={true} className="myModal" tabIndex="-1" toggle={() => { setmodal(!modal); }}>
                        <div className="modal-content">
                            <ModalHeader toggle={() => { clear() }}>
                                {isCreate ? props.t('Create New Attendance') : props.t('Update Attendance')}
                            </ModalHeader >
                            <ModalBody>
                                <Col sm="12">
                                    <Card>
                                        <CardBody>
                                            <AvForm onValidSubmit={(e, v) => { handleEdit(e, v) }}>
                                                <AvField type="select" label="Actions" value={isCreate ? "Select" : present} name="actions" label={props.t('Present?')}>
                                                    <option disabled={true}>Select</option>
                                                    <option>Présent(P)</option>
                                                    <option>Absent(A)</option>
                                                    <option>Maladie(M)</option>
                                                    <option>Vacances(V)</option>
                                                    <option>Férié(F)</option>
                                                    <option>Fermeture du SG(X)</option>
                                                    <option>Demi-Journée(DJ)</option>
                                                </AvField>
                                                <AvField type="select" label="Service De Garde" value={isCreate ? "Select" : garde} name="garde" label="Service De Garde">
                                                    <option disabled={true}>Select</option>
                                                    <option>Yes</option>
                                                    <option>No</option>
                                                </AvField>
                                                {/* <AvField type="select" label="Cafeteria" value={isCreate ? "Select" : cafe} name="cafe" label="Cafeteria">
                                                    <option disabled={true}>Select</option>
                                                    <option>Yes</option>
                                                    <option>No</option>
                                                </AvField> */}
                                                <FormGroup className="mb-0">
                                                    <div>
                                                        <Button type="submit" color="primary" className="mr-1" disabled={props.loading}>
                                                            {isCreate ? (
                                                                props.loading ?
                                                                    <React.Fragment>
                                                                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                        Saving...
                                                                    </React.Fragment> :
                                                                    "Save"
                                                            ) :
                                                                (
                                                                    props.loading ?
                                                                        <React.Fragment>
                                                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                            Updating...
                                                                        </React.Fragment> : "Update"
                                                                )
                                                            }
                                                        </Button>{" "}
                                                    </div>
                                                </FormGroup>
                                            </AvForm>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </ModalBody>
                        </div>
                    </Modal>

                </Container>
            </div>
        </React.Fragment >
    );
}

const mapStateToProps = state => {
    const { groups } = state.ActivityReducer;
    const { modal, attendances, editModal, loading } = state.AttendanceReducer;
    return { groups, modal, editModal, attendances, loading };
}

export default withRouter(connect(mapStateToProps, { getGroupList, getAttStudents, updateAttendance, createAttendance })(withNamespaces()(Attendances)));
