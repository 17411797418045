import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup, CustomInput, Label,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Pagination } from "antd";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { connect } from "react-redux";
import moment from "moment";
import {
  getRegistrationList,
  updateRegistration,
  delOneRegistration
} from "../../store/actions";
//i18n
import { withNamespaces } from "react-i18next";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvRadioGroup,
  AvRadio,
  AvInput
} from "availity-reactstrap-validation";
// Form Mask
import InputMask from "react-input-mask";
import axios from "axios";
import toastr from "toastr";


const selectOptions = [1, 1, 1, 1, 1];
const selectDescriptions = ['', '', '', '', ''];

const RegistrationList = (props) => {
  const [confirm_both, setconfirm_both] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [addUserSuccess, setAddUserSuccess] = useState(false);
  const [error_dlg, seterror_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");

  const [editModal, setEditModal] = useState(false);
  const [current, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  // For editing modal
  const [editingList, setEditingList] = useState(null);
  const [index, setIndex] = useState("index");

  const [delData, setDelData] = useState(null);

  const [mMon, setMMon] = useState(false)
  const [mTue, setMTue] = useState(false)
  const [mWed, setMWed] = useState(false)
  const [mThu, setMThu] = useState(false)
  const [mFri, setMFri] = useState(false)

  const [aMon, setAMon] = useState(false)
  const [aTue, setATue] = useState(false)
  const [aWed, setAWed] = useState(false)
  const [aThu, setAThu] = useState(false)
  const [aFri, setAFri] = useState(false)

  const [isMSameAddress, setIsMSameAddress] = useState(false);
  const [isFSameAddress, setIsFSameAddress] = useState(false);

  const [selectList, setSelectList] = useState(selectOptions);
  const [selectDescription, setSelectDescription] = useState(selectDescriptions);

  const [birthFile, setBirthFile] = useState(null);
  const [bankFile, setBankFile] = useState(null);

  useEffect(() => {
    props.getRegistrationList({ page: 1, pageSize: pageSize });
  }, []);

  useEffect(() => {
    if (props.editModalStatus === 1) {
      setEditModal(false);
    }
  }, [props.editModalStatus]);


  const handleMAddress = (event, value) => {
    if(value) {
      const isSame = value === 1
      setIsMSameAddress(isSame);
    }
  }

  const handleFAddress = (event, value) => {
    if(value) {
      const isSame = value === 1
      setIsFSameAddress(isSame);
    }
  }

  const handleSelections = (event, num) => {
    let val = selectList;
    val[num] = parseInt(event.target.value);
    if(val[num] === 3) {
      let value = selectDescription;
      value[num] = '';
      setSelectDescription([...value]);
    }
    setSelectList([...val]);
  }

  const handleSelectionDesc = (event, num) => {
    let val = selectDescription;
    val[num] = event.target.value;
    setSelectDescription([...val]);
  }

  const onChange = (page) => {
    props.getAllUsers({ page: page, pageSize: pageSize });
    setCurrentPage(page);
  };

  function onShowSizeChange(current, pageSize) {
    props.getAllUsers({ page: current, pageSize: pageSize });
    setPageSize(pageSize);
    setCurrentPage(current);
  }

  const handleDel = (val, index) => {
    setDelData(val);
    setIndex(index);
    setconfirm_both(true);
  };

  const handleAddUser = (val, index) => {
    setEditingList(val);
    setIndex(index);
    setAddUser(true)
  }

  const postDelete = () => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"));
    const config = {
      method: "delete",
      url: `/api/v1/registrations/${delData.id}`,
      headers: {
        accesstoken: headers.accesstoken,
        client: headers.client,
        uid: headers.uid,
      },
    };
    return axios(config)
      .then((response) => {
        if (response.status === 400 || response.status === 500)
          throw response.data;
        setsuccess_dlg(true);
        setdynamic_title("Deleted");
        setdynamic_description("Registered student has been deleted.");
      })
      .catch((err) => {
        seterror_dlg(true);
        throw err[1];
      });
  };

  const addUserToStudentList = () => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"));
    const config = {
      method: "post",
      url: `/api/v1/registrations/confirm_user`,
      headers: {
        accesstoken: headers.accesstoken,
        client: headers.client,
        uid: headers.uid,
      },
      data: {id: editingList.id}
    };
    return axios(config)
        .then((response) => {
          if (response.status > 300){
            throw response.data;}
          setAddUserSuccess(true);
          setdynamic_title("Confirmed");
          setdynamic_description("User confirmed successfully.");
        })
        .catch((err) => {
          seterror_dlg(true);
          setdynamic_title("Error");
          setdynamic_description(err.response.data.message);
          throw err[1];
        });
  }

  const handleEdit = (val, index) => {
    setEditingList(val);
    setIndex(index);
    // Attendance days
    setMMon(val.mMon ? val.mMon : false)
    setMTue(val.mTue ? val.mTue : false)
    setMWed(val.mWed ? val.mWed : false)
    setMThu(val.mThu ? val.mThu : false)
    setMFri(val.mFri ? val.mFri : false)

    setAMon(val.aMon ? val.aMon : false)
    setATue(val.aTue ? val.aTue : false)
    setAWed(val.aWed ? val.aWed : false)
    setAThu(val.aThu ? val.aThu : false)
    setAFri(val.aFri ? val.aFri : false)

    // Selected values & description
    setSelectList([val.specialCare, val.medication, val.sideEffects, val.allergies, val.provision]);
    setSelectDescription([val.specialCareDesc, val.medicationDesc, val.sideEffectsDesc, val.allergyDesc, val.provisionDesc])

    setEditModal(!editModal);
  };

  const handleRegistrationUpdate = (event, values) => {
    event.persist();
    if(values.parentFirstSignDate !== values.parentSecondSignDate ||
        values.parentSecondSignDate !== values.parentConfirmSignDate ||
        values.parentConfirmSignDate !== values.parentFirstSignDate
    ) {
      toastr.warning("Sign dates can't be different", "Validation Warning");
      return;
    }

    // Attendance check
    const days = [mMon, mTue, mWed, mThu, mFri, aMon, aTue, aWed, aThu, aFri]
    let total = 0
    days.forEach(item => {
      if (item) {
        total++;
      }
    });
    if (total < 3) {
      toastr.warning("Have to check more than 3 days")
      return false;
    }
    values["id"] = editingList.id;
    values["index"] = index;

    values.mMon = mMon;
    values.mTue = mTue;
    values.mWed = mWed;
    values.mThu = mThu;
    values.mFri = mFri;
    values.aMon = aMon;
    values.aTue = aTue;
    values.aWed = aWed;
    values.aThu = aThu;
    values.aFri = aFri;

    if( !values.fatherSameAddress ) {
      values.fatherSameAddress = 0
    }
    if( !values.motherSameAddress ) {
      values.motherSameAddress = 0
    }

    props.updateRegistration(values);
  };

  const contactListTable = () => {
    if (props.registeredList) {
      return props.registeredList.map((item, index) => {
        return (
          <Tr key={index}>
            <Td>{index + 1}</Td>
            <Td>{item.motherName}</Td>
            <Td>{item.motherEmail}</Td>
            <Td>{item.motherCellPhone}</Td>
            <Td>{item.firstName + " " + item.lastName}</Td>
            <Td>{moment(item.created_at).format("YYYY-MM-DD")}</Td>
            <Td>{moment(item.birthday).format("YYYY-MM-DD")}</Td>
            <Td>
              {item.birth_cert_url && (
                  <>
                    <a className="doc-url" href={item.birth_cert_url} download={true} target="_blank">CN</a>
                    <br />
                  </>
              )}
              {item.bank_balance_url && (
                <a href={item.bank_balance_url} download={true} target="_blank">PB</a>
              )}
            </Td>
            <Td>{item.user_id ? item.user.name : ""}</Td>
            <Td>{moment(item.updated_at).format("YYYY-MM-DD")}</Td>
            <Td>
              {item.is_approved ? (
                 <span className="badge rounded-pill bg-success">
                   Registered
                 </span>
              ) : (
                  <span className="badge rounded-pill bg-warning">
                    Pending
                  </span>
              )}
            </Td>
            <Td>
              {item.is_approved ? (
                  <>
                    <i
                        className="fas fa-user-plus actions"
                        style={{color: "darkgrey", cursor: "not-allowed"}}
                    />
                    <i
                      className="fas fa-pencil-alt actions"
                      style={{color: "darkgrey", cursor: "not-allowed"}}
                    />
                  </>
                ) : (
                    <>
                      <i
                      className="fas fa-user-plus actions"
                      style={{cursor: "pointer"}}
                      onClick={() => handleAddUser(item, index)}
                      />
                      <i
                      className="fas fa-pencil-alt actions"
                      style={{cursor: "pointer"}}
                      onClick={() => handleEdit(item, index)}
                      />
                    </>
                )}
              <i
                className="fas fa-trash-alt actions"
                style={{cursor: "pointer"}}
                onClick={() => handleDel(item, index)}
              />
            </Td>
          </Tr>
        );
      });
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Registrations")}
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">#</Th>
                            <Th data-priority="1">{props.t("Contact Name")}</Th>
                            <Th data-priority="1">{props.t("E-mail")}</Th>
                            <Th data-priority="1">{props.t("Phone")}</Th>
                            <Th data-priority="1">{props.t("Student Name")}</Th>
                            <Th data-priority="1">{props.t("Filling date")}</Th>
                            <Th data-priority="1">{props.t("Birthday")}</Th>
                            <Th data-priority="1">{props.t("Documents")}</Th>
                            <Th data-priority="1">{props.t("Updated by")}</Th>
                            <Th data-priority="1">{props.t("Last updated")}</Th>
                            <Th data-priority="1">{props.t("Status")}</Th>
                            <Th data-priority="1">{props.t("Actions")}</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {contactListTable()}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                  <Pagination
                    className="float-right"
                    current={current}
                    defaultPageSize={20}
                    onShowSizeChange={onShowSizeChange}
                    onChange={onChange}
                    total={props.listTotal ? props.listTotal : 0}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={editModal}
            style={{maxWidth: "90%", margin: "30px auto"}}
            role="dialog"
            size={"lg"}
            autoFocus={true}
            centered={true}
            className="myModal"
            tabIndex="-1"
            toggle={() => {
              setEditModal(!editModal);
            }}
          >
            <div className="modal-content">
              <ModalHeader
                toggle={() => {
                  setEditModal(!editModal);
                }}
              >
                {props.t("Edit")}
              </ModalHeader>
              <ModalBody>
                <Col sm="12">
                  <Card>
                    <CardBody>
                      {editingList && (
                      <AvForm
                        onValidSubmit={(e, v) => {
                          handleRegistrationUpdate(e, v);
                        }}
                      >
                        <div className="row sub-section">
                          <div className="col-xl-12 sub-section-title-div">
                            <h3 className="sub-section-title">Informations sur l'enfant / Child Info</h3>
                          </div>
                          <div className="col-sm-12 col-md-6 mt-4">
                            <AvField
                                name="lastName"
                                type="text"
                                label="Nom de famille / Last name *"
                                errorMessage="Enter Last Name!"
                                value={editingList.lastName}
                                validate={{ required: { value: true } }}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 mt-4">
                            <AvField
                                name="firstName"
                                type="text"
                                label="Prénom / First name *"
                                errorMessage="Enter First Name!"
                                value={editingList.firstName}
                                validate={{ required: { value: true } }}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 mt-4">
                            <AvField
                                name="address"
                                label="Adresse / Address *"
                                type="text"
                                errorMessage="Provide a valid address!"
                                value={editingList.address}
                                validate={{ required: { value: true } }}
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-3 mt-4">
                            <AvField
                                name="birthday"
                                label="Date d'anniversaire / Birthday *"
                                type="date"
                                value={moment(editingList.birthday).format("YYYY-MM-DD")}
                                errorMessage="Provide a valid date!"
                                required
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-3 mt-4">
                            <AvField
                                type="select"
                                label="Genre / Gender *"
                                name="gender"
                                errorMessage={"Select the gender"}
                                value={2}
                                validate={{
                                  required: { value: true },
                                  pattern: {value: '^(?!(?:1)$)', errorMessage: 'Please select'}
                                }}>
                              <option value={1}>Genre / Gender</option>
                              <option value={2}>M</option>
                              <option value={3}>F</option>
                            </AvField>
                          </div>
                          <div className="col-sm-12 mt-4">
                            <label className="control-label">Langage / Language *</label>
                            <AvCheckboxGroup
                                inline name="language"
                                style={{padding: "0 10px"}}
                                value={editingList.selected_languages}
                                errorMessage="Select more than 1 language"
                                required
                            >
                              <AvCheckbox label="Français / French" value="French" />
                              <AvCheckbox label="Anglais / English" value="English" />
                              <AvCheckbox label="Espanol / Spanish" value="Spanish" />
                              <AvCheckbox label="Portugais / Portuguese" value="Portuguese" />
                              <AvCheckbox label="Arabe / Arabic" value="Arabic" />
                            </AvCheckboxGroup>
                          </div>
                          <div className="col-12 mt-4">
                            <label className="control-label">La fréquence / Expected attendance *</label>
                            <Table id="days-table" className="table table-striped table-bordered">
                              <Thead>
                                <Tr>
                                  <Th data-priority="1">Periode / Period</Th>
                                  <Th data-priority="1">Lundi / Monday</Th>
                                  <Th data-priority="3">Mardi / Tuesday</Th>
                                  <Th data-priority="1">Mercredi / Wednesday</Th>
                                  <Th data-priority="1">Jeudi / Thursday</Th>
                                  <Th data-priority="1">Venderdi/Friday</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                <Tr>
                                  <Td>Matin / Morning</Td>
                                  <Td className="week-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setMMon(!mMon)} checked={mMon} />
                                  </Td>
                                  <Td className="week-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setMTue(!mTue)} checked={mTue} />
                                  </Td>
                                  <Td className="week-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setMWed(!mWed)} checked={mWed} />
                                  </Td>
                                  <Td className="week-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setMThu(!mThu)} checked={mThu} />
                                  </Td>
                                  <Td className="week-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setMFri(!mFri)} checked={mFri} />
                                  </Td>
                                </Tr>
                                <Tr>
                                  <Td>Après-midi / Afternoon</Td>
                                  <Td className="week-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setAMon(!aMon)} checked={aMon} />
                                  </Td>
                                  <Td className="week-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setATue(!aTue)} checked={aTue} />
                                  </Td>
                                  <Td className="week-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setAWed(!aWed)} checked={aWed} />
                                  </Td>
                                  <Td className="week-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setAThu(!aThu)} checked={aThu} />
                                  </Td>
                                  <Td className="week-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={() => setAFri(!aFri)} checked={aFri} />
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </div>

                          {/*Identification of parents*/}
                          <div className="col-xl-12 sub-section-title-div mt-lg-5 mt-3">
                            <h3 className="sub-section-title">Identification des parents / Identification of parents</h3>
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <AvField
                                name="motherName"
                                label="Tuteur1/Tutrice1 - Tutor1 *"
                                type="text"
                                value={editingList.motherName}
                                errorMessage="Enter mother name"
                                validate={{ required: { value: true } }}
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <AvField
                                name="motherEmail"
                                value={editingList.motherEmail}
                                type="email"
                                label={"Courriel / E-mail *"}
                                errorMessage="Invalid Email"
                                validate={{
                                  required: { value: true },
                                  email: { value: true }
                                }}
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <label className="control-label">
                              Même adresse que l’enfant / Same child address
                            </label>
                            <AvRadioGroup inline name="motherSameAddress" style={{padding: "0 10px"}} onChange={handleMAddress} >
                              <AvRadio label="Oui / Yes" value={1} />
                              <AvRadio label="Non / No" value={2} />
                            </AvRadioGroup>
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <AvField
                                name="motherAddress"
                                label="Adresse complète / Address"
                                type="text"
                                disabled={isMSameAddress}
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <AvField
                                tag={InputMask}
                                mask="(999) 999-9999"
                                className="form-control input-color"
                                value={editingList.motherHomePhone}
                                name="motherHomePhone"
                                label="N. tél. maison / Home phone number"
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <AvField
                                tag={InputMask}
                                mask="(999) 999-9999"
                                className="form-control input-color"
                                name="motherCellPhone"
                                label="N. tél. cellulaire / Cel phone number *"
                                value={editingList.motherCellPhone}
                                errorMessage={"Input phone number"}
                                required
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <AvField
                                tag={InputMask}
                                mask="(999) 999-9999"
                                className="form-control input-color"
                                name="motherOfficePhone"
                                value={editingList.motherOfficePhone}
                                label="N. tél. travail / Office phone number"
                            />
                          </div>
                          <div className="col-sm-6 col-md-8"></div>

                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <AvField
                                name="fatherName"
                                label="Tuteur2/Tutrice2 - Tutor2"
                                type="text"
                                value={editingList.fatherName}
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <AvField
                                name="fatherEmail"
                                type="email"
                                label={"Courriel / E-mail *"}
                                value={editingList.fatherEmail}
                                validate={{
                                  email: { value: true }
                                }}
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <label className="control-label">
                              Même adresse que l’enfant / Same child address
                            </label>
                            <AvRadioGroup inline name="fatherSameAddress" style={{padding: "0 10px"}} onChange={handleFAddress} >
                              <AvRadio label="Oui / Yes" value={1} />
                              <AvRadio label="Non / No" value={2} />
                            </AvRadioGroup>
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <AvField
                                name="fatherAddress"
                                label="Adresse complète / Address"
                                type="text"
                                disabled={isFSameAddress}
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <AvField
                                tag={InputMask}
                                mask="(999) 999-9999"
                                className="form-control input-color"
                                name="fatherHomePhone"
                                value={editingList.fatherHomePhone}
                                label="N. tél. maison / Home phone number"
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <AvField
                                tag={InputMask}
                                mask="(999) 999-9999"
                                className="form-control input-color"
                                name="fatherCellPhone"
                                value={editingList.fatherCellPhone}
                                label="N. tél. cellulaire / Cel phone number"
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <AvField
                                tag={InputMask}
                                mask="(999) 999-9999"
                                className="form-control input-color"
                                name="fatherOfficePhone"
                                value={editingList.fatherOfficePhone}
                                label="N. tél. travail / Office phone number"
                            />
                          </div>

                          {/*Authorized person to pick up child*/}
                          <div className="col-xl-12 sub-section-title-div mt-lg-5 mt-3">
                            <h3 className="sub-section-title">
                              Personnes autorisées à venir chercher l’enfant / Persons authorized to pickup the child
                            </h3>
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <AvField
                                name="pickerName"
                                label="Nom / Name *"
                                type="text"
                                errorMessage="Enter name"
                                value={editingList.pickerName}
                                validate={{ required: { value: true } }}
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <AvField
                                name="pickerLinkChild"
                                type="text"
                                value={editingList.linkChild}
                                label="Lien avec l'enfant / Link with child *"
                                validate={{
                                  required: { value: true }
                                }}
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <AvField
                                tag={InputMask}
                                mask="(999) 999-9999"
                                className="form-control input-color"
                                name="pickerPhone"
                                value={editingList.pickerPhone}
                                label="N. télephone / Phone number *"
                                errorMessage={"Input phone number"}
                                required
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <AvField
                                name="pickerAddress"
                                label="Adresse complète / Address *"
                                type="text"
                                value={editingList.pickerAddress}
                                errorMessage={"Input address"}
                                required
                            />
                          </div>

                          {/*Contact in case of urgency (other than parents)*/}
                          <div className="col-xl-12 sub-section-title-div mt-lg-5 mt-3">
                            <h3 className="sub-section-title">
                              Personnes à contacter en cas d’urgence (autres que les parents) / Contact in case of urgency (other than parents)
                            </h3>
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <AvField
                                name="urgencyName"
                                label="Nom / Name *"
                                type="text"
                                value={editingList.urgencyName}
                                errorMessage="Enter name"
                                validate={{ required: { value: true } }}
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <AvField
                                name="urgencyLinkChild"
                                type="text"
                                value={editingList.urgencyLinkChild}
                                label="Lien avec l'enfant / Link with child *"
                                validate={{
                                  required: { value: true }
                                }}
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <AvField
                                tag={InputMask}
                                mask="(999) 999-9999"
                                value={editingList.urgencyPhone}
                                className="form-control input-color"
                                name="urgencyPhone"
                                label="N. télephone / Phone number *"
                                errorMessage={"Input phone number"}
                                required
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-4 mt-4">
                            <AvField
                                name="urgencyAddress"
                                label="Adresse complète / Address *"
                                value={editingList.urgencyAddress}
                                type="text"
                                errorMessage={"Input address"}
                                required
                            />
                          </div>

                          {/* Child health information */}
                          <div className="col-xl-12 sub-section-title-div mt-lg-5 mt-3">
                            <h3 className="sub-section-title">
                              Renseignements sur la santé de l’enfant / Child health information
                            </h3>
                          </div>
                          <div className="col-12 col-md-6 mt-4">
                            <AvField
                                tag={InputMask}
                                mask="aaaa 9999 9999"
                                className="form-control input-color"
                                name="securityNumber"
                                value={editingList.healthSin}
                                label="N. assurance maladie / RAMQ *"
                                required
                            />
                          </div>
                          <div className="col-12 col-md-6 mt-4">
                            <AvField
                                name="expDate"
                                type="date"
                                className="form-control"
                                label="Exp. date *"
                                value={moment(editingList.healthExp).format("YYYY-MM-DD")}
                                required
                            />
                          </div>
                          <div className="col-md-4 mt-4">
                            A-t-il des problèmes de santé qui nécessitent des soins particuliers? /
                            Are there any health problems that require special care? *
                          </div>
                          <div className="col-md-4 mt-4">
                            <AvField
                                type="select"
                                name="specialCare"
                                value={editingList.specialCare}
                                onChange={(e) => handleSelections(e, 0)}
                                validate={{
                                  required: { value: true },
                                  pattern: {value: '^(?!(?:1)$)', errorMessage: 'Please select'}
                                }}>
                              <option value={1} disabled={true}>Select</option>
                              <option value={2}>Oui / Yes</option>
                              <option value={3}>Non / No</option>
                            </AvField>
                          </div>
                          <div className="col-md-4 mt-4">
                            <AvField
                                name="specialCareDesc"
                                type="textarea"
                                className="form-control"
                                placeholder="Si oui, lesquels?"
                                disabled={selectList[0] !== 2}
                                required={selectList[0] === 2}
                                errorMessage={"Please input description"}
                                value={selectDescription[0]}
                                onChange={e => handleSelectionDesc(e, 0)}
                                rows={3}
                            />
                          </div>

                          <div className="col-md-4 mt-4">
                            Prend-il des médicaments de façon régulière? /
                            Does he/she take medication on a regular basis? *
                          </div>
                          <div className="col-md-4 mt-4">
                            <AvField
                                type="select"
                                name="medication"
                                value={editingList.medication}
                                onChange={(e) => handleSelections(e, 1)}
                                validate={{
                                  required: { value: true },
                                  pattern: {value: '^(?!(?:1)$)', errorMessage: 'Please select'}
                                }}>
                              <option value={1} disabled={true}>Select</option>
                              <option value={2}>Oui / Yes</option>
                              <option value={3}>Non / No</option>
                            </AvField>
                          </div>
                          <div className="col-md-4 mt-4">
                            <AvField
                                name="medicationDesc"
                                type="textarea"
                                className="form-control"
                                placeholder="Si oui, lesquels?"
                                disabled={selectList[1] !== 2}
                                value={selectDescription[1]}
                                required={selectList[1] === 2}
                                errorMessage={"Please input description"}
                                onChange={e => handleSelectionDesc(e, 1)}
                                rows={3}
                            />
                          </div>

                          <div className="col-md-4 mt-4">
                            Effets secondaires possibles de ces médicaments /
                            Possible side effects for these medications *
                          </div>
                          <div className="col-md-4 mt-4">
                            <AvField
                                type="select"
                                name="sideEffect"
                                value={editingList.sideEffects}
                                onChange={(e) => handleSelections(e, 2)}
                                validate={{
                                  required: { value: true },
                                  pattern: {value: '^(?!(?:1)$)', errorMessage: 'Please select'}
                                }}>
                              <option value={1} disabled={true}>Select</option>
                              <option value={2}>Oui / Yes</option>
                              <option value={3}>Non / No</option>
                            </AvField>
                          </div>
                          <div className="col-md-4 mt-4">
                            <AvField
                                name="sideEffectDesc"
                                type="textarea"
                                className="form-control"
                                placeholder="Si oui, lesquels?"
                                disabled={selectList[2] !== 2}
                                required={selectList[2] === 2}
                                errorMessage={"Please input description"}
                                value={selectDescription[2]}
                                onChange={e => handleSelectionDesc(e, 2)}
                                rows={3}
                            />
                          </div>

                          <div className="col-md-4 mt-4">
                            A-t-il reçu tous ses vaccins? / Has the child received all vaccines? *
                          </div>
                          <div className="col-md-4 mt-4">
                            <AvField
                                type="select"
                                name="vaccines"
                                value={editingList.vaccine}
                                validate={{
                                  required: { value: true },
                                  pattern: {value: '^(?!(?:1)$)', errorMessage: 'Please select'}
                                }}>
                              <option value={1} disabled>Select</option>
                              <option value={2}>Oui / Yes</option>
                              <option value={3}>Non / No</option>
                            </AvField>
                          </div>
                          <div className="col-md-4 mt-4">
                          </div>

                          <div className="col-md-4 mt-4">
                            La vaccination est-elle à jour? / Is the vaccination up to date? *
                          </div>
                          <div className="col-md-4 mt-4">
                            <AvField
                                type="select"
                                name="vaccineUpToDate"
                                value={editingList.vaccineUpToDate}
                                validate={{
                                  required: { value: true },
                                  pattern: {value: '^(?!(?:1)$)', errorMessage: 'Please select'}
                                }}>
                              <option value={1} disabled={true}>Select</option>
                              <option value={2}>Oui / Yes</option>
                              <option value={3}>Non / No</option>
                            </AvField>
                          </div>
                          <div className="col-md-4 mt-4">
                          </div>

                          <div className="col-md-4 mt-4">
                            Votre enfant souffre-t-il d’allergies (médicaments ou aliments)? / Does your child have allergies (medication or food)? *
                          </div>
                          <div className="col-md-4 mt-4">
                            <AvField
                                type="select"
                                name="allergies"
                                value={editingList.allergies}
                                onChange={(e) => handleSelections(e, 3)}
                                validate={{
                                  required: { value: true },
                                  pattern: {value: '^(?!(?:1)$)', errorMessage: 'Please select'}
                                }}>
                              <option value={1} disabled={true}>Select</option>
                              <option value={2}>Oui / Yes</option>
                              <option value={3}>Non / No</option>
                            </AvField>
                          </div>
                          <div className="col-md-4 mt-4">
                            <AvField
                                name="allergyDesc"
                                type="textarea"
                                className="form-control"
                                placeholder="Si oui, lesquels?"
                                disabled={selectList[3] !== 2}
                                required={selectList[3] === 2}
                                errorMessage={"Please input description"}
                                value={selectDescription[3]}
                                onChange={e => handleSelectionDesc(e, 3)}
                                rows={3}
                            />
                          </div>

                          <div className="col-md-4 mt-4">
                            Y a-t-il des dispositions à prendre pour sa santé selon les maladies et/ou allergies mentionnées plus haut en cas d’urgence? /
                            Are there provisions to be taken for his health according to the disease and / or allergies mentioned above in case of emergency? *
                          </div>
                          <div className="col-md-4 mt-4">
                            <AvField
                                type="select"
                                name="provision"
                                value={editingList.provision}
                                onChange={(e) => handleSelections(e, 4)}
                                validate={{
                                  required: { value: true },
                                  pattern: {value: '^(?!(?:1)$)', errorMessage: 'Please select'}
                                }}>
                              <option value={1} disabled={true}>Select</option>
                              <option value={2}>Oui / Yes</option>
                              <option value={3}>Non / No</option>
                            </AvField>
                          </div>
                          <div className="col-md-4 mt-4">
                            <AvField
                                name="provisionDesc"
                                type="textarea"
                                className="form-control"
                                placeholder="Si oui, lesquels?"
                                disabled={selectList[4] !== 2}
                                required={selectList[4] === 2}
                                errorMessage={"Please input description"}
                                value={selectDescription[4]}
                                onChange={e => handleSelectionDesc(e, 4)}
                                rows={3}
                            />
                          </div>

                          <div className="col-md-4 mt-4">
                            Nom du pédiatre et/ou du médecinde famille /
                            Name of Pediatrician And / or family doctor *
                          </div>
                          <div className="col-md-4 mt-4">
                            <AvField
                                name="doctorName"
                                placeholder="Prénom, Nom / Last"
                                type="text"
                                value={editingList.doctorName}
                                errorMessage="Enter Name"
                                validate={{ required: { value: true } }}
                            />
                            <br />
                            <AvField
                                tag={InputMask}
                                mask="(999) 999-9999"
                                className="form-control input-color"
                                name="doctorPhone"
                                value={editingList.doctorPhone}
                                placeholder="N. Télephone / Phone number"
                                required
                            />
                          </div>
                          <div className="col-md-4 mt-4">
                            <AvField
                                name="doctorAddress"
                                type="text"
                                value={editingList.doctorAddress}
                                className="form-control"
                                placeholder="Adresse / Address"
                                required
                            />
                          </div>

                          <div className="col-md-4 mt-4">
                            Médicaments autorisés. Ceci est requis en signant les formulaires MFA /
                            Authorized Drugs. This is required by signing MFA forms *
                          </div>
                          <div className="col-md-8 mt-4">
                            <AvField
                                name="drugSign"
                                type="text"
                                value={editingList.drugSign}
                                errorMessage="Enter Authorized drug"
                                validate={{ required: { value: true } }}
                            />
                          </div>

                          {/* Autorisations / Authorizations */}
                          <div className="col-xl-12 sub-section-title-div mt-lg-5 mt-3">
                            <h3 className="sub-section-title">
                              Autorisations / Authorizations
                            </h3>
                          </div>
                          <div className="mt-4 mx-0 py-4 row" style={{border: "1px solid"}}>
                            <div className="col-md-12 py-2">
                              <p>
                                J’autorise mon enfant à participer aux activités extérieures organisées par le service de garde de l’École
                                Montessori de Beauport (sorties éducatives ou sportives).
                              </p>
                              <p>
                                I authorize my child to participate on outdoors activities organized by École Montessori de Beauport. (educational or sports activities)
                              </p>
                            </div>
                            <div className="col-sm-6 py-2 d-flex align-items-center">
                              <AvInput
                                  className=""
                                  type="checkbox" name="parentFirstSign"
                                  tag={CustomInput}
                                  label={"Autorisation du parent / Parent's authorization *"}
                                  errorMessage="Please check this!"
                                  value={editingList.firstPSign}
                                  required />
                            </div>
                            <div className="col-sm-6 py-2">
                              <AvField
                                  name="parentFirstSignDate"
                                  type="date"
                                  value={moment(editingList.firstSDate).format("YYYY-MM-DD")}
                                  errorMessage="Provide a valid date!"
                                  required
                              />
                            </div>
                            <div className="col-sm-12 py-2">
                              <p className="border-top" />
                            </div>
                            <div className="col-md-12 py-2">
                              <p>
                                J’autorise l’équipe de l’École Montessori de Beauport à prendre les mesures nécessaires en cas d’urgence
                                pour la santé et la sécurité de mon enfant.
                              </p>
                              <p>
                                I authorize the École Montessori de Beauport to take the necessary measures in case of urgency concerning the security and healthy of my child.
                              </p>
                              <p>
                                Y a-t-il des dispositions particulières à prendre en cas d’urgence (ambulance, hôpital, premiers soins)?<br />
                                Is there any particular or concerns we should know in case of urgency? (hospital, ambulance, first aid).
                              </p>
                            </div>
                            <div className="col-md-12 pb-2">
                              <AvField
                                  name="signDesc"
                                  type="textarea"
                                  className="form-control"
                                  value={editingList.signDesc}
                                  placeholder="Si oui, lesquelles? / If yes, please describe."
                                  rows={5}
                              />
                            </div>
                            <div className="col-sm-6 py-2 d-flex align-items-center">
                              <AvInput
                                  tag={CustomInput}
                                  type="checkbox"
                                  name="parentSecondSign"
                                  value={editingList.secondPSign}
                                  label={"Autorisation du parent / Parent's authorization *"}
                                  required />
                            </div>
                            <div className="col-sm-6 py-2">
                              <AvField
                                  name="parentSecondSignDate"
                                  type="date"
                                  value={moment(editingList.secondSDate).format("YYYY-MM-DD")}
                                  errorMessage="Provide a valid date!"
                                  required
                              />
                            </div>
                            <div className="col-sm-12 py-2">
                              <p className="border-top" />
                            </div>

                            <div className="col-md-12 py-2">
                              <p>
                                Les renseignements contenus dans cette fiche sont exacts et je m’engage à vous informer de tout changement.
                              </p>
                            </div>
                            <div className="col-sm-6 py-2 d-flex align-items-center">
                              <AvInput
                                  label={"je suis d'accord  / I agree *"}
                                  tag={CustomInput}
                                  type="checkbox"
                                  name="parentConfirmSign"
                                  value={editingList.confirmSign}
                                  required />
                            </div>
                            <div className="col-sm-6 py-2">
                              <AvField
                                  name="parentConfirmSignDate"
                                  type="date"
                                  value={moment(editingList.confirmSignDate).format("YYYY-MM-DD")}
                                  errorMessage="Provide a valid date!"
                                  required
                              />
                            </div>
                          </div>
                        </div>

                        {/*Submit button*/}
                        <div className="col-md-12 pt-4 text-center">
                          <Button
                              type="submit"
                              color="primary"
                              className="py-2 px-4"
                              disabled={props.loading}
                          >
                            {props.loading ? (
                                <React.Fragment>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                  ...Updating
                                </React.Fragment>
                            ) : "Update"}
                          </Button>
                        </div>

                      </AvForm>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </ModalBody>
            </div>
          </Modal>

          {confirm_both ? (
            <SweetAlert
              title={props.t("Are you sure?")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                setconfirm_both(false);
                postDelete();
              }}
              onCancel={() => {
                setconfirm_both(false);
                setsuccess_dlg(false);
                setdynamic_title("Cancelled");
                setdynamic_description("Your imaginary file is safe :)");
              }}
            >
              {props.t("You won't be able to revert this!")}
            </SweetAlert>
          ) : null}

          {addUser ? (
              <SweetAlert
                  title={props.t("Are you sure?")}
                  warning
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    setAddUser(false);
                    addUserToStudentList();
                  }}
                  onCancel={() => {
                    setAddUser(false);
                    setAddUserSuccess(false);
                    setdynamic_title("Cancelled");
                    setdynamic_description("Your imaginary file is safe :)");
                  }}
              >
                L'élève et les utilisateurs pour les parents seront créés. / The student and the users for the parents will be created.
              </SweetAlert>
          ) : null}

          {addUserSuccess ? (
              <SweetAlert
                  success
                  title={props.t(dynamic_title)}
                  onConfirm={() => {
                    setAddUserSuccess(false);
                    setEditingList(null);
                    setIndex(null);
                    props.getRegistrationList({ page: current, pageSize: pageSize });
                  }}
              >
                {dynamic_description}
              </SweetAlert>
          ) : null}

          {success_dlg ? (
            <SweetAlert
              success
              title={props.t(dynamic_title)}
              onConfirm={() => {
                props.delOneRegistration(index);
                setsuccess_dlg(false);
                setDelData(null);
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {error_dlg ? (
            <SweetAlert
              error
              title={props.t(dynamic_title)}
              onConfirm={() => {
                seterror_dlg(false);
                setDelData(null);
                setEditingList(null);
                setIndex(null);
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { listTotal, registeredList, editModalStatus, loading } = state.StudentRegisterReducer;
  return { listTotal, registeredList, editModalStatus, loading };
};

export default withRouter(
  connect(mapStateToProps, {
    getRegistrationList,
    updateRegistration,
    delOneRegistration
  })(withNamespaces()(RegistrationList))
);
