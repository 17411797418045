import {
    REGISTER_STUDENT,
    REGISTER_STUDENT_SUCCESS,
    REGISTER_STUDENT_FAILED,
    GET_REGISTERED_LIST,
    GET_REGISTERED_LIST_SUCCESS,
    UPDATE_REGISTRATION,
    UPDATE_REGISTRATION_SUCCESS,
    UPDATE_REGISTRATION_FAILED,
    DELETE_ONE_REGISTRATION
} from './actionTypes';

const initialState = {
    error: "",
    loading: false,
    result: -1,
    listTotal: 0,
    registeredList: null,
    editModalStatus: 0
}

const registerStudent = (state = initialState, action) => {
    switch (action.type) {
        case GET_REGISTERED_LIST_SUCCESS:
            state = {
                ...state,
                registeredList: action.payload,
                listTotal: action.total
            };
            break;
        case REGISTER_STUDENT:
            state = {
                ...state,
                loading: true,
                result: -1
            };
            break;
        case REGISTER_STUDENT_SUCCESS:
            state = {
                ...state,
                loading: false,
                result: 1
            };
            break;
        case REGISTER_STUDENT_FAILED:
            state = {
                ...state,
                loading: false,
                result: 0
            };
            break;
        case UPDATE_REGISTRATION:
            state = {
                ...state,
                loading: true,
                editModalStatus: 0
            };
            break;
        case UPDATE_REGISTRATION_SUCCESS:
            let list = state.registeredList;
            list[action.index] = action.payload;
            state = {
                ...state,
                loading: false,
                editModalStatus: 1,
                registeredList: list
            };
            break;
        case UPDATE_REGISTRATION_FAILED:
            state = {
                ...state,
                loading: false,
                editModalStatus: 2
            };
            break;
        case DELETE_ONE_REGISTRATION:
            let registeredList = state.registeredList;
            registeredList.splice(action.payload, 1);
            state = {
                ...state,
                registeredList: registeredList,
                listTotal: state.listTotal > 0 ? state.listTotal - 1 : 0
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default registerStudent;