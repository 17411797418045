import {
    GET_DASHBOARD_ACTIVITIES,
    GET_DASHBOARD_ACTIVITIES_SUCCESS,
    GET_DASHBOARD_ACTIVITIES_FAILED
} from './actionTypes';

export const getDashboardActivities= () => {
    return {
        type: GET_DASHBOARD_ACTIVITIES
    }
}

export const getDashboardActivitiesSuccess = (activities) => {
    return {
        type: GET_DASHBOARD_ACTIVITIES_SUCCESS,
        payload: activities,
    }
}

export const getDashboardActivitiesFailed = () => {
    return {
        type: GET_DASHBOARD_ACTIVITIES_FAILED
    }
}
