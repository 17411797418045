import {
    GET_CONTACTS,
    GET_CONTACTS_SUCCESS,
    UPDATE_CONTACTS,
    UPDATE_CONTACTS_SUCCESS,
    UPDATE_CONTACTS_FAILED,
    DELETE_ONE_CONTACT
} from './actionTypes';
import {DELETE_ONE_WAITER} from "../waiting_list/actionTypes";

const initialState = {
    loading: false,
    contactList: null,
    totalContacts: 0,
    editModalStatus: 0,
}

const login = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONTACTS:
            state = {
                ...state
            };
            break;
        case GET_CONTACTS_SUCCESS:
            state = {
                ...state,
                loading: false,
                contactList: action.payload,
                totalContacts: action.total
            };
            break;
        case UPDATE_CONTACTS:
            state = {
                ...state,
                loading: true,
                editModalStatus: 0
            };
            break;
        case UPDATE_CONTACTS_SUCCESS:
            let list = state.contactList;
            list[action.index] = action.payload;
            state = {
                ...state,
                loading: false,
                editModalStatus: 1,
                contactList: list
            };
            break;
        case UPDATE_CONTACTS_FAILED:
            state = {
                ...state,
                loading: false,
                editModalStatus: 2
            };
            break;
        case DELETE_ONE_CONTACT:
            let contactList = state.contactList;
            contactList.splice(action.payload, 1);
            state = {
                ...state,
                contactList: contactList,
                totalContacts: state.totalContacts > 0 ? state.totalContacts - 1 : 0
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default login;