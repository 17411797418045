import { all } from 'redux-saga/effects';

//public
// import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import LayoutSaga from './layout/saga';
import ResetPwd from './auth/resetPwd/saga';

import UserSaga from './contents/user/saga';
import StudentSage from './contents/students/saga';
import StudentDetailSaga from './contents/studentDetail/saga';
import GroupSaga from './contents/group/saga';
import ActivitySaga from './contents/activity/saga';
import ActivityList from './contents/activity_list/saga';
import Attendance from './contents/attendance/saga';
import ReportAttendance from './contents/report/saga';
import SchoolReport from './contents/school_report/saga';
import SchoolReportList from './contents/report_items/saga';
import Notifications from './contents/notification/saga';
import Dashboard from './contents/dashboard/saga';
import WaitingList from './contents/waiting_list/saga';
import ContactList from './contents/contact/saga';
import StudentRegister from './contents/registerForm/saga';

export default function* rootSaga() {
    yield all([
        AuthSaga(),
        ForgetSaga(),
        LayoutSaga(),
        ResetPwd(),
        UserSaga(),
        StudentSage(),
        StudentDetailSaga(),
        GroupSaga(),
        ActivitySaga(),
        ActivityList(),
        Attendance(),
        ReportAttendance(),
        SchoolReport(),
        SchoolReportList(),
        Notifications(),
        Dashboard(),
        WaitingList(),
        ContactList(),
        StudentRegister()
    ])
}