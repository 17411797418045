import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import { GET_A_STUDENT, UPDATE_A_STUDENT, UPLOAD_DOC, DELETE_DOC } from './actionTypes';
import {
    getAStudentSuccess,
    getAStudentFailed,
    updateAStudentSuccess,
    updateAStudentFailed,
    getTeachersForChild,
    getMedicalDocs,
    uploadDocSuccess,
    uploadDocFailed,
    deleteDocSuccess,
    deleteDocFailed
} from './actions';
import {aStudent, editAStudent} from '../../../apiHelpers/studentDetailHelper';
import { paramPost, postWithFile } from '../../../apiHelpers/commonAPi'
import toastr from 'toastr';

function* getStudent({ payload: id }) {
    try {
        const response = yield call(aStudent, `/api/v1/students/${id}`);
        if(response.status === 200) {
            yield put(getAStudentSuccess(JSON.parse(response.data.student)));
            yield put(getTeachersForChild(JSON.parse(response.data.teachers)));
            yield put(getMedicalDocs(JSON.parse(response.data.docs)));
        } else {
            yield put(getAStudentFailed(response.status));
            toastr.error("Something went wrong","Error")
        }

    } catch (error) {
        yield put(getAStudentFailed(error));
    }
}

function* updateStudent({ payload: student }) {
    try {
        const id = student.id
        delete student.id
        const response = yield call(editAStudent, `/api/v1/students/${id}`, {...student});
        if (response.status === 200) {
            yield put(getAStudentSuccess(JSON.parse(response.data.student)))
            yield put(updateAStudentSuccess())
            toastr.success("The child information has been updated!")
        } else {
            toastr.error("It seems the SIN informed already exists","Error")
            yield put(updateAStudentFailed(response.status));
        }
    } catch (error) {
        yield put(updateAStudentFailed(error));
    }
}

function* uploadCertDoc({ payload: data }) {
    try {
        let formData = new FormData();
        formData.append(`file`, data.file);
        formData.append('id', data.id);
        formData.append('type', data.type);
        const response = yield call(postWithFile, `/api/v1/students/upload_doc`, formData);
        if (response.status === 200) {
            yield put(uploadDocSuccess(JSON.parse(response.data.student)))
            yield put(uploadDocFailed())
            toastr.success("The child information has been updated!")
        } else {
            toastr.error(response.data.message,"Error")
            yield put(uploadDocFailed());
        }
    } catch (error) {
        toastr.error("Something went wrong. Please try again.","Error")
        yield put(uploadDocFailed());
    }
}

function* deleteCertDoc({ payload: data }) {
    try {
        const response = yield call(paramPost, `/api/v1/students/delete_doc`, {...data});
        if (response.status === 200) {
            yield put(deleteDocSuccess(JSON.parse(response.data.student)))
            yield put(deleteDocFailed())
            toastr.success("The child information has been updated!")
        } else {
            toastr.error(response.data.message,"Error")
            yield put(deleteDocFailed());
        }
    } catch (error) {
        toastr.error("Something went wrong. Please try again.","Error")
        yield put(deleteDocFailed());
    }
}


export function* watchGetStudent() {
    yield takeEvery(GET_A_STUDENT, getStudent)
}

export function* watchUpdateStudent() {
    yield takeEvery(UPDATE_A_STUDENT, updateStudent)
}

export function* watchUploadDoc() {
    yield takeEvery(UPLOAD_DOC, uploadCertDoc)
}

export function* watchDeleteDoc() {
    yield takeEvery(DELETE_DOC, deleteCertDoc)
}

function* studentDetailSaga() {
    yield all([
        fork(watchGetStudent),
        fork(watchUpdateStudent),
        fork(watchUploadDoc),
        fork(watchDeleteDoc)
    ]);
}

export default studentDetailSaga;