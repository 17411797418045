export const GET_ATT_STUDENTS = 'GET_ATT_STUDENTS';
export const GET_ATT_STUDENTS_SUCCESS = 'GET_ATT_STUDENTS_SUCCESS';
export const GET_ATT_STUDENTS_FAILED = 'GET_ATT_STUDENTS_FAILED';
export const CREATE_ATTENDANCE = 'CREATE_ATTENDANCE';
export const CREATE_ATTENDANCE_SUCCESS = 'CREATE_ATTENDANCE_SUCCESS';
export const CREATE_ATTENDANCE_FAILED = 'CREATE_ATTENDANCE_FAILED';
export const UPDATE_ATTENDANCE = 'UPDATE_ATTENDANCE';
export const UPDATE_ATTENDANCE_SUCCESS = 'UPDATE_ATTENDANCE_SUCCESS';
export const UPDATE_ATTENDANCE_FAILED = 'UPDATE_ATTENDANCE_FAILED';

