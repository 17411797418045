import React from 'react';
// import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

import NonAuthMiddleWare from "./routes/middleware/NonAuthMiddleware"

import NonAuthLayout from "./components/Layouts/NonAuthLayout"
import VerticalLayout from "./components/VerticalLayout/index"

// Import Routes all
import { authRoutes, userRoutes } from "./routes/allRoutes";
import Login from "./components/Authentication/login";
import ForgetPwd from "./components/Authentication/ForgetPassword";
import ResetPwd from "./components/Authentication/resetPwd";
import RegistrationForm from "./components/RegistrationForm";

// Import scss
import "./assets/scss/theme.scss";

function App() {
    return (
        <Router>
            <Switch>
                <NonAuthMiddleWare exact path="/login" component={Login} layout={NonAuthLayout} />
                <Route path="/forgot-password" component={ForgetPwd} />
                <Route path="/reset-password" component={ResetPwd} />
                <Route path="/fiche-inscription" component={RegistrationForm} />
                {authRoutes.map((route, idx) => (
                    <Authmiddleware
                        path={route.path}
                        layout={NonAuthLayout}
                        component={route.component}
                        key={idx}
                    />
                ))}

                {userRoutes.map((route, idx) => (
                    <Authmiddleware
                        path={route.path}
                        layout={VerticalLayout}
                        component={route.component}
                        key={idx}
                    />
                ))}
            </Switch>
        </Router>
    );
}

export default App;